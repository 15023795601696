var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{staticClass:"dialogBox",attrs:{"scrollable":"","width":"1520"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"dialogBox"},[_c('v-card-title',[_vm._v(_vm._s(_vm.isNewMode ? 'メール設定の追加' : '詳細'))]),_c('v-card-text',{staticClass:"text--primary px-7"},[_c('div',{staticClass:"masterDetail pt-3 pb-3"},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-md-9 pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"dialogBox"},[_c('div',{staticClass:"masterDetail"},[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DataPanelField',{attrs:{"label":"名称"}},[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"placeholder":"例： 新規予約時通知メール, 予約xx日前通知メール","outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[(v) => !!v || '名称は必須項目です。']},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('DataPanelField',{attrs:{"label":"メール件名"}},[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"placeholder":"例： {facility.name}予約完了のお知らせ","outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[
                            (v) => !!v || 'メール件名は必須項目です。',
                          ]},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})],1),_c('DataPanelField',{attrs:{"label":"メール本文"}},[_c('v-textarea',{ref:"bodyTextField",staticClass:"mt-0 pt-0",attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[
                            (v) => !!v || 'メール本文は必須項目です。',
                          ]},model:{value:(_vm.item.body),callback:function ($$v) {_vm.$set(_vm.item, "body", $$v)},expression:"item.body"}})],1),_c('DataPanelField',{attrs:{"label":"同時送信するtoアドレス(「;」区切り)"}},[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"placeholder":"例： aaa@example.com;bbb@example.com","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.item.alternativeTo),callback:function ($$v) {_vm.$set(_vm.item, "alternativeTo", $$v)},expression:"item.alternativeTo"}})],1),_c('DataPanelField',{attrs:{"label":"同時送信するccアドレス(「;」区切り)"}},[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"placeholder":"例： aaa@example.com;bbb@example.com","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.item.alternativeCc),callback:function ($$v) {_vm.$set(_vm.item, "alternativeCc", $$v)},expression:"item.alternativeCc"}})],1),_c('DataPanelField',{attrs:{"label":"同時送信するbccアドレス(「;」区切り)"}},[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"placeholder":"例： aaa@example.com;bbb@example.com","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.item.alternativeBcc),callback:function ($$v) {_vm.$set(_vm.item, "alternativeBcc", $$v)},expression:"item.alternativeBcc"}})],1),_c('DataPanelField',{attrs:{"label":"メール種類"}},[_c('v-select',{staticClass:"mt-0 pt-0",attrs:{"items":_vm.mailType.items,"outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[
                            (v) => !!v || 'メール種類は必須項目です。',
                          ]},model:{value:(_vm.item.mailType),callback:function ($$v) {_vm.$set(_vm.item, "mailType", $$v)},expression:"item.mailType"}})],1),(_vm.item.mailType === 'periodically')?[_c('DataPanelField',{attrs:{"label":"定期実行時の時間"}},[_c('v-select',{staticClass:"mt-0 pt-0",attrs:{"items":[...Array(24)].map((_v, i) => ({
                                text: `${i}時`,
                                value: i,
                              })),"outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[
                              (v) =>
                                (v !== null && v !== undefined) ||
                                '定期実行時の時間は必須項目です。',
                            ]},model:{value:(_vm.item.hour),callback:function ($$v) {_vm.$set(_vm.item, "hour", $$v)},expression:"item.hour"}})],1),_c('DataPanelField',{attrs:{"label":"予約何日前"}},[_c('NumberInput',{attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":"","rules":[
                              (v) =>
                                (v !== null && v !== undefined) ||
                                '予約何日前は必須項目です。',
                              (v) => v > 0 || '1日以上前を指定してください。',
                            ]},model:{value:(_vm.item.preDays),callback:function ($$v) {_vm.$set(_vm.item, "preDays", $$v)},expression:"item.preDays"}})],1)]:_vm._e()],2)],1)],1)],1),_c('v-card-actions',[(!_vm.isNewMode)?_c('v-btn',{staticClass:"red",attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.$emit('destroy')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")]),_c('span',[_vm._v("項目削除")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"gray",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{},[_vm._v("mdi-close-circle-outline")]),_vm._v(" キャンセル ")],1),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.save}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-file-edit-outline")]),_vm._v(" 保存 ")],1)],1)],1)],1),_c('v-col',{staticClass:"fieldList rounded",attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v("項目一覧")]),_c('FieldList',{staticClass:"overflow-auto",attrs:{"items":_vm.fields},on:{"click":_vm.selectField}})],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }