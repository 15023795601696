<template>
  <v-container class="reserveList">
    <v-row>
      <v-col cols="6" class="pageTtl">
        <h2>ユーザー管理</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn x-large @click="open">
          <v-icon class="white--text">mdi-plus</v-icon>
          ユーザーを新規作成
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in 1" :key="i">
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="filter.value.name"
                      outlined
                      dense
                      hide-details="auto"
                      label="名前"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-select
                      v-model="filter.value.roleId"
                      :items="filter.roleValues"
                      outlined
                      dense
                      hide-details="auto"
                      label="権限グループ"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      v-model="filter.value.isValid"
                      :items="filter.isValidValues"
                      outlined
                      dense
                      hide-details="auto"
                      label="有効/無効"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <v-btn small outlined @click="load">
                  <v-icon>mdi-magnify</v-icon>
                  検索
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :items-per-page="20"
            item-class="pointer"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1],
            }"
            @click:row="handleClickRow"
          >
            <template #item.isValid="{ item }">
              <v-chip :color="getColor(item.isValid)">
                {{ item.isValid ? '有効' : '無効' }}
              </v-chip>
            </template>
            <template #item.role="{ value }">
              {{ value?.name }}
            </template>
          </v-data-table>
          <UserDetail
            v-model="dialog"
            @save="save"
            @destroy="destroy"
            @change-password="changePassword"
            @sign-out="signOut"
          ></UserDetail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import UserDetail from './UserDetail.vue';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  GetAllUsersRequest,
  GetAllUsersResponse,
  GlobalSignOutRequest,
  GlobalSignOutResponse,
  UpdateUserRequestUser,
} from '@api-i/routes/user/user';
import { Role, User } from '@api/models';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import {
  GetAllRolesRequest,
  GetAllRolesResponse,
} from '@api-i/routes/role/role';

const initializedItem: Partial<UpdateUserRequestUser> = {
  isValid: true,
  roleId: '',
};

export default Vue.extend({
  name: 'User',

  pageOptions: {
    routeConfig: {
      path: '/users',
    },
    props: {
      title: 'ユーザー管理',
    },
  },

  components: {
    UserDetail,
  },

  data: () => ({
    filter: {
      value: {
        name: undefined as string | undefined,
        roleId: undefined as number | undefined,
        isValid: undefined as 0 | 1 | undefined,
      },
      roleValues: [] as {
        text?: string | number | object;
        value?: string | number | object;
        disabled?: boolean;
        divider?: boolean;
        header?: string;
      }[],
      isValidValues: [
        {
          text: '有効',
          value: 1,
        },
        {
          text: '無効',
          value: 0,
        },
      ],
    },

    table: {
      headers: [
        { text: '名前', value: 'name', cellClass: 'pointer' },
        { text: 'ID', value: 'username', cellClass: 'pointer' },
        { text: '所属名', value: 'department', cellClass: 'pointer' },
        { text: '権限グループ', value: 'role', cellClass: 'pointer' },
        { text: '有効', value: 'isValid', cellClass: 'pointer' },
      ],
      items: [] as User[],
    },

    dialog: {
      show: false,
      item: {} as UpdateUserRequestUser,
      newMode: false,
      passwordDialog: false,
      roles: [] as Role[],
    },
  }),

  watch: {
    markdown(to) {
      console.log(to);
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.load(), this.loadRole()]);
    },

    async load() {
      try {
        const result = await this.$api<GetAllUsersResponse, GetAllUsersRequest>(
          {
            path: '/user',
            method: 'post',
            params: {
              condition: this.filter.value,
            },
          },
        );

        this.table.items = result.result;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async loadRole() {
      try {
        const result = await this.$api<GetAllRolesResponse, GetAllRolesRequest>(
          {
            path: '/role',
            method: 'get',
            params: {},
          },
        );

        const roleItems = result.result.map((r) => ({
          text: r.name,
          value: r.id!,
        }));

        this.filter.roleValues = roleItems;
        this.$set(this.dialog, 'roles', result.result);
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    getColor(infoUserIsValid: boolean) {
      return infoUserIsValid ? 'enabled' : 'disabled';
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initializedItem));
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: User) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/user/save',
          method: 'post',
          params: {
            user: this.dialog.item,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      try {
        const result = await this.$api<DeleteUserResponse, DeleteUserRequest>({
          path: '/user/delete',
          method: 'post',
          params: this.dialog.item,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async changePassword(newPassword: string) {
      try {
        await this.$api<ChangePasswordResponse, ChangePasswordRequest>({
          path: '/user/changePassword',
          method: 'post',
          params: {
            username: this.dialog.item.username,
            password: newPassword,
          },
        });
        this.dialog.passwordDialog = false;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'パスワード変更に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async signOut() {
      try {
        await this.$api<GlobalSignOutResponse, GlobalSignOutRequest>({
          path: '/user/globalSignOut',
          method: 'post',
          params: {
            username: this.dialog.item.username,
          },
        });
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'サインアウトに失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.markdown-editor {
  height: 550px;
}
</style>
