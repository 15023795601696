<template>
  <v-dialog v-model="show" scrollable width="1440" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-text class="text--primary">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" lg="8" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <v-card-title>{{
                  isNewMode ? '利用時間帯を追加' : '詳細'
                }}</v-card-title>
                <v-col>
                  <div class="masterDetail pt-6 pb-6">
                    <data-panel-field label="名称">
                      <v-text-field
                        v-model="item.name"
                        placeholder="例： 夏季"
                        class="mt-0 pt-0"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        :rules="[(v) => !!v || '名称は必須項目です。']"
                      ></v-text-field>
                    </data-panel-field>
                    <data-panel-field label="利用開始時間">
                      <time-picker
                        v-model="item.startTime"
                        clearable
                        :minute-step="step"
                      ></time-picker>
                    </data-panel-field>
                    <data-panel-field label="利用終了時間">
                      <time-picker
                        v-model="item.endTime"
                        clearable
                        :minute-step="step"
                      ></time-picker>
                    </data-panel-field>
                  </div>
                </v-col>
                <schema-edit
                  v-model="item.schema_"
                  :fields="fields"
                ></schema-edit>
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn depressed color="primary" small @click="$emit('save')">
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <field-list :items="fields" class="overflow-auto"></field-list>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveUsageTimeRequest } from '@api-i/routes/usageTime/usageTime';
import { Field } from '@api/models';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import FieldList from '@web-i/components/FieldList.vue';
import SchemaEdit from '@web-i/components/schema/SchemaEdit.vue';
import TimePicker from '@web/components/inputs/time-picker.vue';

export type UsageTimeDetailDataType = {
  show: boolean;
  item: SaveUsageTimeRequest;
  newMode: boolean;
};

const initRange = { from: '', to: '' };

export const initUsageTimeDetailItem = {
  name: '',
  schema_: [],
  startTime: '',
  endTime: '',
  unitId: '',
};

export default Vue.extend({
  name: 'UsageTimeDetail',

  components: {
    DataPanelField,
    FieldList,
    SchemaEdit,
    TimePicker,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    step: {
      type: Number,
      default: undefined,
    },

    value: {
      type: Object as PropType<UsageTimeDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveUsageTimeRequest {
        return this.value.item;
      },
      set(item: SaveUsageTimeRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },
  },

  methods: {
    timeChangesFixed(fixed: boolean, target: string) {
      if (!fixed) {
        this.$emit('input', {
          ...this.value,
          item: {
            ...this.item,
            [target]: {
              ...initRange,
            },
          },
        });
      } else {
        this.$emit('input', {
          ...this.value,
          item: {
            ...this.item,
            [target]: '',
          },
        });
      }
    },
  },
});
</script>
