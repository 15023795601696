<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>休館日の追加/削除</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <DataPanelField label="休館タイプ">
            <v-select
              v-model="type"
              :items="closedDayType.items"
              outlined
              dense
              hide-details="auto"
              clearable
            ></v-select>
          </DataPanelField>

          <DataPanelField label="指定方法">
            <v-select
              v-model="method"
              :items="methodItems"
              outlined
              dense
              hide-details="auto"
              clearable
            ></v-select>

            <!-- 曜日指定 ここから -->
            <v-row v-if="method === 1">
              <v-col cols="12" class="pl-0 pr-0 mt-5">
                <v-row>
                  <v-col
                    v-for="day in daysOfWeekItems"
                    :key="day.value"
                    cols="6"
                    sm="4"
                    class="pt-0 pl-0"
                  >
                    <v-checkbox
                      v-model="daysOfWeek"
                      :label="day.text"
                      :value="day.value"
                      class="mt-0"
                      hide-details="false"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 曜日指定 ここまで -->

            <!-- 毎月指定 ここから -->
            <v-row v-else-if="method === 2">
              <v-col cols="12" class="pl-0 pr-0 mt-5">
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pl-0">
                    <v-select
                      v-model="date"
                      :items="Array.from({ length: 31 }, (_, i) => i + 1)"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      suffix="日"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else-if="method === 3">
              <v-col cols="12" class="pl-0 pr-0 mt-5">
                <v-row>
                  <v-col
                    cols="1"
                    class="pt-0 pl-0 pr-0 d-flex justify-start align-center"
                  >
                    第
                  </v-col>
                  <v-col
                    cols="4"
                    class="pt-0 pl-0 d-flex justify-start align-center"
                  >
                    <v-select
                      v-model="weekOfMonth"
                      :items="[1, 2, 3, 4, 5]"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-for="day in daysOfWeekItems"
                    :key="day.value"
                    cols="6"
                    sm="4"
                    class="pt-0 pl-0"
                  >
                    <v-checkbox
                      v-model="daysOfWeek"
                      :label="day.text"
                      :value="day.value"
                      class="mt-0"
                      hide-details="false"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 毎月指定 ここまで -->

            <!-- 個別指定 ここから -->
            <v-row v-else-if="method === 4">
              <v-col cols="12" class="pl-0 pr-0 mt-5">
                <v-row>
                  <v-col cols="12" class="pt-0 pl-0 pr-0">
                    <DatePicker
                      v-model="startDate"
                      label="開始日"
                      outlined
                      dense
                      clearable
                    />
                  </v-col>

                  <v-col cols="12" class="pt-0 pl-0 pr-0">
                    <DatePicker
                      v-model="endDate"
                      label="終了日"
                      outlined
                      dense
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 個別指定 ここまで -->
          </DataPanelField>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed small class="red" @click="destroy">
          <v-icon>mdi-delete-outline</v-icon>
          <span>削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn
          depressed
          color="primary"
          small
          :disabled="disabledToAdd"
          @click="add"
        >
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          追加
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { closedDayType, daysOfWeek } from '@api/constants';
import { dateUtility } from '@c/util';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import DatePicker from '@web/components/inputs/date-picker.vue';
import HolidaySearcher from '@web/modules/holiday-searcher';
import { mapActions } from '@web/store/snackbar';
import { chunk } from 'lodash';
import Vue, { PropType } from 'vue';

export type ClosedDayAddDataType = {
  show: boolean;
  year: number;
};

export default Vue.extend({
  name: 'ClosedDayAdd',

  components: {
    DataPanelField,
    DatePicker,
  },

  props: {
    value: {
      type: Object as PropType<ClosedDayAddDataType>,
      default: undefined,
    },
  },

  data: () => ({
    type: '',
    method: null,
    weekOfMonth: null,
    daysOfWeek: [] as number[],
    date: null,
    startDate: '',
    endDate: '',
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    year: {
      get(): number {
        return this.value.year;
      },
    },

    methodItems() {
      return [
        { text: '毎週○曜日', value: 1 },
        { text: '毎月○日', value: 2 },
        { text: '毎月第○△曜日', value: 3 },
        { text: '日付範囲指定', value: 4 },
        { text: '祝日', value: 5 },
      ];
    },

    disabledToAdd() {
      if (!this.type || !this.method) {
        return true;
      }
      if (this.method === 1 && !this.daysOfWeek.length) {
        return true;
      } else if (this.method === 2 && !this.date) {
        return true;
      } else if (
        this.method === 3 &&
        (!this.weekOfMonth || !this.daysOfWeek.length)
      ) {
        return true;
      } else if (this.method === 4 && (!this.startDate || !this.endDate)) {
        return true;
      }
      return false;
    },

    closedDayType() {
      return closedDayType;
    },

    daysOfWeekItems() {
      return daysOfWeek.items;
    },
  },

  watch: {
    method(_, oldVal) {
      if (oldVal === 1) {
        this.daysOfWeek = [];
      } else if (oldVal === 2) {
        this.date = null;
      } else if (oldVal === 3) {
        this.weekOfMonth = null;
        this.daysOfWeek = [];
      } else if (oldVal === 4) {
        this.startDate = '';
        this.endDate = '';
      }
    },
  },

  methods: {
    async getMethodDates() {
      if (this.method === null || this.method === undefined) {
        return;
      }
      let result = [] as Date[];
      if (this.method <= 3) {
        result = this.getDatesOfYear();
      } else if (this.method === 4) {
        result = dateUtility.getDatesInRange(this.startDate, this.endDate);
      } else if (this.method === 5) {
        const dates = await this.getDatesOfHoliday();
        if (!dates || dates.length === 0) {
          this.snackbarRegister({
            type: 'error',
            message: '祝日を取得できませんでした。',
          });
          return;
        }
        result = dates;
      }
      return result;
    },

    async add() {
      const dates = await this.getMethodDates();
      if (!dates) {
        return;
      }
      this.$emit('add', this.type, dates);
    },

    async destroy() {
      const dates = await this.getMethodDates();
      if (!dates) {
        return;
      }
      this.$emit('destroy', this.type, dates);
    },

    getDatesOfYear(): Date[] {
      let result = [] as Date[];

      const year = this.year;
      const weekOfMonth = this.weekOfMonth;
      const daysOfWeek = this.daysOfWeek;
      const date = this.date;
      for (let month = 0; month < 12; month++) {
        const dates = this.getDatesOfMonth(
          year,
          month,
          weekOfMonth,
          daysOfWeek,
          date,
        );
        result = result.concat(dates);
      }

      return result;
    },

    getDatesOfMonth(
      year: number,
      month: number,
      weekOfMonth: number | null,
      daysOfWeek: number[],
      date: string | null,
    ): Date[] {
      let result = [];

      for (let d = 1; d <= 31; d++) {
        const date_ = new Date(year, month, d);
        if (month !== date_.getMonth()) {
          break;
        }
        if (!!daysOfWeek && !!daysOfWeek.length) {
          if (!daysOfWeek.includes(date_.getDay())) {
            continue;
          }
        }
        if (!!date && !!Number(date)) {
          if (date_.getDate() !== Number(date)) {
            continue;
          }
        }
        result.push(date_);
      }
      if (!!daysOfWeek && weekOfMonth) {
        result = chunk(result, daysOfWeek.length)
          .filter((_, i) => i + 1 === weekOfMonth)
          .flat();
      }

      return result;
    },

    async getDatesOfHoliday(): Promise<Date[] | null> {
      const holidays = await HolidaySearcher.fetchCacheAPI(this.year, this);
      const result = holidays.map((holiday) => new Date(holiday));

      return result;
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
