<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>休館日</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small :disabled="!selectedFacility" @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加/削除
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-col class="mr-auto" cols="12" sm="4" md="3" lg="2">
            <v-select
              v-model="selectedYear"
              :items="selectableYears"
              suffix="年"
              outlined
              dense
              @change="onChangeYear"
            ></v-select>
          </v-col>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            @click:row="handleClickRow"
          >
          </v-data-table>
          <ClosedDayAdd v-model="dialog" @destroy="destroy" @add="add" />
          <ClosedDayDetail
            v-model="detailDialog"
            @destroy="remove"
            @save="save"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import ClosedDayAdd from './ClosedDayAdd.vue';
import ClosedDayDetail from './ClosedDayDetail.vue';
import { ClosedDay } from '@api/models';
import { range } from 'lodash';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { mapActions } from '@web/store/snackbar';
import {
  GetAllClosedDaysRequest,
  GetAllClosedDaysResponse,
  UpdateClosedDayRequest,
} from '@api-i/routes/closedDay/closedDay';

export default Vue.extend({
  name: 'MastersClosedDay',

  pageOptions: {
    routeConfig: {
      path: '/masters/closedDay',
    },
    props: {
      title: 'マスタ設定 - 休館日',
    },
  },

  components: {
    ClosedDayAdd,
    ClosedDayDetail,
  },

  data: () => ({
    selectedYear: new Date().getFullYear(),

    table: {
      headers: [
        { text: '日付', value: 'date', cellClass: 'pointer' },
        { text: 'タイプ', value: 'typeName', cellClass: 'pointer' },
      ],
      items: [] as ClosedDay[],
    },

    dialog: {
      show: false,
      year: null,
    },

    detailDialog: {
      show: false,
      item: {} as Partial<UpdateClosedDayRequest>,
    },
  }),

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },

    selectableYears() {
      return range(this.selectedYear - 5, this.selectedYear + 5);
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      try {
        const result = await this.$api<
          GetAllClosedDaysResponse,
          GetAllClosedDaysRequest
        >({
          path: '/closedDay',
          method: 'get',
          params: {
            facilityId: facilityId,
            year: this.selectedYear,
          },
        });

        if (result && result.closedDays) {
          this.table.items = result.closedDays;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }
    },

    async onChangeYear() {
      await this.load();
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'year', this.selectedYear);
    },

    async add(type: string, dates: Date[]) {
      const facilityId = this.selectedFacility.id;
      const items = dates.map((date) => {
        const date_ = this.$dateFns.fnsFormat(date, '', 'yyyy-MM-dd');
        return { date: date_, type, facilityId };
      });
      try {
        const result = await this.$api({
          path: '/closedDay/add',
          method: 'post',
          params: {
            items,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy(type: string, dates: Date[]) {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      const items = dates.map((date) => {
        const date_ = this.$dateFns.fnsFormat(date, '', 'yyyy-MM-dd');
        return date_;
      });
      const facilityId = this.selectedFacility.id;
      try {
        const result = await this.$api({
          path: '/closedDay/delete',
          method: 'post',
          params: {
            type,
            dates: items,
            facilityId: facilityId,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    handleClickRow(item: any) {
      this.$set(this.detailDialog, 'show', true);
      this.$set(this.detailDialog, 'item', structuredClone(item));
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/closedDay/save',
          method: 'post',
          params: this.detailDialog.item,
        });
        this.detailDialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async remove() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api({
          path: '/closedDay/delete',
          method: 'post',
          params: {
            id: this.detailDialog.item.id,
          },
        });
        this.detailDialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>

<style lang="scss" scoped>
.markdown-editor {
  height: 550px;
}
</style>
