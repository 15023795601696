import { VDataTable, VSimpleTable } from 'vuetify/lib/components';
import { DataScopeProps } from 'vuetify/types';
import { PropType, VNode, VNodeChildren } from 'vue';
import CustomDraggable from './CustomDraggable';

/**
 * draggableなdata table
 *
 * @description
 * `@updated-items`で`items`に渡した項目を更新すること。
 * また、`@draggable-${xxx}`でvue-draggableのイベントをそのまま引き継げる。
 *
 * @example
 * ```html
<custom-data-table
  :headers="headers"
  :items="items"
  @updated-items="
    (v) => {
      items = v;
    }
  "
>
</custom-data-table>
 * ```
 *
 */
export default VDataTable.extend({
  name: 'custom-data-table',

  components: {
    CustomDraggable,
  },

  props: {
    useDraggable: {
      type: Boolean,
      default: false,
    },
    draggableProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },

  methods: {
    _genBody: (VDataTable as any).options.methods.genBody,
    genBody(props: DataScopeProps): VNode | string | VNodeChildren {
      if (this.$scopedSlots.body) {
        return this._genBody(props);
      }
      if (!this.useDraggable) {
        return this._genBody(props);
      }

      const allItems = (this as any).items as any[];
      const currentItems = (this as any).internalCurrentItems as any[];
      const firstItemIndex = allItems.indexOf(currentItems[0]);

      const self = this as any;

      const eventHandlers = [
        'start',
        'add',
        'remove',
        'update',
        'end',
        'choose',
        'unchoose',
        'sort',
        'filter',
        'clone',
      ].reduce((prev, next) => {
        return {
          ...prev,
          [next]: function (event: any) {
            self.$emit(`draggable-${next}`, event);
          },
        };
      }, {});

      const dragTable = this.$createElement(
        CustomDraggable,
        {
          props: {
            tag: 'tbody',
            ...self.draggableProps,
            value: self.items,
            skip: firstItemIndex || 0,
          },
          on: {
            input(event: any) {
              self.$emit('updated-items', event);
            },
            ...eventHandlers,
          },
        },
        [...this._genBody(props).children],
      );

      return dragTable;
    },
    _genDefaultScopedSlot: (VDataTable as any).options.methods
      .genDefaultScopedSlot,
    genDefaultScopedSlot(props: DataScopeProps): VNode {
      const simpleProps = {
        height: (this as any).height,
        fixedHeader: (this as any).fixedHeader,
        dense: (this as any).dense,
      };

      const self = this as any;

      return this.$createElement(
        VSimpleTable,
        {
          props: simpleProps,
          class: {
            'v-data-table--mobile': (this as any).isMobile,
            'draggable-table': self.useDraggable,
          },
        },
        [...this._genDefaultScopedSlot(props).componentOptions.children],
      );
    },
  },
});
