<template>
  <v-dialog v-model="show" scrollable width="960" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>
        {{ item.subject }}
      </v-card-title>
      <v-card-text class="text--primary px-7">
        <mavon-editor
          v-model="item.body"
          language="ja"
          default-open="preview"
          :toolbars-flag="false"
          :subfield="false"
          class="markdown-editor"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { NoticeForManager } from '@api/models';
import Vue, { PropType } from 'vue';

export type NewsDetailDataType = {
  show: boolean;
  item: NoticeForManager;
};

export default Vue.extend({
  name: 'NewsDetail',

  props: {
    value: {
      type: Object as PropType<NewsDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): NoticeForManager {
        return this.value.item;
      },
    },
  },
});
</script>
