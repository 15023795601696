import Vue from 'vue';
import App from './App.vue';
import InitialLoading from '@web/components/InitialLoading.vue';

// 先に読み込むやつ
import vuetify from '@web/plugins/vuetify';
import '@web-i/components/global/index';
import '@web/plugins/config';
import '@web/plugins/constants';
import { cognitoInstance } from '@web/plugins/cognito';
import '@web/components/global/index';

// 次に読み込むやつ
import router from './router';
import store from './store';
import '@web/plugins/api';
import { loggedInCheck } from '@web/modules/login';

// いつ読み込んでもいいやつ
import '@web/plugins/datefns';
import '@web/plugins/mathjs';
import '@web/plugins/mavon-editor';
import '@web/plugins/cache';
import '@web/assets/styles/global.scss';
import '@web-i/assets/styles/style.scss';

Vue.config.productionTip = false;

async function run() {
  // 最初にローディング表示
  new Vue({
    vuetify,
    render: (h) => h(InitialLoading),
  }).$mount('#app');

  // ログイン済みのユーザがいるか確認
  await loggedInCheck({ store, cognito: cognitoInstance });

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
}

run();
