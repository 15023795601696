<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <DataPanelField label="日付">
            <DatePicker v-model="item.date" outlined dense clearable />
          </DataPanelField>
          <DataPanelField label="休館タイプ">
            <v-select
              v-model="item.type"
              :items="closedDayType.items"
              outlined
              dense
              hide-details="auto"
              clearable
              :rules="[(v) => !!v || '休館タイプは必須項目です。']"
            ></v-select>
          </DataPanelField>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed small class="red" @click="$emit('destroy')">
          <v-icon>mdi-delete-outline</v-icon>
          <span>項目削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" small @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { UpdateClosedDayRequest } from '@api-i/routes/closedDay/closedDay';
import DatePicker from '@web/components/inputs/date-picker.vue';
import { closedDayType } from '@api/constants';

export type ClosedDayDetailDataType = {
  show: boolean;
  item: UpdateClosedDayRequest;
};

export default Vue.extend({
  name: 'ClosedDayDetail',

  components: {
    DataPanelField,
    DatePicker,
  },

  props: {
    value: {
      type: Object as PropType<ClosedDayDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): UpdateClosedDayRequest {
        return this.value.item;
      },
      set(item: UpdateClosedDayRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    closedDayType() {
      return closedDayType;
    },
  },
});
</script>
