<template>
  <v-container class="reserveList">
    <v-row>
      <v-col cols="12" class="pageTtl"><h2>請求一覧</h2></v-col>
      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <FilterForm :facilities="facilities" />
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <v-btn small outlined :disabled="isNoSet" @click="load">
                  <v-icon>mdi-magnify</v-icon>
                  検索
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="table.headers"
          :items="tableItems"
          :items-per-page="10"
          item-class="pointer"
          :mobile-breakpoint="768"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          @click:row="handleClickRow"
        >
          <template #item.createdAt="{ item }">
            {{ item.createdAt | datetime }}
          </template>
          <template #item.status="{ value }">
            <v-chip
              :color="
                value === invoiceStatus.unpaid.value ? 'disabled' : 'enabled'
              "
            >
              {{ invoiceStatus.get(value) }}
            </v-chip>
          </template>
        </v-data-table>
        <invoice-detail
          v-model="dialog"
          :reports="reports"
          @save="save"
          @destroy="destroy"
          @output="output"
        ></invoice-detail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain, parseInt } from 'lodash';
import InvoiceDetail from './InvoiceDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { Invoice, Report } from '@api/models';
import {
  DeleteInvoiceRequest,
  DeleteInvoiceResponse,
  GetAllInvoicesRequest,
  GetAllInvoicesResponse,
  UpdateInvoiceRequest,
  UpdateInvoiceResponse,
} from '@api-i/routes/invoice/invoice';
import { invoiceStatus } from '@api/constants/invoiceStatus';
import { InferCreationAttributesInvoice } from '@api/models/Invoice';
import { loadCacheReports, loadReport } from '@web/modules/master-loader';
import {
  base64ToArrayBuffer,
  distributeInvoiceContext,
  downloadDocument,
  generateDocument,
  getFileName,
} from '@web/modules/document-handler';
import FilterForm from '@web-i/components/FilterForm.vue';
import * as loginState from '@web-i/store/login';
import * as filterState from '@web-i/store/filter';
import { dateMethod } from '@api/constants/dateMethod';

export default Vue.extend({
  name: 'Invoices',

  pageOptions: {
    routeConfig: {
      path: '/invoices',
    },
    props: {
      title: '請求一覧',
    },
  },

  components: {
    InvoiceDetail,
    FilterForm,
  },

  data: () => ({
    reports: [] as Report[],

    /**
     * 検索結果を一旦ここに入れる
     */
    invoices: [] as Invoice[],

    table: {
      headers: [
        { text: '受付番号', value: 'book.receiptNo', cellClass: 'pointer' },
        { text: '施設', value: 'book.facilityName', cellClass: 'pointer' },
        { text: '作成日', value: 'createdAt', cellClass: 'pointer' },
        { text: '予約者', value: 'book.name', cellClass: 'pointer' },
        {
          text: '金額（税込）',
          value: 'summary',
          cellClass: 'pointer',
          align: 'end',
        },
        { text: '状態', value: 'status', cellClass: 'pointer' },
      ],
      items: [] as Invoice[],
    },

    selectedItem: {} as InferCreationAttributesInvoice,

    dialog: {
      show: false,
      item: {} as Invoice,
    },
  }),

  computed: {
    invoiceDict(): Record<string, Invoice> {
      const result = chain(this.invoices).keyBy('id').value();

      return result;
    },

    tableItems(): InferCreationAttributesInvoice[] {
      const result = chain(this.invoices)
        .map((invoice) => {
          const summary = chain(invoice.details)
            .map('amount')
            .map(parseInt)
            .sum()
            .value()
            .toLocaleString();

          const result_ = {
            ...invoice,
            summary,
          };

          return result_;
        })
        .value();

      return result;
    },

    invoiceStatus() {
      return invoiceStatus;
    },

    ...filterState.mapState(['filter']),
    ...filterState.mapGetters(['isNoSet']),
    ...loginState.mapGetters(['facilities']),
  },

  async mounted() {
    await this.init();
    await this.$nextTick();
    await Promise.all([this.loadReports(), this.load()]);
  },

  methods: {
    /**
     * 検索条件の初期値を設定する
     */
    async init() {
      const method = dateMethod.today.value;
      const { from, to } = dateMethod.calc(method);
      const from_ = this.$dateFns.fnsFormat(from, '', 'yyyy-MM-dd');
      const to_ = this.$dateFns.fnsFormat(to, '', 'yyyy-MM-dd');

      await this.setFilter({
        ...this.filter,
        dateMethod: method,
        fromDate: from_,
        toDate: to_,
      });
    },

    /**
     * 帳票マスタを読み込む
     */
    async loadReports() {
      const reports = await loadCacheReports(this);
      this.$set(this, 'reports', reports);
    },

    async load() {
      try {
        const result = await this.$api<
          GetAllInvoicesResponse,
          GetAllInvoicesRequest
        >({
          path: '/invoice',
          method: 'get',
          params: {
            ...this.filter,
            scope: 'details',
          },
        });

        if (result) {
          this.invoices = result.invoices;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    handleClickRow(item: InferCreationAttributesInvoice) {
      this.selectedItem = item;
      const invoice = this.invoiceDict[item.id!];

      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(invoice));
    },

    async save() {
      try {
        const result = await this.$api<
          UpdateInvoiceResponse,
          UpdateInvoiceRequest
        >({
          path: '/invoice/update',
          method: 'post',
          params: {
            ...this.dialog.item,
            details: this.dialog.item.details!.map((detail, index) => ({
              ...detail,
              order: index,
            })),
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeleteInvoiceResponse,
          DeleteInvoiceRequest
        >({
          path: '/invoice/delete',
          method: 'post',
          params: {
            ...this.dialog.item,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの削除に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 帳票出力
     */
    async output(reportId: string) {
      const report = await loadReport(reportId, this, { scope: 'template' });
      if (!report || !report.fileTemplate) {
        return;
      }
      const buffer = base64ToArrayBuffer(report.fileTemplate);
      const data = distributeInvoiceContext(this, this.selectedItem);
      const doc = await generateDocument({
        data: data,
        template: buffer,
      });
      const fileName = getFileName(this, report.name);
      await downloadDocument({
        data: doc,
        fileName: fileName,
      });
    },

    ...filterState.mapActions(['setFilter']),
  },
});
</script>
