import { StoreRootInternal } from '@web-t/store/root';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import loading from '@web/store/loading';
import snackbar from '@web/store/snackbar';
import filter from './filter';
import login from './login';
import master from './master';

Vue.use(Vuex);

const root: StoreOptions<StoreRootInternal> = {
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    loading,
    snackbar,
    filter,
    login,
    master,
  },
};

export default new Vuex.Store<StoreRootInternal>(root);
