<template>
  <v-dialog v-model="show" scrollable width="1440" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-text class="text--primary">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" md="8" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <v-card-title>{{
                  isNewMode ? '入力検証を追加' : '詳細'
                }}</v-card-title>
                <div class="masterDetail pt-6">
                  <v-row>
                    <v-col cols="12">
                      <DataPanelField label="メッセージ">
                        <v-text-field
                          v-model="item.message"
                          placeholder="例： 利用人数は最大10名までとしております。"
                          outlined
                          dense
                          hide-details="auto"
                          clearable
                          :rules="[(v) => !!v || 'メッセージは必須項目です。']"
                        ></v-text-field>
                      </DataPanelField>
                    </v-col>
                  </v-row>

                  <SchemaEdit v-model="item.schema_" :fields="fields" />
                </div>
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn depressed color="primary" small @click="$emit('save')">
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <FieldList :items="fields" class="overflow-auto" />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveValidationRequest } from '@api-i/routes/validation/validation';
import { Field } from '@api/models';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import FieldList from '@web-i/components/FieldList.vue';
import SchemaEdit from '@web-i/components/schema/SchemaEdit.vue';

export type ValidationDetailDataType = {
  show: boolean;
  item: SaveValidationRequest;
  newMode: boolean;
};

export const initValidationDetailItem = {
  message: '',
  schema_: [],
  unitId: '',
};

export default Vue.extend({
  name: 'ValidationDetail',

  components: {
    DataPanelField,
    FieldList,
    SchemaEdit,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<ValidationDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveValidationRequest {
        return this.value.item;
      },
      set(item: SaveValidationRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },
  },
});
</script>
