<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>帳票</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small outlined @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
            <template #item.upload="{ item }">
              <v-edit-dialog
                :return-value.sync="item.file"
                large
                cancel-text="キャンセル"
                save-text="適用"
                @save="upload(item)"
              >
                <v-icon small> mdi-upload </v-icon>
                <template #input>
                  <div class="mt-5">
                    <v-file-input
                      v-model="item.file"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    />
                  </div>
                </template>
              </v-edit-dialog>
            </template>
            <template #item.download="{ item }">
              <v-icon small @click.stop="download(item)"> mdi-download </v-icon>
            </template>
          </custom-data-table>
          <ReportDetail v-model="dialog" @save="save" @destroy="destroy" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  DeleteReportRequest,
  DeleteReportResponse,
  OrderReportRequest,
  OrderReportResponse,
  SaveReportRequest,
  SaveReportResponse,
  UploadReportRequest,
  UploadReportResponse,
} from '@api-i/routes/report/report';
import { Report } from '@api/models';
import {
  base64ToArrayBuffer,
  downloadDocument,
} from '@web/modules/document-handler';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadReport, loadReports } from '@web/modules/master-loader';
import { mapActions } from '@web/store/snackbar';
import Vue from 'vue';
import ReportDetail from './ReportDetail.vue';

export default Vue.extend({
  name: 'MastersReports',

  pageOptions: {
    routeConfig: {
      path: '/masters/reports',
    },
    props: {
      title: 'マスタ設定 - 帳票',
    },
  },

  components: {
    ReportDetail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '対象', value: 'targetName', cellClass: 'pointer' },
        {
          text: 'アップロード',
          value: 'upload',
          cellClass: 'pointer',
          align: 'center',
        },
        {
          text: 'ダウンロード',
          value: 'download',
          cellClass: 'pointer',
          align: 'center',
        },
      ],
      items: [] as Report[],
    },

    dialog: {
      show: false,
      item: {} as any,
      newMode: false,
    },
  }),

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      const reports = await loadReports(this, {
        facilityId: facilityId,
      });

      if (reports) {
        this.table.items = reports;
      }
    },

    open() {
      const facilityId = this.selectedFacility.id;
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', { name: '', target: '', facilityId });
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: Report) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', JSON.parse(JSON.stringify(item)));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const data = structuredClone(this.dialog.item);
        const result = await this.$api<SaveReportResponse, SaveReportRequest>({
          path: '/report/save',
          method: 'post',
          params: { ...data },
        });
        this.dialog.show = false;
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeleteReportResponse,
          DeleteReportRequest
        >({
          path: '/report/delete',
          method: 'post',
          params: this.dialog.item as DeleteReportRequest,
        });
        this.dialog.show = false;
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      if (!this.table.items) {
        return;
      }
      try {
        const result = await this.$api<OrderReportResponse, OrderReportRequest>(
          {
            path: '/report/order',
            method: 'post',
            params: {
              items: this.table.items.map((item, order) => {
                item.order = order + 1;
                return item;
              }),
            },
          },
        );
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async upload(item: any) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const dataURI = fileReader.result;
          if (!dataURI || typeof dataURI !== 'string') {
            return;
          }
          const base64EncodedFile = dataURI.replace(/data:.*\/.*;base64,/, '');
          const template = base64EncodedFile;
          const data = {
            id: item.id,
            fileTemplate: template,
          };
          const result = await this.$api<
            UploadReportResponse,
            UploadReportRequest
          >({
            path: '/report/upload',
            method: 'post',
            params: { ...data },
          });
          this.dialog.show = false;
          if (result) {
            this.snackbarRegister({
              type: 'success',
              message: 'ファイルをアップロードしました。',
            });
          }
        };
        fileReader.readAsDataURL(item.file);
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'ファイルのアップロードに失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async download(item: any) {
      const report = await loadReport(item.id, this, { scope: 'template' });
      if (!report) {
        return;
      }
      if (!report.fileTemplate) {
        return this.snackbarRegister({
          type: 'error',
          message: 'この帳票のファイルは、まだアップロードされていません。',
        });
      }
      const buffer = base64ToArrayBuffer(report.fileTemplate);
      const fileName = report.name + '_テンプレート';
      await downloadDocument({
        data: buffer,
        fileName: fileName,
      });
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
