<template>
  <DataPanelField :label="label">
    <v-edit-dialog
      v-if="!readonly"
      :return-value.sync="_value"
      @save="$emit('save')"
    >
      <slot name="display">
        <span>
          {{ _value }}
        </span>
      </slot>
      <v-icon small class="ml-2">mdi-pencil</v-icon>
      <template #input>
        <slot>
          <v-text-field v-model="_value" single-line></v-text-field>
        </slot>
      </template>
    </v-edit-dialog>
    <template v-else>
      <slot name="display">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ _value }}
            </span>
          </template>
          <span>{{ readonlyText }}</span>
        </v-tooltip>
      </slot>
    </template>
  </DataPanelField>
</template>

<script lang="ts">
import Vue from 'vue';
import DataPanelField from './DataPanelField.vue';

export default Vue.extend({
  name: 'DataPanelFieldWrap',

  components: {
    DataPanelField,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: undefined,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    readonlyText: {
      type: String,
      default: '編集できません',
    },
  },

  computed: {
    _value: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit('input', newValue);
      },
    },
  },
});
</script>
