import {
  StoreMasterActions,
  StoreMasterGetters,
  StoreMasterMutations,
  StoreMasterState,
} from '@web-t/store/master';
import { StoreRootInternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreMasterState = {
  // 施設
  facility: {
    selected: null,
  },
};

const getters: DefineGetters<StoreMasterGetters, StoreMasterState> = {
  /**
   * 選択した施設
   * @param state
   * @returns
   */
  selectedFacility(state) {
    return state.facility.selected;
  },
};

const mutations: DefineMutations<StoreMasterMutations, StoreMasterState> = {
  setSelectedFacility(state, payload) {
    state.facility.selected = payload;
  },
};

const actions: DefineActions<
  StoreMasterActions,
  StoreMasterState,
  StoreMasterMutations,
  StoreMasterGetters
> = {
  setSelectedFacility({ commit }, payload) {
    commit('setSelectedFacility', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreMasterState,
    StoreMasterGetters,
    StoreMasterMutations,
    StoreMasterActions
  >('master');

const storeModule: Module<StoreMasterState, StoreRootInternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreMasterState,
    StoreRootInternal
  >,
};

export default storeModule;
