<template>
  <v-dialog v-model="show" scrollable width="1440" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-text class="text--primary">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" md="8" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <v-card-title>{{
                  isNewMode ? '受付期間を追加' : '詳細'
                }}</v-card-title>
                <v-col>
                  <div class="masterDetail pt-6 pb-6">
                    <DataPanelField label="名称">
                      <v-text-field
                        v-model="item.name"
                        placeholder="例： 夏季"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        :rules="[(v) => !!v || '名称は必須項目です。']"
                      ></v-text-field>
                    </DataPanelField>
                    <DataPanelField label="受付開始">
                      <ReceiptPeriodSchema v-model="item.from_" />
                    </DataPanelField>
                    <DataPanelField label="受付終了">
                      <ReceiptPeriodSchema v-model="item.to_" />
                    </DataPanelField>
                  </div>
                </v-col>
                <SchemaEdit v-model="item.schema_" :fields="fields" />
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    small
                    :disabled="disabledToSave"
                    @click="$emit('save')"
                  >
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <FieldList :items="fields" class="overflow-auto" />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveReceiptPeriodRequest } from '@api-i/routes/receiptPeriod/receiptPeriod';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import FieldList from '@web-i/components/FieldList.vue';
import SchemaEdit from '@web-i/components/schema/SchemaEdit.vue';
import { periodOperation } from '@api/constants';
import { Field } from '@api/models';
import ReceiptPeriodSchema from './ReceiptPeriodSchema.vue';

export type ReceiptPeriodDetailDataType = {
  show: boolean;
  item: SaveReceiptPeriodRequest;
  newMode: boolean;
};

const initSchema = {
  property: '',
  operation: '',
  value_1: '',
  value_2: '',
};

export const initReceiptPeriodDetailItem = {
  name: '',
  schema_: [],
  from_: structuredClone(initSchema),
  to_: structuredClone(initSchema),
  unitId: '',
};

export default Vue.extend({
  name: 'ReceiptPeriodDetail',

  components: {
    DataPanelField,
    FieldList,
    SchemaEdit,
    ReceiptPeriodSchema,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<ReceiptPeriodDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveReceiptPeriodRequest {
        return this.value.item;
      },
      set(item: SaveReceiptPeriodRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    disabledToSave(): boolean {
      if (!this.item.name) {
        return true;
      }
      if (!this.item.from_) {
        return true;
      }
      return !periodOperation.valid(this.item.from_);
    },
  },
});
</script>
