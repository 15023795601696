<template>
  <v-container class="reserveList">
    <v-row>
      <v-col cols="12" class="pageTtl"><h2>対象予約一覧</h2></v-col>
      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <FilterForm :facilities="facilities" />
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <v-btn small outlined :disabled="isNoSet" @click="load">
                  <v-icon>mdi-magnify</v-icon>
                  検索
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="table.headers"
          :items="tableItems"
          :items-per-page="10"
          item-class="pointer"
          :mobile-breakpoint="768"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          @click:row="handleClickRow"
        >
          <template #item.section="{ item }">
            {{ stringUtility.toString(item.section) }}
          </template>
          <template #item.numOfPeople="{ item }">
            {{ item[item.unit.asNumOfPeople] }}
          </template>
        </v-data-table>
        <InvoiceAdd v-model="dialog" @save="save" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import InvoiceAdd, { InvoiceAddDetail, InvoiceAddItem } from './InvoiceAdd.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import {
  GetAllBooksRequest,
  GetAllBooksResponse,
} from '@api-i/routes/book/book';
import { Book } from '@api/models';
import {
  InsertInvoiceRequest,
  InsertInvoiceResponse,
} from '@api-i/routes/invoice/invoice';
import * as snackbarStore from '@web/store/snackbar';
import { invoiceStatus } from '@api/constants/invoiceStatus';
import { InferCreationAttributesDetail } from '@api/models/Detail';
import FilterForm from '@web-i/components/FilterForm.vue';
import * as loginState from '@web-i/store/login';
import * as filterState from '@web-i/store/filter';
import { dateMethod } from '@api/constants/dateMethod';
import { stringUtility } from '@c/util';
import { ConstantStringUtility } from '@c-t/util/stringUtility';

export default Vue.extend({
  name: 'Billable',

  pageOptions: {
    routeConfig: {
      path: '/billable',
    },
    props: {
      title: '対象予約一覧',
    },
  },

  components: {
    InvoiceAdd,
    FilterForm,
  },

  data: () => ({
    /**
     * 検索結果を一旦ここに入れる
     */
    books: [] as Book[],

    table: {
      headers: [
        { text: '受付番号', value: 'book.receiptNo', cellClass: 'pointer' },
        { text: '施設', value: 'book.facilityName', cellClass: 'pointer' },
        { text: '区画種類', value: 'unit.name', cellClass: 'pointer' },
        { text: '部屋・サイト等', value: 'section', cellClass: 'pointer' },
        { text: '利用日（開始）', value: 'startDate', cellClass: 'pointer' },
        { text: '人数', value: 'numOfPeople', cellClass: 'pointer' },
        { text: '予約者名', value: 'book.name', cellClass: 'pointer' },
      ],
    },

    dialog: {
      show: false,
      item: {} as InvoiceAddItem,
    },
  }),

  computed: {
    bookDict(): Record<string, Book> {
      const result = chain(this.books).keyBy('id').value();

      return result;
    },

    tableItems(): InferCreationAttributesDetail[] {
      const result = chain(this.books)
        .map((book) => {
          const result_ = chain(book.details)
            .map((detail) => {
              if (!detail.unit || detail.unit.isOption) return [];
              return structuredClone({ ...detail, book });
            })
            .flatten()
            .value();

          return result_;
        })
        .flatten()
        .value();

      return result;
    },

    stringUtility(): ConstantStringUtility {
      return stringUtility;
    },

    ...filterState.mapState(['filter']),
    ...filterState.mapGetters(['isNoSet']),
    ...loginState.mapGetters(['facilities']),
  },

  async mounted() {
    await this.init();
    await this.$nextTick();
    await this.load();
  },

  methods: {
    /**
     * 検索条件の初期値を設定する
     */
    async init() {
      const method = dateMethod.today.value;
      const { from, to } = dateMethod.calc(method);
      const from_ = this.$dateFns.fnsFormat(from, '', 'yyyy-MM-dd');
      const to_ = this.$dateFns.fnsFormat(to, '', 'yyyy-MM-dd');

      await this.setFilter({
        ...this.filter,
        dateMethod: method,
        fromDate: from_,
        toDate: to_,
      });
    },

    async load() {
      try {
        const result = await this.$api<GetAllBooksResponse, GetAllBooksRequest>(
          {
            path: '/book',
            method: 'get',
            params: {
              ...this.filter,
              scope: 'books',
            },
          },
        );

        if (result) {
          this.books = result.books;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    handleClickRow(item: InferCreationAttributesDetail) {
      const book = this.bookDict[item.bookId] as InvoiceAddItem;

      this.dialog = {
        show: true,
        item: structuredClone(book),
      };
    },

    async save(details: InvoiceAddDetail[]) {
      try {
        const result = await this.$api<
          InsertInvoiceResponse,
          InsertInvoiceRequest
        >({
          path: '/invoice/create',
          method: 'post',
          params: {
            status: invoiceStatus.unpaid.value,
            bookId: this.dialog.item.id!,
            details,
          },
        });
        this.dialog.show = false;

        if (result) {
          this.snackbarRegister({
            type: 'success',
            message: '請求データを作成しました。',
          });
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...filterState.mapActions(['setFilter']),
    ...snackbarStore.mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
