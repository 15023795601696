<template>
  <v-dialog v-model="show" scrollable width="1500">
    <v-card class="dialogBox">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.subject"
                outlined
                dense
                hide-details="auto"
                clearable
                label="タイトル"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <mavon-editor
                v-model="item.body"
                language="ja"
                placeholder="本文をここに入力します。"
                :toolbars="{
                  bold: true,
                  italic: true,
                  header: true,
                  underline: true,
                  strikethrough: true,
                  superscript: true,
                  subscript: true,
                  quote: true,
                  ol: true,
                  ul: true,
                  link: true,
                  code: true,
                  table: true,
                  fullscreen: true,
                  readmodel: true,
                  htmlcode: true,
                  help: true,
                  undo: true,
                  redo: true,
                  trash: true,
                  save: true,
                  navigation: true,
                  alignleft: true,
                  aligncenter: true,
                  alignright: true,
                  subfield: true,
                  preview: true,
                }"
                class="markdown-editor"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <date-picker v-model="item.from" label="掲載開始日"></date-picker>
            </v-col>
            <v-col cols="6">
              <date-picker v-model="item.to" label="掲載終了日"></date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" small @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon> 保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  DeleteNoticeForManagerRequest,
  InsertNoticeForManagerRequest,
  UpdateNoticeForManagerRequest,
} from '@api-i/routes/noticeForManager/noticeForManager';
import DatePicker from '@web/components/inputs/date-picker.vue';

export interface NoticeDetailItem
  extends InsertNoticeForManagerRequest,
    UpdateNoticeForManagerRequest,
    DeleteNoticeForManagerRequest {}

export type NoticeDetailDataType = {
  show: boolean;
  item: NoticeDetailItem;
};

export const initItem = {
  id: '',
  subject: '',
  body: '',
  from: '',
  to: '',
};

export default Vue.extend({
  name: 'NoticeDetail',

  components: {
    DatePicker,
  },

  props: {
    value: {
      type: Object as PropType<NoticeDetailDataType>,
      default: () => ({
        show: false,
        item: initItem,
      }),
    },
  },

  data: () => ({
    fromMenu: false,
    toMenu: false,
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },
    item: {
      get(): InsertNoticeForManagerRequest | UpdateNoticeForManagerRequest {
        return this.value.item;
      },
      set(
        item: InsertNoticeForManagerRequest | UpdateNoticeForManagerRequest,
      ): void {
        this.$emit('input', { ...this.value, item });
      },
    },
  },
});
</script>
