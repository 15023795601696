import { StoreRootInternal } from '@web-t/store/root';
import {
  StoreFilterActions,
  StoreFilterGetters,
  StoreFilterMutations,
  StoreFilterState,
} from '@web-t/store/filter';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';
import { dateMethod } from '@api/constants/dateMethod';

const state: StoreFilterState = {
  filter: {
    facilityId: [],
    receiptNo: '',
    dateMethod: dateMethod.manual.value,
    fromDate: '',
    toDate: '',
    name: '',
  },
};

const getters: DefineGetters<StoreFilterGetters, StoreFilterState> = {
  isNoSet(state) {
    const result =
      state.filter.facilityId.length === 0 &&
      !state.filter.fromDate &&
      !state.filter.toDate &&
      !state.filter.name &&
      !state.filter.receiptNo;

    return result;
  },
};

const mutations: DefineMutations<StoreFilterMutations, StoreFilterState> = {
  setFilter(state, payload) {
    state.filter = payload;
  },
  clearFilter(state) {
    state.filter = {
      facilityId: [],
      receiptNo: '',
      dateMethod: dateMethod.manual.value,
      fromDate: '',
      toDate: '',
      name: '',
    };
  },
  setFacilityId(state, payload) {
    state.filter = {
      ...state.filter,
      facilityId: [...payload],
    };
  },
  setReceiptNo(state, payload) {
    state.filter = {
      ...state.filter,
      receiptNo: payload,
    };
  },
  setDateMethod(state, payload) {
    state.filter = {
      ...state.filter,
      dateMethod: payload,
    };
  },
  setFromDate(state, payload) {
    state.filter = {
      ...state.filter,
      fromDate: payload,
    };
  },
  setToDate(state, payload) {
    state.filter = {
      ...state.filter,
      toDate: payload,
    };
  },
  setName(state, payload) {
    state.filter = {
      ...state.filter,
      name: payload,
    };
  },
};

const actions: DefineActions<
  StoreFilterActions,
  StoreFilterState,
  StoreFilterMutations,
  StoreFilterGetters
> = {
  setFilter({ commit }, payload) {
    commit('setFilter', payload);
  },
  clearFilter({ commit }) {
    commit('clearFilter');
  },
  setFacilityId({ commit }, payload) {
    commit('setFacilityId', payload);
  },
  setReceiptNo({ commit }, payload) {
    commit('setReceiptNo', payload);
  },
  setDateMethod({ commit }, payload) {
    commit('setDateMethod', payload);
  },
  setFromDate({ commit }, payload) {
    commit('setFromDate', payload);
  },
  setToDate({ commit }, payload) {
    commit('setToDate', payload);
  },
  setName({ commit }, payload) {
    commit('setName', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreFilterState,
    StoreFilterGetters,
    StoreFilterMutations,
    StoreFilterActions
  >('filter');

const storeModule: Module<StoreFilterState, StoreRootInternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreFilterState,
    StoreRootInternal
  >,
};

export default storeModule;
