<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? '入力項目を追加' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-alert type="warning" icon="mdi-alert" dense outlined
          >特定の管理者のみ入力項目を編集できます。</v-alert
        >
        <div class="masterDetail">
          <DataPanelField label="名称">
            <v-text-field
              v-model="item.name"
              outlined
              dense
              hide-details="auto"
              clearable
              :disabled="!isAdmin"
              :rules="[(v) => !!v || '名称は必須項目です。']"
            ></v-text-field>
          </DataPanelField>

          <DataPanelField label="対象">
            <v-select
              v-model="item.property"
              :items="inputProperty.items"
              outlined
              dense
              hide-details="auto"
              clearable
              :disabled="!isAdmin"
              :rules="[(v) => !!v || '対象は必須項目です。']"
            ></v-select>
          </DataPanelField>

          <DataPanelField label="入力タイプ">
            <v-select
              v-model="item.type"
              :items="inputType.items"
              outlined
              dense
              hide-details="auto"
              clearable
              :disabled="!isAdmin"
              :rules="[(v) => !!v || '入力タイプは必須項目です。']"
              @change="typeChanges"
            ></v-select>
          </DataPanelField>
          <!-- 単一/複数選択 -->
          <DataPanelField
            v-if="
              item.type === inputType.enumSingle.value ||
              item.type === inputType.enumMultiple.value
            "
            label="選択肢"
          >
            <v-textarea
              v-model="item.items"
              placeholder="1行に1つの選択肢を入力"
              outlined
              dense
              hide-details="auto"
              clearable
              class="mt-0 pt-0"
            />
          </DataPanelField>

          <!-- 数値 -->
          <DataPanelField
            v-if="item.type === inputType.number.value"
            label="最大値"
          >
            <NumberInput
              v-model="item.max"
              outlined
              dense
              hide-details="auto"
              clearable
            />
          </DataPanelField>

          <!-- チェックボックス -->
          <DataPanelField
            v-if="item.type === inputType.checkbox.value"
            label="ラベル"
          >
            <v-text-field
              v-model="item.label"
              placeholder="例： 確認しました"
              class="mt-0 pt-0"
              outlined
              dense
              hide-details="auto"
              clearable
            ></v-text-field>
          </DataPanelField>

          <DataPanelField label="ヒント">
            <v-text-field
              v-model="item.hint"
              class="mt-0 pt-0"
              outlined
              dense
              hide-details="auto"
              clearable
            ></v-text-field>
          </DataPanelField>

          <DataPanelField label="入力サイト">
            <BooleanInput
              v-model="item.isPublic"
              true-text="公開サイトと管理サイト"
              false-text="管理サイトのみ"
              :disabled="!isAdmin"
            />
          </DataPanelField>

          <DataPanelField label="アイコン">
            <IconPicker v-model="item.icon" :disabled="!isAdmin" />
          </DataPanelField>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isNewMode"
          depressed
          small
          class="red"
          @click="$emit('destroy')"
        >
          <v-icon>mdi-delete-outline</v-icon>
          <span>区画種類から削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!isAdmin"
          @click="$emit('save')"
        >
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import BooleanInput from '@web/components/inputs/boolean.vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { UpdateFieldRequest } from '@api-i/routes/field/field';
import { inputProperty, inputType } from '@api/constants';
import IconPicker from '@web-i/components/icon/IconPicker.vue';
import NumberInput from '@web/components/inputs/number-input.vue';

export type FieldDetailDataType = {
  show: boolean;
  item: UpdateFieldRequest;
  newMode: boolean;
};

export const initFieldDetailItem: Partial<UpdateFieldRequest> = {
  name: '',
  property: '',
  type: '',
  isPublic: true,
  icon: '',
  items_: [],
};

export default Vue.extend({
  name: 'FieldDetail',

  components: {
    BooleanInput,
    DataPanelField,
    IconPicker,
    NumberInput,
  },

  props: {
    value: {
      type: Object as PropType<FieldDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): UpdateFieldRequest {
        return this.value.item;
      },
      set(item: UpdateFieldRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    inputProperty() {
      return inputProperty;
    },

    inputType() {
      return inputType;
    },

    isAdmin(): boolean {
      return this.$store.getters['login/isAdmin'];
    },
  },

  methods: {
    typeChanges(type: string): void {
      if (
        type !== inputType.enumSingle.value &&
        type !== inputType.enumMultiple.value
      ) {
        this.item.items = '';
      }
    },
  },
});
</script>
