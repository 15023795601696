<template>
  <v-row>
    <v-col cols="4">
      <v-select
        v-model="item.property"
        :items="fields"
        item-text="name"
        item-value="property"
        suffix="が"
        hide-details="auto"
        clearable
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        v-model="item.operation"
        :items="operations"
        hide-details="auto"
        clearable
      ></v-select>
    </v-col>
    <template v-if="numOfArgs > 0">
      <v-col :cols="numOfArgs >= 2 ? 2 : 4">
        <SchemaInputField
          v-model="item.value_1"
          :field="selectedField"
          :operation="item.operation"
          label="$1"
          hide-details="auto"
          clearable
        />
      </v-col>
      <v-col v-if="numOfArgs >= 2" cols="2">
        <SchemaInputField
          v-model="item.value_2"
          :field="selectedField"
          :operation="item.operation"
          label="$2"
          hide-details="auto"
          clearable
        />
      </v-col>
    </template>
  </v-row>
</template>

<script lang="ts">
import { ConstantSchemaOperationItem } from '@api-t/constants/schemaOperation';
import { Schema } from '@api-t/utils';
import { schemaOperation } from '@api/constants';
import { Field } from '@api/models';
import Vue, { PropType } from 'vue';
import SchemaInputField from './SchemaInputField.vue';

export default Vue.extend({
  name: 'SchemaEditRow',

  components: {
    SchemaInputField,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<Schema>,
      default: undefined,
    },
  },

  computed: {
    item: {
      get(): Schema | undefined {
        return this.value;
      },
      set(value: Schema): void {
        this.$emit('input', value);
      },
    },

    selectedField(): Field | undefined {
      const found = this.fields.find(
        (field) => field.property === this.item?.property,
      );

      return found;
    },

    operations(): ConstantSchemaOperationItem[] {
      if (!this.selectedField) {
        return [];
      }
      return schemaOperation.get(this.selectedField.type);
    },

    selectedOperation(): ConstantSchemaOperationItem | undefined {
      const found = this.operations.find(
        (operation) => operation.value === this.item?.operation,
      );

      return found;
    },

    numOfArgs(): number {
      if (!this.selectedOperation) {
        return 2;
      }
      return this.selectedOperation.numOfArgs;
    },
  },
});
</script>
