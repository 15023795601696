<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>区画種類</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small outlined @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
            <template #item.from="{ item }">
              {{ item.from | datetime }}
            </template>
            <template #item.to="{ item }">
              {{ item.to | datetime }}
            </template>
          </custom-data-table>
          <UnitDetail
            v-model="dialog"
            :fields="fields"
            @save="save"
            @destroy="destroy"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  DeleteUnitRequest,
  DeleteUnitResponse,
  OrderUnitRequest,
  OrderUnitResponse,
  SaveUnitRequest,
} from '@api-i/routes/unit/unit';
import { Field, Unit } from '@api/models';
import UnitDetail, { initUnitDetailItem } from './UnitDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { mapActions } from '@web/store/snackbar';
import { loadUnits } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'MastersUnits',

  pageOptions: {
    routeConfig: {
      path: '/masters/units',
    },
    props: {
      title: 'マスタ設定 - 区画種類',
    },
  },

  components: {
    UnitDetail,
  },

  data: () => ({
    fields: [] as Partial<Field>[],
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '貸区分', value: 'typeName', cellClass: 'pointer' },
        { text: '予約単位(分)', value: 'step', cellClass: 'pointer' },
        { text: '最大数量', value: 'maximum', cellClass: 'pointer' },
        { text: '有効期間(from)', value: 'from', cellClass: 'pointer' },
        { text: '有効期間(to)', value: 'to', cellClass: 'pointer' },
      ],
      items: [] as Unit[],
    },

    dialog: {
      show: false,
      item: {},
      newMode: false,
    },
  }),

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      const units = await loadUnits(this, {
        scope: 'fields',
        facilityId: facilityId,
      });
      if (units) {
        this.table.items = units.filter((u) => !u.isOption);
      }
    },

    open() {
      const facilityId = this.selectedFacility.id;
      this.$set(this, 'fields', []);
      this.$set(this.dialog, 'show', true);
      this.$set(
        this.dialog,
        'item',
        structuredClone({ ...initUnitDetailItem, facilityId }),
      );
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: Unit) {
      this.$set(this, 'fields', item.fields);
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      const data = this.dialog.item as SaveUnitRequest;
      try {
        const result = await this.$api({
          path: '/unit/save',
          method: 'post',
          params: {
            ...data,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<DeleteUnitResponse, DeleteUnitRequest>({
          path: '/unit/delete',
          method: 'post',
          params: this.dialog.item as DeleteUnitRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      if (!this.table.items) {
        return;
      }
      try {
        const result = await this.$api<OrderUnitResponse, OrderUnitRequest>({
          path: '/unit/order',
          method: 'post',
          params: {
            items: this.table.items.map((item, order) => {
              item.order = order + 1;
              return item;
            }),
          },
        });

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>

<style lang="scss" scoped></style>
