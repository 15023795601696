<template>
  <v-container class="status">
    <v-row>
      <v-col cols="12" class="pageTtl">
        <h2>統計情報</h2>
      </v-col>
      <v-col cols="12">
        <v-card tile>
          <v-container>
            <v-row class="align-center">
              <v-col cols="12" sm="3">
                <v-select
                  v-model="method"
                  :items="filteredItems"
                  outlined
                  dense
                  hide-details="auto"
                  label="集計期間"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-btn large @click="downloadXlsx">
                <v-icon class="white--text"> mdi-download </v-icon>
                EXCELダウンロード
              </v-btn>
            </v-row>
          </v-container>

          <v-data-table
            :headers="table.headers"
            :items="tableItems"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, -1],
            }"
          >
            <template #item.counts="{ item }">
              {{ Number(item.counts || 0).toLocaleString() }}
            </template>
            <template #item.number="{ item }">
              {{ Number(item.number || 0).toLocaleString() }}
            </template>
            <template #item.realNumber="{ item }">
              {{ Number(item.realNumber || 0).toLocaleString() }}
            </template>
            <template #item.visitor="{ item }">
              {{ item.visitor || '-' }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  GetAllDetailsRequest,
  GetAllDetailsResponse,
} from '@api-i/routes/detail/detail';
import { ConstantDateMethodItem } from '@api-t/constants/dateMethod';
import { dateMethod } from '@api/constants/dateMethod';
import { Detail, Facility, Unit } from '@api/models';
import { objectUtility } from '@c/util/objectUtility';
import { mapGetters } from '@web-i/store/login';
import { downloadDocument, getFileName } from '@web/modules/document-handler';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadCacheUnits } from '@web/modules/master-loader';
import {
  bookColumns,
  generateWorkbook,
} from '@web/modules/spreadsheet-handler';
import { getStatsListData } from '@web/modules/stats-calculator';
import { chain } from 'lodash';
import Vue from 'vue';

type statsItem = Facility & {
  counts?: number;
  number?: number;
  realNumber?: number;
  visitor?: string;
};

export default Vue.extend({
  name: 'Stats',

  pageOptions: {
    routeConfig: {
      path: '/stats',
    },
    props: {
      title: '統計情報',
    },
  },

  data: () => ({
    units: [] as Unit[],

    method: dateMethod.last7days.value,

    table: {
      headers: [
        { text: '施設名', value: 'name' },
        { text: '予約件数', value: 'counts', align: 'end' },
        { text: '予約人数', value: 'number', align: 'end' },
        { text: '実績人数', value: 'realNumber', align: 'end' },
        { text: '来場率', value: 'visitor', align: 'center' },
      ],
      items: [] as statsItem[],
    },

    details: [] as Detail[],
  }),

  computed: {
    facilityDict(): Record<string, Facility> {
      return chain(this.facilities).keyBy('id').value();
    },

    unitDict(): Record<string, Unit> {
      const result = chain(this.units).keyBy('id').value();

      return result;
    },

    tableItems(): statsItem[] {
      return getStatsListData(this.details, this.facilityDict);
    },

    filteredItems(): ConstantDateMethodItem[] {
      const result = dateMethod.items.filter((item) => {
        const result_ = !/manual|tomorrow|^next/.test(item.value);

        return result_;
      });

      return result;
    },

    ...mapGetters(['facilities']),
  },

  watch: {
    method: {
      handler: function () {
        this.load();
      },
    },
  },

  async mounted() {
    await Promise.all([this.load(), this.loadUnit()]);
  },

  methods: {
    async load() {
      const { from, to } = dateMethod.calc(this.method);
      const startDate_ = this.$dateFns.fnsFormat(from, '', 'yyyy-MM-dd');
      const endDate_ = this.$dateFns.fnsFormat(to, '', 'yyyy-MM-dd');

      try {
        const response = await this.$api<
          GetAllDetailsResponse,
          GetAllDetailsRequest
        >({
          path: '/detail',
          method: 'get',
          params: {
            scope: 'nonOption',
            startDate: startDate_,
            endDate: endDate_,
          },
        });

        if (!response) {
          return;
        }

        this.details = response.details;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 区画種類マスタを読み込む
     */
    async loadUnit() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * EXCELダウンロード
     */
    async downloadXlsx() {
      const sheets = chain(this.details)
        .map((item) => objectUtility.flatten(item, (v) => Number(v) || v))
        .groupBy('unitId')
        .mapValues((details, unitId) => {
          const { name, fields } = this.unitDict[unitId];
          const columns = chain(fields)
            .map((field) => {
              const { name, property } = field;
              return { header: name, key: property };
            })
            .value();
          const columns_ = [...bookColumns, ...columns];

          return { name, columns: columns_, rows: details };
        })
        .values()
        .value();

      const book = await generateWorkbook({ sheets });
      const fileName = getFileName(this, '統計情報', 'xlsx');
      await downloadDocument({
        data: book,
        fileName: fileName,
      });
    },
  },
});
</script>
