<template>
  <v-select
    v-if="/in|notIn/i.test(operation)"
    v-model="value_"
    :items="field.items_"
    :label="label"
    :hide-details="hideDetails"
    :clearable="clearable"
  />
  <v-select
    v-else-if="/dayOfWeek/i.test(operation)"
    v-model="value_"
    :items="daysOfWeek.items"
    :item-text="(v) => v.text.charAt(0)"
    :item-value="(v) => String(v.value)"
    :label="label"
    :hide-details="hideDetails"
    :clearable="clearable"
  />
  <v-text-field
    v-else
    v-model="value_"
    :label="label"
    :hide-details="hideDetails"
    :clearable="clearable"
  />
</template>

<script lang="ts">
import { ConstantDaysOfWeek } from '@api-t/constants/daysOfWeek';
import { daysOfWeek } from '@api/constants';
import { Field } from '@api/models';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SchemaInputField',

  props: {
    value: {
      type: String,
      default: '',
    },

    field: {
      type: Object as PropType<Field>,
      default: undefined,
    },

    operation: {
      type: String,
      default: undefined,
    },

    label: {
      type: String,
      default: undefined,
    },

    hideDetails: {
      type: [Boolean, String],
      default: undefined,
    },

    clearable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    value_: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },

    daysOfWeek(): ConstantDaysOfWeek {
      return daysOfWeek;
    },
  },
});
</script>
