<template>
  <v-row>
    <template v-if="typeof item === 'object'">
      <v-col cols="6">
        <v-select
          v-model="item.operation"
          :items="operations"
          hide-details="auto"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="3">
        <DatePicker
          v-if="isDateType"
          v-model="item.value_1"
          :outlined="false"
          :dense="false"
          label="$1"
          hide-details="auto"
          clearable
        />
        <v-text-field
          v-else
          v-model="item.value_1"
          label="$1"
          hide-details="auto"
          clearable
        ></v-text-field>
      </v-col>
      <v-col v-if="numOfArgs >= 2" cols="3">
        <v-text-field
          v-model="item.value_2"
          label="$2"
          hide-details="auto"
          clearable
        ></v-text-field>
      </v-col>
    </template>
  </v-row>
</template>

<script lang="ts">
import {
  ConstantPeriodOperationItem,
  ConstantPeriodOperationKey,
} from '@api-t/constants/periodOperation';
import { Schema } from '@api-t/utils';
import { periodOperation } from '@api/constants';
import DatePicker from '@web/components/inputs/date-picker.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ReceiptPeriodSchema',

  components: {
    DatePicker,
  },

  props: {
    value: {
      type: Object as PropType<Schema<ConstantPeriodOperationKey>>,
      default: undefined,
    },
  },

  computed: {
    item: {
      get(): Schema<ConstantPeriodOperationKey> {
        return this.value;
      },
      set(item: Schema<ConstantPeriodOperationKey>): void {
        this.$emit('input', { ...item });
      },
    },

    operations(): ConstantPeriodOperationItem[] {
      return periodOperation.items;
    },

    selectedOperation(): ConstantPeriodOperationItem | undefined {
      const found = this.operations.find(
        (operation) => operation.value === this.item?.operation,
      );

      return found;
    },

    numOfArgs(): number {
      if (!this.selectedOperation) {
        return 2;
      }
      return this.selectedOperation.numOfArgs;
    },

    isDateType(): boolean {
      if (!this.selectedOperation) {
        return false;
      }
      return this.selectedOperation.value === periodOperation.any.value;
    },
  },

  watch: {
    isDateType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.item = { ...this.item, value_1: '', value_2: '' };
      }
    },
  },
});
</script>
