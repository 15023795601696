<template>
  <v-row>
    <v-col cols="12">
      <v-list dense>
        <v-subheader>トリガー</v-subheader>
        <template v-for="(_, i) in items">
          <v-list-item :key="i" class="pl-0 pr-0">
            <v-list-item-content>
              <SchemaEditRow v-model="items[i]" :fields="fields" />
            </v-list-item-content>
            <v-list-item-action>
              <button @click="deleteItems(i)">
                <v-icon>mdi-minus-circle</v-icon>
              </button>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-btn depressed x-small outlined @click="addItems">
        <v-icon>mdi-plus</v-icon>
        トリガーを追加
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Schema } from '@api-t/utils';
import { Field } from '@api/models';
import SchemaEditRow from './SchemaEditRow.vue';

export default Vue.extend({
  name: 'SchemaEdit',

  components: {
    SchemaEditRow,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    value: {
      type: Array as PropType<Schema[]>,
      default: () => [],
    },
  },

  computed: {
    items() {
      return this.value;
    },
  },

  methods: {
    addItems() {
      this.$emit('input', [
        ...this.items,
        {
          property: '',
          operation: '',
          value_1: '',
          value_2: '',
        },
      ]);
    },

    deleteItems(index: number) {
      this.$emit('input', [
        ...this.items?.slice(0, index),
        ...this.items?.slice(index + 1),
      ]);
    },
  },
});
</script>
