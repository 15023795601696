<template>
  <InternalLayout>
    <transition name="page" mode="out-in">
      <router-view />
    </transition>
  </InternalLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import InternalLayout from '@web-i/components/layout/index.vue';
import { CognitoUserDetail } from '@web-t/cognito';
import {
  GetCurrentUserRequest,
  GetCurrentUserResponse,
} from './routes/auth/auth';
import { mapActions } from './store/login';

export default Vue.extend({
  name: 'App',

  components: {
    InternalLayout,
  },

  watch: {
    '$store.state.login.loggedInUser': {
      handler(to: CognitoUserDetail | null, from: CognitoUserDetail | null) {
        // ユーザ名に変更があったらDBからの情報も更新する
        if (to?.user.getUsername() !== from?.user?.getUsername()) {
          this.loadUserDetail();
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.loadUserDetail();
  },

  methods: {
    async loadUserDetail() {
      // 現在のcognitoユーザを取得
      const currentCognitoUser = await this.$cognito.getLoggedInUserDetail();
      // cognitoユーザがある場合のみ取得
      if (currentCognitoUser) {
        try {
          const result = await this.$api<
            GetCurrentUserResponse,
            GetCurrentUserRequest
          >({
            path: '/auth/getCurrentUser',
            method: 'get',
          });
          await this.setDBUser(result?.userInfo || null);
          return;
        } catch (error) {
          // ignored
        }
      }
      await this.setDBUser(null);
    },

    ...mapActions(['setDBUser']),
  },
});
</script>

<style lang="scss" scoped>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.4s ease;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
</style>
