import Vue from 'vue';
import draggable from 'vuedraggable';

export default Vue.extend({
  name: 'CustomDraggable',
  extends: draggable,

  props: {
    skip: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    _getVmIndex: (draggable as any).methods.getVmIndex,
    getVmIndex(domIndex: number) {
      const result = this._getVmIndex(domIndex);
      return result + this.skip;
    },

    _getUnderlyingVm: (draggable as any).methods.getUnderlyingVm,
    getUnderlyingVm(htmlElt: any) {
      const result = this._getUnderlyingVm(htmlElt);
      return {
        ...result,
        index: result.index + this.skip,
      };
    },
  },
});
