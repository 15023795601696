import { ConstantObjectUtility } from '@c-t/util/objectUtility';

export const objectUtility: ConstantObjectUtility = {
  toRawType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  },

  flatten(obj, cb = (v) => v) {
    if (!obj) {
      return undefined;
    }
    const result = Object.keys(obj).reduce((acc, crr) => {
      const value = obj[crr];
      const type = objectUtility.toRawType(value);
      if (type !== 'Object') {
        return {
          ...acc,
          [crr]: cb(value),
        };
      }

      const flattened = objectUtility.flatten(value, cb);
      const merged = Object.keys(flattened).reduce(
        (acc_, crr_) => ({
          ...acc_,
          [`${crr}.${crr_}`]: flattened[crr_],
        }),
        {},
      );

      return {
        ...acc,
        ...merged,
      };
    }, {});

    return result;
  },
};
