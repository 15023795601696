import { StoreRootInternal } from '@web-t/store/root';
import { Store } from 'vuex';
import { Cognito } from './cognito';

/**
 * ログインチェックしてstoreに保存する
 * @param param0
 */
export async function loggedInCheck({
  store,
  cognito,
}: {
  store: Store<StoreRootInternal>;
  cognito: Cognito;
}) {
  // ログイン済みのユーザがいるか確認
  const loggedInUser = await cognito.getLoggedInUserDetail();
  // 値があってもなくてもstoreにセット
  await store.dispatch('login/setLoggedInUser', loggedInUser);
}
