<template>
  <v-dialog v-model="show" scrollable width="1000" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>請求データ作成</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-row>
          <v-col cols="6">
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.receiptNo }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.facilityName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.kana }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.address }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.zipCode }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.tel }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col cols="6">
            <v-list subheader two-line flat>
              <v-list-item-group v-model="selected" multiple>
                <v-list-item v-for="detail in details" :key="detail.feeId">
                  <template #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ detail.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ detail.date }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        {{ detail.amount.toLocaleString() }}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <div class="pa-4 text-right">
          {{ summary.toLocaleString() + '円（税込）' }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="save">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { UpdateBookRequest } from '@api-i/routes/book/book';
import { Unit } from '@api/models';
import { loadCacheUnits } from '@web/modules/master-loader';
import { UpdateDetailRequest } from '@api-i/routes/detail/detail';
import { chain, sortBy } from 'lodash';
import { InferAttributesInvoiceDetail } from '@api/models/InvoiceDetail';
import HolidaySearcher from '@web/modules/holiday-searcher';
import { calcInvoiceDetails } from '@web/modules/fee-calculator';

export interface InvoiceAddItem extends UpdateBookRequest {}

export type InvoiceAddDataType = {
  show: boolean;
  item: InvoiceAddItem;
};

export type InvoiceAddDetail = Omit<InferAttributesInvoiceDetail, 'invoiceId'>;

export default Vue.extend({
  name: 'InvoiceAdd',

  props: {
    value: {
      type: Object as PropType<InvoiceAddDataType>,
      default: undefined,
    },
  },

  data: () => ({
    // 区画種類マスタ
    units: [] as Unit[],

    // 祝日
    holidays: [] as string[],

    details: [] as InvoiceAddDetail[],

    selected: [] as number[],
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): InvoiceAddItem {
        return this.value.item;
      },
      set(item: InvoiceAddItem): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    summary() {
      const result = chain(this.selected)
        .map((idx) => {
          const detail = this.details[idx];

          return detail.amount;
        })
        .sum()
        .value();

      return result;
    },
  },

  watch: {
    show(value: boolean) {
      if (!value) {
        this.initialize();
      } else {
        this.distributeData();
      }
    },
  },

  async mounted() {
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadUnits(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
    ]);
  },

  methods: {
    initialize() {
      this.details = [];
      this.selected = [];
    },
    /**
     * 区画種類マスタを読み込む
     */
    async loadUnits() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },

    distributeData() {
      const details_ = [] as InvoiceAddDetail[];

      const units_ = sortBy(
        this.units.filter((unit) => unit.facilityId === this.item.facilityId),
        ['isOption', 'order'],
      );

      units_.forEach((unit) => {
        const details__ = sortBy(
          (this.item.details as UpdateDetailRequest[]).filter(
            (d) => d.unitId === unit.id,
          ),
          'startDate',
        );
        details__.forEach((detail) => {
          if (!unit.fees) {
            return;
          }
          const invoiceDetails = calcInvoiceDetails(
            this,
            detail,
            unit.fees,
            this.holidays,
          ) as InvoiceAddDetail[];
          details_.push(...invoiceDetails);
        });
      });

      this.details = details_.map((d, i) => ({ ...d, order: i + 1 }));
      this.selected = [...Array(details_.length).keys()];
    },

    save() {
      const result = this.selected.map((i) => this.details[i]);

      this.$emit('save', result);
    },
  },
});
</script>
