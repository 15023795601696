<template>
  <v-container class="home">
    <v-row>
      <v-col cols="12" md="4" class="numberWrap">
        <v-row>
          <v-col cols="12">
            <v-card tile>
              <v-container class="numberRes">
                <v-row>
                  <v-col cols="4" sm="2" md="4" xl="3" class="iconBox">
                    <div class="icon">
                      <v-icon class="white--text">
                        mdi-calendar-blank-outline
                      </v-icon>
                    </div>
                  </v-col>
                  <v-col cols="8" sm="10" md="8" xl="9" class="numberBox">
                    <p>本日の予約数</p>
                    <div class="number">
                      <span class="number__number">{{ todayReserve }}</span>
                      <span class="number__unit">件</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card tile>
              <v-container class="numberRes">
                <v-row>
                  <v-col cols="4" sm="2" md="4" xl="3" class="iconBox">
                    <div class="icon">
                      <v-icon class="white--text">mdi-account-multiple</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="8" sm="10" md="8" xl="9" class="numberBox">
                    <p>本日の来場予定人数</p>
                    <div class="number">
                      <span class="number__number">{{ todayNumOfPeople }}</span>
                      <span class="number__unit">人</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" class="newsWrap">
        <v-card tile class="newsBox">
          <v-card-title>お知らせ</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="newsList" role="list">
                  <li
                    v-for="item in notice.items"
                    :key="item.id"
                    class="newsList__item"
                    tabindex="0"
                    @click="openNews(item)"
                    @keydown.enter.prevent="openNews(item)"
                  >
                    <span class="date">{{ item.from }}</span>
                    <span class="ttl">{{ item.subject }}</span>
                  </li>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <news-detail v-model="notice.dialog" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-card-title>本日の予約件数</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>施設名</th>
                      <th>予約件数</th>
                      <th>予約人数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, iIndex) in tableItems"
                      :key="`${iIndex}-${i}`"
                    >
                      <td>{{ i.name }}</td>
                      <td class="text-right">
                        {{ Number(i.counts || 0).toLocaleString() }}
                      </td>
                      <td class="text-right">
                        {{ Number(i.number || 0).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  GetAllDetailsRequest,
  GetAllDetailsResponse,
} from '@api-i/routes/detail/detail';
import {
  GetAllNoticeForManagerRequest,
  GetAllNoticeForManagerResponse,
} from '@api-i/routes/noticeForManager/noticeForManager';
import { Detail, Facility, NoticeForManager } from '@api/models';
import { mapGetters } from '@web-i/store/login';
import { chain } from 'lodash';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import Vue from 'vue';
import { getStatsListData } from '@web/modules/stats-calculator';
import NewsDetail from './NewsDetail.vue';

type ReserveItem = Facility & {
  counts?: number;
  number?: number;
};

export default Vue.extend({
  name: 'Home',

  pageOptions: {
    routeConfig: {
      path: '/',
    },
    props: {
      title: 'ダッシュボード',
    },
  },

  components: { NewsDetail },

  data: () => ({
    notice: {
      items: [] as NoticeForManager[],
      dialog: {
        show: false,
        item: {},
      },
    },

    details: [] as Detail[],
  }),

  computed: {
    todayReserve(): string {
      const result = this.tableItems.reduce((total, item) => {
        if (!item.counts) {
          return total;
        }
        return total + item.counts;
      }, 0);

      return result.toLocaleString();
    },

    todayNumOfPeople(): string {
      const result = this.tableItems.reduce((total, item) => {
        if (!item.number) {
          return total;
        }
        return total + item.number;
      }, 0);

      return result.toLocaleString();
    },

    facilityDict(): Record<string, Facility> {
      return chain(this.facilities).keyBy('id').value();
    },

    tableItems(): ReserveItem[] {
      return getStatsListData(this.details, this.facilityDict);
    },

    ...mapGetters(['facilities']),
  },

  async mounted() {
    await Promise.all([this.loadNotice(), this.loadDetail()]);
  },

  methods: {
    async loadNotice() {
      try {
        const result = await this.$api<
          GetAllNoticeForManagerResponse,
          GetAllNoticeForManagerRequest
        >({
          path: '/noticeForManager',
          method: 'get',
        });

        if (result) {
          this.notice.items = result.notices.filter(
            (notice) => notice.isPublic,
          );
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async loadDetail() {
      try {
        const anyDate = this.$dateFns.fnsFormat(new Date(), '', 'yyyy-MM-dd');
        const response = await this.$api<
          GetAllDetailsResponse,
          GetAllDetailsRequest
        >({
          path: '/detail',
          method: 'get',
          params: { scope: 'nonOption', anyDate },
        });

        this.details = response.details;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    openNews(item: NoticeForManager) {
      this.notice.dialog.show = true;
      this.notice.dialog.item = structuredClone(item);
    },
  },
});
</script>
