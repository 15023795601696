import {
  ConstantDateMethod,
  ConstantDateMethodDict,
} from '@api-t/constants/dateMethod';

const dateMethodDict: ConstantDateMethodDict = {
  manual: {
    text: '日付指定',
    value: 'manual',
  },
  last7days: {
    text: '過去7日間',
    value: 'last7days',
  },
  tomorrow: {
    text: '明日',
    value: 'tomorrow',
  },
  today: {
    text: '今日',
    value: 'today',
  },
  yesterday: {
    text: '昨日',
    value: 'yesterday',
  },
  nextWeek: {
    text: '来週',
    value: 'nextWeek',
  },
  thisWeek: {
    text: '今週',
    value: 'thisWeek',
  },
  lastWeek: {
    text: '先週',
    value: 'lastWeek',
  },
  nextMonth: {
    text: '来月',
    value: 'nextMonth',
  },
  thisMonth: {
    text: '今月',
    value: 'thisMonth',
  },
  lastMonth: {
    text: '先月',
    value: 'lastMonth',
  },
  nextYear: {
    text: '来年',
    value: 'nextYear',
  },
  thisYear: {
    text: '今年',
    value: 'thisYear',
  },
  lastYear: {
    text: '昨年',
    value: 'lastYear',
  },
};

export const dateMethod: ConstantDateMethod = {
  ...dateMethodDict,
  items: [
    dateMethodDict.manual,
    dateMethodDict.last7days,
    dateMethodDict.tomorrow,
    dateMethodDict.today,
    dateMethodDict.yesterday,
    dateMethodDict.nextWeek,
    dateMethodDict.thisWeek,
    dateMethodDict.lastWeek,
    dateMethodDict.nextMonth,
    dateMethodDict.thisMonth,
    dateMethodDict.lastMonth,
    dateMethodDict.nextYear,
    dateMethodDict.thisYear,
    dateMethodDict.lastYear,
  ],
  calc(value) {
    const from = new Date();
    const to = new Date();
    switch (value) {
      case 'last7days': {
        from.setDate(from.getDate() - 7);
        to.setDate(to.getDate() - 1);
        break;
      }
      case 'tomorrow': {
        from.setDate(from.getDate() + 1);
        to.setDate(to.getDate() + 1);
        break;
      }
      case 'today': {
        break;
      }
      case 'yesterday': {
        from.setDate(from.getDate() - 1);
        to.setDate(to.getDate() - 1);
        break;
      }
      case 'nextWeek': {
        from.setDate(from.getDate() + ((from.getDay() ^ 7) % 7) + 1 - 0);
        to.setDate(to.getDate() + ((to.getDay() ^ 7) % 7) + 7);
        break;
      }
      case 'thisWeek': {
        from.setDate(from.getDate() + ((from.getDay() ^ 7) % 7) + 1 - 7);
        to.setDate(to.getDate() + ((to.getDay() ^ 7) % 7) + 0);
        break;
      }
      case 'lastWeek': {
        from.setDate(from.getDate() + ((from.getDay() ^ 7) % 7) + 1 - 14);
        to.setDate(to.getDate() + ((to.getDay() ^ 7) % 7) - 7);
        break;
      }
      case 'nextMonth': {
        from.setDate(1);
        from.setMonth(from.getMonth() + 1);
        to.setDate(1);
        to.setMonth(to.getMonth() + 2);
        to.setDate(0);
        break;
      }
      case 'thisMonth': {
        from.setDate(1);
        to.setDate(1);
        to.setMonth(to.getMonth() + 1);
        to.setDate(0);
        break;
      }
      case 'lastMonth': {
        from.setDate(1);
        from.setMonth(from.getMonth() - 1);
        to.setDate(0);
        break;
      }
      case 'nextYear': {
        from.setDate(1);
        from.setMonth(0);
        from.setFullYear(from.getFullYear() + 1);
        to.setDate(1);
        to.setMonth(0);
        to.setFullYear(to.getFullYear() + 2);
        to.setDate(0);
        break;
      }
      case 'thisYear': {
        from.setDate(1);
        from.setMonth(0);
        to.setDate(1);
        to.setMonth(0);
        to.setFullYear(to.getFullYear() + 1);
        to.setDate(0);
        break;
      }
      case 'lastYear': {
        from.setDate(1);
        from.setMonth(0);
        from.setFullYear(from.getFullYear() - 1);
        to.setDate(1);
        to.setMonth(0);
        to.setDate(0);
        break;
      }
    }

    return { from, to };
  },
};
