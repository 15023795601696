<template>
  <v-container class="reserveList">
    <v-row>
      <v-col cols="12" class="pageTtl"><h2>予約一覧</h2></v-col>
      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <FilterForm :facilities="facilities" />
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <v-btn small outlined :disabled="isNoSet" @click="load">
                  <v-icon>mdi-magnify</v-icon>
                  検索
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-card tile>
          <v-container class="text-right">
            <v-btn large @click="downloadXlsx">
              <v-icon class="white--text">mdi-download</v-icon>
              EXCELダウンロード
            </v-btn>
          </v-container>
        </v-card>
        <v-data-table
          :headers="table.headers"
          :items="tableItems"
          :items-per-page="10"
          item-class="pointer"
          :mobile-breakpoint="768"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          @click:row="handleClickRow"
        >
          <template #item.section="{ item }">
            {{ stringUtility.toString(item.section) }}
          </template>
          <template #item.numOfPeople="{ item }">
            {{ item[item.unit.asNumOfPeople] }}
          </template>
        </v-data-table>
        <BookAdd
          v-model="dialog"
          :facilities="facilities"
          :units="units"
          :reports="reports"
          @save="save"
          @cancel="cancel"
          @send="send"
          @output="output"
        ></BookAdd>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import {
  DeleteBookRequest,
  DeleteBookResponse,
  GetAllBooksRequest,
  GetAllBooksResponse,
  SendMailOnCreatedBookRequest,
  SendMailOnCreatedBookResponse,
  SendMailOnUpdatedBookRequest,
  SendMailOnUpdatedBookResponse,
  UpdateBookRequest,
  UpdateBookResponse,
} from '@api-i/routes/book/book';
import { Book, Report, Unit } from '@api/models';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import BookAdd from '@web-i/components/BookAdd.vue';
import {
  loadCacheReports,
  loadCacheUnits,
  loadReport,
} from '@web/modules/master-loader';
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import {
  generateDocument,
  base64ToArrayBuffer,
  downloadDocument,
  getFileName,
  distributeDocumentContext,
} from '@web/modules/document-handler';
import { InferCreationAttributesDetail } from '@api/models/Detail';
import FilterForm from '@web-i/components/FilterForm.vue';
import * as loginState from '@web-i/store/login';
import * as filterState from '@web-i/store/filter';
import { dateMethod } from '@api/constants/dateMethod';
import {
  bookColumns,
  generateWorkbook,
} from '@web/modules/spreadsheet-handler';
import { objectUtility } from '@c/util/objectUtility';
import * as snackbarStore from '@web/store/snackbar';
import { stringUtility } from '@c/util';
import { ConstantStringUtility } from '@c-t/util/stringUtility';
import HolidaySearcher from '@web/modules/holiday-searcher';

export default Vue.extend({
  name: 'Books',

  pageOptions: {
    routeConfig: {
      path: '/books',
    },
    props: {
      title: '予約一覧',
    },
  },

  components: {
    BookAdd,
    FilterForm,
  },

  data: () => ({
    // 区画種類マスタ
    units: [] as Unit[],

    // 祝日
    holidays: [] as string[],

    reports: [] as Report[],

    /**
     * 検索結果を一旦ここに入れる
     */
    books: [] as Book[],

    table: {
      headers: [
        { text: '受付番号', value: 'book.receiptNo', cellClass: 'pointer' },
        { text: '施設', value: 'book.facilityName', cellClass: 'pointer' },
        { text: '区画種類', value: 'unit.name', cellClass: 'pointer' },
        { text: '部屋・サイト等', value: 'section', cellClass: 'pointer' },
        { text: '利用日（開始）', value: 'startDate', cellClass: 'pointer' },
        { text: '人数', value: 'numOfPeople', cellClass: 'pointer' },
        { text: '予約者名', value: 'book.name', cellClass: 'pointer' },
      ],
    },

    selectedItem: {} as InferCreationAttributesDetail,

    dialog: {
      show: false,
      item: {} as UpdateBookRequest,
      newMode: false,
    },
  }),

  computed: {
    unitDict(): Record<string, Unit> {
      const result = chain(this.units).keyBy('id').value();

      return result;
    },

    bookDict(): Record<string, Book> {
      const result = chain(this.books).keyBy('id').value();

      return result;
    },

    tableItems(): InferCreationAttributesDetail[] {
      const result = chain(this.books)
        .map((book) => {
          const result_ = chain(book.details)
            .map((detail) => {
              if (!detail.unit || detail.unit.isOption) return [];
              return structuredClone({ ...detail, book });
            })
            .flatten()
            .value();

          return result_;
        })
        .flatten()
        .value();

      return result;
    },

    tableItemsWithOption(): InferCreationAttributesDetail[] {
      const result = chain(this.books)
        .map((book) => {
          const result_ = chain(book.details)
            .map((detail) => {
              if (!detail.unit) return [];
              return structuredClone({ ...detail, book });
            })
            .flatten()
            .value();

          return result_;
        })
        .flatten()
        .value();

      return result;
    },

    stringUtility(): ConstantStringUtility {
      return stringUtility;
    },

    ...filterState.mapState(['filter']),
    ...filterState.mapGetters(['isNoSet']),
    ...loginState.mapGetters(['facilities']),
  },

  async mounted() {
    await this.init();
    await this.$nextTick();
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadUnit(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
      this.loadReports(),
      this.load(),
    ]);
  },

  methods: {
    /**
     * 検索条件の初期値を設定する
     */
    async init() {
      const method = dateMethod.today.value;
      const { from, to } = dateMethod.calc(method);
      const from_ = this.$dateFns.fnsFormat(from, '', 'yyyy-MM-dd');
      const to_ = this.$dateFns.fnsFormat(to, '', 'yyyy-MM-dd');

      await this.setFilter({
        ...this.filter,
        dateMethod: method,
        fromDate: from_,
        toDate: to_,
      });
    },

    /**
     * 区画種類マスタを読み込む
     */
    async loadUnit() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },

    /**
     * 帳票マスタを読み込む
     */
    async loadReports() {
      const reports = await loadCacheReports(this);
      this.$set(this, 'reports', reports);
    },

    async load() {
      try {
        const result = await this.$api<GetAllBooksResponse, GetAllBooksRequest>(
          {
            path: '/book',
            method: 'get',
            params: {
              ...this.filter,
              scope: 'books',
            },
          },
        );

        if (result) {
          this.books = result.books;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    handleClickRow(item: InferCreationAttributesDetail) {
      this.selectedItem = item;
      const book = this.bookDict[item.bookId] as UpdateBookRequest;

      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(book));
    },

    async save(details: InsertDetailRequest[]) {
      try {
        const result = await this.$api<UpdateBookResponse, UpdateBookRequest>({
          path: '/book/update',
          method: 'post',
          params: {
            ...this.dialog.item,
            details: [...details],
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 予約取消
     */
    async cancel() {
      if (!confirm('本当に取り消しますか？')) {
        return;
      }
      try {
        const result = await this.$api<DeleteBookResponse, DeleteBookRequest>({
          path: '/book/cancel',
          method: 'post',
          params: this.dialog.item as DeleteBookRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['予約取消に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * メール送信
     */
    async send(type: string) {
      if (type === 'created') {
        await this.sendMailOnCreated();
      } else if (type === 'updated') {
        await this.sendMailOnUpdated();
      }
    },

    /**
     * 予約完了メール
     */
    async sendMailOnCreated() {
      if (!confirm('予約完了メールを送信します。よろしいですか？')) {
        return;
      }
      try {
        const result = await this.$api<
          SendMailOnCreatedBookResponse,
          SendMailOnCreatedBookRequest
        >({
          path: '/book/mail/created',
          method: 'post',
          params: this.dialog.item as SendMailOnCreatedBookRequest,
        });
        this.dialog.show = false;

        if (result) {
          this.snackbarRegister({
            type: 'success',
            message: '予約完了メールを送信しました。',
          });
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              '予約完了メールの送信に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 予約変更メール
     */
    async sendMailOnUpdated() {
      if (!confirm('予約変更メールを送信します。よろしいですか？')) {
        return;
      }
      try {
        const result = await this.$api<
          SendMailOnUpdatedBookResponse,
          SendMailOnUpdatedBookRequest
        >({
          path: '/book/mail/updated',
          method: 'post',
          params: this.dialog.item as SendMailOnUpdatedBookRequest,
        });
        this.dialog.show = false;

        if (result) {
          this.snackbarRegister({
            type: 'success',
            message: '予約変更メールを送信しました。',
          });
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              '予約変更メールの送信に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 帳票出力
     */
    async output(reportId: string) {
      const report = await loadReport(reportId, this, { scope: 'template' });
      if (!report || !report.fileTemplate) {
        return;
      }
      const buffer = base64ToArrayBuffer(report.fileTemplate);
      const unit = this.unitDict[this.selectedItem.unitId];
      const data = distributeDocumentContext(
        this,
        this.selectedItem,
        unit,
        this.holidays,
      );
      const doc = await generateDocument({
        data: data,
        template: buffer,
      });
      const fileName = getFileName(this, report.name);
      await downloadDocument({
        data: doc,
        fileName: fileName,
      });
    },

    /**
     * EXCELダウンロード
     */
    async downloadXlsx() {
      const sheets = chain(this.tableItemsWithOption)
        .map((item) => objectUtility.flatten(item, (v) => Number(v) || v))
        .groupBy('unitId')
        .mapValues((details, unitId) => {
          const { name, fields, isOption } = this.unitDict[unitId];
          const columns = chain(fields)
            .map((field) => {
              const { name, property } = field;
              return { header: name, key: property };
            })
            .value();
          if (isOption) {
            columns.unshift({ header: '利用日', key: 'startDate' });
          }
          const columns_ = [...bookColumns, ...columns];

          return { name, columns: columns_, rows: details };
        })
        .values()
        .value();

      const book = await generateWorkbook({ sheets });
      const fileName = getFileName(this, '予約一覧', 'xlsx');
      await downloadDocument({
        data: book,
        fileName: fileName,
      });
    },

    ...filterState.mapActions(['setFilter']),
    ...snackbarStore.mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
