import Vue from 'vue';
import VueRouter from 'vue-router';
import { setupRouter } from './util';
import { routes } from './pages';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

setupRouter({ router });

export default router;
