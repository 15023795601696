<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>利用目的を追加</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-tabs v-model="tab" color="basil">
          <v-tab v-for="tabItem in tabItems" :key="tabItem">
            {{ tabItem }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-text>
                <div class="masterDetail">
                  <data-panel-field label="名称">
                    <v-text-field
                      v-model="item.name"
                      placeholder="例： キャンプ"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '名称は必須項目です。']"
                    ></v-text-field>
                  </data-panel-field>
                  <data-panel-field label="表示サイズ">
                    <v-select
                      v-model="item.size"
                      :items="purposeSize.items"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || 'サイズは必須項目です。']"
                    ></v-select>
                  </data-panel-field>
                  <data-panel-field label="背景画像名">
                    <v-text-field
                      v-model="item.imageName"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    ></v-text-field>
                  </data-panel-field>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-text-field
                  v-model="search"
                  flat
                  label="キーワードで絞り込む"
                  prepend-inner-icon="mdi-magnify"
                  solo-inverted
                ></v-text-field>
                <v-list>
                  <v-list-item-group>
                    <template v-for="(filteredItem, index) in filteredItems">
                      <v-list-item
                        :key="filteredItem.name"
                        @click="$emit('select', filteredItem.id)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="filteredItem.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="index < filteredItems.length - 1"
                        :key="filteredItem.index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="!tab">
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="$emit('add')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  GetAllPurposesRequest,
  GetAllPurposesResponse,
} from '@api-i/routes/purpose/purpose';
import { Purpose } from '@api/models';
import { SavePurposeRequest } from '@api-i/routes/purpose/purpose';
import { purposeSize } from '@api/constants';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import DataPanelField from '@web-i/components/DataPanelField.vue';

export type PurposeAddDataType = {
  show: boolean;
  item: SavePurposeRequest;
};

export default Vue.extend({
  name: 'PurposeAdd',

  components: {
    DataPanelField,
  },

  props: {
    value: {
      type: Object as PropType<PurposeAddDataType>,
      default: undefined,
    },
  },

  data() {
    return {
      tab: null,
      tabItems: ['新規作成', '既存の利用目的から選択'],
      search: '',
      items: [] as Purpose[],
    };
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },
    item: {
      get(): SavePurposeRequest {
        return this.value.item;
      },
      set(item: SavePurposeRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },
    purposeSize() {
      return purposeSize;
    },
    filteredItems(): Purpose[] {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter((item: Purpose) => {
        return item.name.includes(this.search);
      });
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        const result = await this.$api<
          GetAllPurposesResponse,
          GetAllPurposesRequest
        >({
          path: '/purpose',
          method: 'get',
        });

        if (result) {
          this.items = result.purposes;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>
