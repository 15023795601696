<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>施設設定</h2>
        <div class="facility"></div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small outlined @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
          </custom-data-table>
          <facility-detail
            v-model="dialog"
            @save="save"
            @destroy="destroy"
          ></facility-detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  DeleteFacilityRequest,
  DeleteFacilityResponse,
  OrderFacilityResponse,
  OrderFacilityRequest,
} from '@api-i/routes/facility/facility';
import { Facility } from '@api/models';
import FacilityDetail, { initFacilityDetailItem } from './FacilityDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadFacilities } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'MastersFacilities',

  pageOptions: {
    routeConfig: {
      path: '/masters/facilities',
    },
    props: {
      title: 'マスタ設定 - 施設設定',
    },
  },

  components: {
    FacilityDetail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '頭文字', value: 'initials', cellClass: 'pointer' },
        { text: '地区名', value: 'area', cellClass: 'pointer' },
        { text: '住所', value: 'address', cellClass: 'pointer' },
        { text: 'TEL', value: 'tel', cellClass: 'pointer' },
      ],
      items: [] as Facility[],
    },

    dialog: {
      show: false,
      item: {},
      newMode: false,
    },
  }),

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      this.table.items = await loadFacilities(this);
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initFacilityDetailItem));
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: Facility) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/facility/save',
          method: 'post',
          params: this.dialog.item,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeleteFacilityResponse,
          DeleteFacilityRequest
        >({
          path: '/facility/delete',
          method: 'post',
          params: this.dialog.item as DeleteFacilityRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      try {
        const result = await this.$api<
          OrderFacilityResponse,
          OrderFacilityRequest
        >({
          path: '/facility/order',
          method: 'post',
          params: {
            items: this.table.items.map((item, order) => {
              item.order = order + 1;
              return item;
            }),
          },
        });

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
