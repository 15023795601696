<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>入力項目を追加</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-tabs v-model="tab" color="basil">
          <v-tab v-for="tabItem in tabItems" :key="tabItem">
            {{ tabItem }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-text class="pl-0 pr-0">
                <div class="masterDetail">
                  <DataPanelField label="名称">
                    <v-text-field
                      v-model="item.name"
                      placeholder="例： 希望サイト"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '名称は必須項目です。']"
                    ></v-text-field>
                  </DataPanelField>

                  <DataPanelField label="対象">
                    <v-select
                      v-model="item.property"
                      :items="filteredInputProperties"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '対象は必須項目です。']"
                    ></v-select>
                  </DataPanelField>

                  <DataPanelField label="入力タイプ">
                    <v-select
                      v-model="item.type"
                      :items="inputType.items"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '入力タイプは必須項目です。']"
                      @change="typeChanges"
                    ></v-select>
                  </DataPanelField>
                  <!-- 単一/複数選択 -->
                  <DataPanelField
                    v-if="
                      item.type === inputType.enumSingle.value ||
                      item.type === inputType.enumMultiple.value
                    "
                    label="選択肢"
                  >
                    <v-textarea
                      v-model="item.items"
                      placeholder="1行に1つの選択肢を入力"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      class="mt-0 pt-0"
                    />
                  </DataPanelField>

                  <!-- 数値 -->
                  <DataPanelField
                    v-if="item.type === inputType.number.value"
                    label="最大値"
                  >
                    <NumberInput
                      v-model="item.max"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    />
                  </DataPanelField>

                  <!-- チェックボックス -->
                  <DataPanelField
                    v-if="item.type === inputType.checkbox.value"
                    label="ラベル"
                  >
                    <v-text-field
                      v-model="item.label"
                      placeholder="例： 確認しました"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    ></v-text-field>
                  </DataPanelField>

                  <DataPanelField label="ヒント">
                    <v-text-field
                      v-model="item.hint"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    ></v-text-field>
                  </DataPanelField>

                  <DataPanelField label="入力サイト">
                    <BooleanInput
                      v-model="item.isPublic"
                      true-text="公開サイトと管理サイト"
                      false-text="管理サイトのみ"
                    />
                  </DataPanelField>

                  <DataPanelField label="アイコン">
                    <IconPicker v-model="item.icon" />
                  </DataPanelField>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="search"
                      flat
                      label="キーワードで絞り込む"
                      prepend-inner-icon="mdi-magnify"
                      solo-inverted
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="overflow-auto">
                    <field-list
                      :items="filteredItems"
                      @click="$listeners['select']"
                    ></field-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="!tab">
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="$emit('add')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  GetAllFieldsRequest,
  GetAllFieldsResponse,
  SaveFieldRequest,
} from '@api-i/routes/field/field';
import { Field } from '@api/models';
import { inputProperty, inputType } from '@api/constants';
import BooleanInput from '@web/components/inputs/boolean.vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import FieldList from '@web-i/components/FieldList.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import IconPicker from '@web-i/components/icon/IconPicker.vue';
import NumberInput from '@web/components/inputs/number-input.vue';

export type FieldAddDataType = {
  show: boolean;
  item: SaveFieldRequest;
};

export default Vue.extend({
  name: 'FieldAdd',

  components: {
    BooleanInput,
    DataPanelField,
    FieldList,
    IconPicker,
    NumberInput,
  },

  props: {
    value: {
      type: Object as PropType<FieldAddDataType>,
      default: undefined,
    },

    selected: {
      type: Array as PropType<Field[]>,
      default: undefined,
    },
  },

  data() {
    return {
      tab: null,
      tabItems: ['新規作成', '既存の入力項目から選択'],
      search: '',
      items: [] as Field[],
    };
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },
    item: {
      get(): SaveFieldRequest {
        return this.value.item;
      },
      set(item: SaveFieldRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },
    inputType() {
      return inputType;
    },
    filteredInputProperties() {
      return inputProperty.items.filter((item) => {
        return !this.selected.some((s) => s.property === item.value);
      });
    },
    filteredItems(): Field[] {
      const result = this.items.filter((item) => {
        return !this.selected.some(
          (s) => s.id === item.id || s.property === item.property,
        );
      });
      if (!this.search) {
        return result;
      }
      return result.filter((item: Field) => {
        return (
          item.name.includes(this.search) ||
          item.property.toLowerCase().includes(this.search.toLowerCase()) ||
          item.typeName.includes(this.search) ||
          item.items_.join().includes(this.search)
        );
      });
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        const result = await this.$api<
          GetAllFieldsResponse,
          GetAllFieldsRequest
        >({
          path: '/field',
          method: 'get',
        });

        if (result) {
          this.items = result.fields;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    typeChanges(type: string): void {
      if (
        type !== inputType.enumSingle.value &&
        type !== inputType.enumMultiple.value
      ) {
        this.item.items = '';
      }
    },
  },
});
</script>
