import { InferCreationAttributesDetail } from '@api/models/Detail';
import { Invoice, InvoiceDetail, Unit } from '@api/models';
import { dateUtility } from '@c/util';
import createDocument from 'docx-templates';
import { chain, partial, sumBy, values } from 'lodash';
import Vue from 'vue';
import { scopeDistribution } from '@api/constants';
import { calcInvoiceDetails } from './fee-calculator';

/**
 * base64をArrayBufferに変換する
 */
export function base64ToArrayBuffer(base64: string) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * ドキュメントを生成する
 */
export async function generateDocument({
  data,
  template,
  additionalJsContext = {},
}: {
  data: any;
  template: any;
  additionalJsContext?: Record<string, unknown>;
}) {
  const doc = await createDocument({
    data,
    template,
    additionalJsContext,
    cmdDelimiter: ['{', '}'],
  });

  return doc;
}

/**
 * ファイル名を取得する
 */
export function getFileName(
  instance: Vue,
  reportName: string,
  extension = 'docx',
) {
  const current = instance.$dateFns.fnsFormat(
    new Date(),
    '',
    'yyyy-MM-dd-HH-mm-ss',
  );
  const fileName = `${reportName}_${current}.${extension}`;

  return fileName;
}

/**
 * ファイルをダウンロードさせる
 */
export async function downloadDocument({
  data,
  fileName,
  contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}: {
  data: any;
  fileName: string;
  contentType?: string;
}) {
  const blob = new Blob([data], { type: contentType });

  // IE,Edgeのダウンロード
  const navigator = window.navigator as any;
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, fileName);
  }
  // IE,Edge以外のダウンロード
  else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

/**
 * 帳票に必要なデータを注入する
 */
export function distributeDocumentContext(
  instance: Vue,
  data: InferCreationAttributesDetail,
  unit: Unit,
  holidays: string[],
) {
  const report = {
    currentDate: '',
    createdDate: '',
    startDate: '',
    startDateJP: '',
    startHour: '',
    endDate: '',
    endDateJP: '',
    endHour: '',
    nextEndDate: '',
    nextEndDateJP: '',
    invoice: {
      details: [] as Partial<InvoiceDetail>[],
      all: {
        include: 0,
      },
    },
  };

  const DATE_FORMAT = 'yyyy年M月d日';
  const DATE_FORMAT_JP = 'yyyy年M月d日(E)';
  // 帳票の作成日（現在日付）
  report.currentDate = instance.$dateFns.fnsFormat(new Date(), '', DATE_FORMAT);

  const { book, startDate, startTime, endDate, endTime, stayDays } = data;
  if (book) {
    // 予約の作成日（申請日）
    report.createdDate = instance.$dateFns.fnsFormat(
      book.createdAt,
      '',
      DATE_FORMAT,
    );
  }
  if (startDate) {
    // 利用日（開始）
    report.startDate = instance.$dateFns.fnsFormat(startDate, '', DATE_FORMAT);
    report.startDateJP = instance.$dateFns.fnsFormat(
      startDate,
      '',
      DATE_FORMAT_JP,
    );

    // 利用日（終了）
    let end = endDate;
    if (!end) {
      end = dateUtility.calcEndDate(startDate, endDate, stayDays);
    }
    report.endDate = instance.$dateFns.fnsFormat(end, '', DATE_FORMAT);
    report.endDateJP = instance.$dateFns.fnsFormat(end, '', DATE_FORMAT_JP);

    // 利用日（終了）の翌日
    const nextEnd = new Date(end);
    nextEnd.setDate(nextEnd.getDate() + 1);
    report.nextEndDate = instance.$dateFns.fnsFormat(nextEnd, '', DATE_FORMAT);
    report.nextEndDateJP = instance.$dateFns.fnsFormat(
      nextEnd,
      '',
      DATE_FORMAT_JP,
    );
  }
  if (startTime && dateUtility.isTimeFormat(startTime)) {
    // 開始時間
    report.startHour = Number(startTime.slice(0, 2)) + '時';
  }
  if (endTime && dateUtility.isTimeFormat(endTime)) {
    // 終了時間
    report.endHour = Number(endTime.slice(0, 2)) + '時';
  }
  if (unit && unit.fees) {
    // 料金
    report.invoice.details = calcInvoiceDetails(
      instance,
      data,
      unit.fees,
      holidays,
    );

    report.invoice.all.include = report.invoice.details.reduce((a, c) => {
      return a + (c.amount ?? 0);
    }, 0);
  }

  const { system } = scopeDistribution.exec(data);

  return {
    ...data,
    report,
    system,
  };
}

/**
 * 請求書に必要なデータを注入する
 */
export function distributeInvoiceContext(
  instance: Vue,
  data: Partial<Invoice>,
) {
  const report = {
    currentDate: '',
  };

  const DATE_FORMAT = 'yyyy年M月d日';
  // 帳票の作成日（現在日付）
  report.currentDate = instance.$dateFns.fnsFormat(new Date(), '', DATE_FORMAT);

  // 請求書に必要な数値を計算する
  const invoice = calcInvoice(instance, data.details);

  // 数値をカンマ区切りにする
  const details = chain(data.details)
    .map((detail) => ({
      ...detail,
      unitPrice: detail.unitPrice.toLocaleString(),
      numOfPieces: detail.numOfPieces.toLocaleString(),
      amount: detail.amount.toLocaleString(),
    }))
    .value();

  return {
    ...data,
    details,
    report,
    invoice,
  };
}

function calcInvoice(instance: Vue, details?: InvoiceDetail[]) {
  const other = sumByTaxRate(instance, details);
  const all = sumAll(other);
  const result = chain({ all, ...other })
    .mapValues((d) => {
      const result_ = chain(d)
        .mapValues((d_) => (Number(d_) || 0).toLocaleString())
        .value();

      return result_;
    })
    .value();

  return result;
}

/**
 * 税率ごとに集計する
 */
function sumByTaxRate(instance: Vue, details?: InvoiceDetail[]) {
  const result = chain(details)
    .groupBy('taxRate')
    .mapValues((items) => {
      const result_ = chain(items)
        .map((detail) => {
          const include = detail.amount;
          const expr = `${detail.roundingType}(amount * taxRate / (100 + taxRate))`;
          const tax = instance.$mathjs.evaluate(expr, detail);
          const exclude = include - tax;

          return { include, exclude, tax };
        })
        .value();

      const result__ = chain(['include', 'exclude', 'tax'])
        .keyBy()
        .mapValues(partial(sumBy, result_))
        .value();

      return result__;
    })
    .value();

  return result;
}

/**
 * 全ての金額を集計する
 */
function sumAll(other: {
  [x: string]: {
    [x: string]: number;
  };
}) {
  const result = chain(['include', 'exclude', 'tax'])
    .keyBy()
    .mapValues(partial(sumBy, values(other)))
    .value();

  return result;
}
