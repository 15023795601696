<template>
  <v-app>
    <template v-if="isLoggedIn">
      <LoadingOverlay />
      <MultiSnackbar
        :objects.sync="$store.state.snackbar.messages"
        transition="scroll-y-transition"
      />
      <v-navigation-drawer v-model="drawer" app clipped class="nav">
        <v-list dense>
          <template v-for="(item, itemIndex) in menuItems">
            <v-divider
              v-if="!item.items && !item.header"
              :key="`${itemIndex}-${item}`"
            ></v-divider>

            <template v-else>
              <v-subheader
                v-if="item.header && item.header.label"
                :key="`${itemIndex}-${item}-header`"
                >{{ item.header.label }}</v-subheader
              >

              <v-list-item
                v-for="(i, iIndex) in item.items"
                :key="`${itemIndex}-${item}-items-${i}-${iIndex}`"
                :link="!!i.link"
                :to="i.link"
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ i.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ i.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app dense clipped-left class="header">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>オクシズ予約管理システム</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu left bottom offset-y>
          <template #activator="{ on, attrs }">
            <v-btn text v-bind="attrs" color="white" v-on="on">
              <v-icon>mdi-account</v-icon>
              <div class="userName">{{ username }}</div>
            </v-btn>
          </template>

          <v-list class="userMenu">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ username }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="isAdmin">
                  管理者
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!!facilities" class="targetFacility">
              <v-list-item-content>
                <v-list-item-title>対象施設</v-list-item-title>
                <v-list-item-subtitle>
                  <div v-for="facility in facilities" :key="facility.id">
                    <v-chip outlined small color="primary" class="ma-1">{{
                      facility.name
                    }}</v-chip>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="buttonOut" @click="logout">
              <v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                ログアウト
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main>
        <v-container>
          <slot />
        </v-container>
      </v-main>
    </template>
    <template v-else-if="isHome">
      <v-main>
        <v-container fluid fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" xl="4">
              <LoginComponent />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </template>
    <template v-else>
      <v-main>
        <v-container fluid fill-height>
          <v-row justify="center" align="center">
            <LoadingInner />
          </v-row>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import LoginComponent from '@web-i/components/Login.vue';
import LoadingOverlay from '@web/components/LoadingOverlay.vue';
import LoadingInner from '@web/components/LoadingInner.vue';
import MultiSnackbar from '@web/components/MultiSnackbar.vue';
import { mapActions, mapGetters } from '@web-i/store/login';

export default Vue.extend({
  name: 'InternalLayout',

  components: {
    LoginComponent,
    LoadingOverlay,
    LoadingInner,
    MultiSnackbar,
  },

  data: () => ({
    drawer: null,

    menuItems: [
      {
        header: {
          label: '',
        },
        items: [
          {
            label: 'ダッシュボード',
            link: '/',
            icon: 'mdi-home-variant-outline',
          },
        ],
      },
      {},
      {
        header: {
          label: '予約',
        },
        items: [
          {
            label: '予約一覧',
            link: '/books',
            icon: 'mdi-calendar-blank-outline',
          },
          {
            label: '空き状況照会/予約登録',
            link: '/free',
            icon: 'mdi-calendar-search-outline',
          },
          {
            label: '統計情報',
            link: '/stats',
            icon: 'mdi-chart-box-outline',
          },
        ],
      },
      {},
      {
        header: {
          label: '請求',
        },
        items: [
          {
            label: '対象予約一覧',
            link: '/billable',
            icon: 'mdi-calendar-blank-outline',
          },
          {
            label: '請求一覧',
            link: '/invoices',
            icon: 'mdi-file-document-outline',
          },
        ],
      },
      {},
      {
        header: {
          label: '管理',
        },
        items: [
          {
            label: 'ユーザー設定',
            link: '/users',
            icon: 'mdi-account',
          },
          {
            label: '利用者お知らせ設定',
            link: '/noticeForUser',
            icon: 'mdi-bell-outline',
          },
          {
            label: '施設管理者お知らせ設定',
            link: '/noticeForManager',
            icon: 'mdi-bell-outline',
          },
          {
            label: 'マスタ設定',
            link: '/masters',
            icon: 'mdi-cog',
          },
        ],
      },
    ],
  }),

  computed: {
    isHome(): boolean {
      return this.$route.path === '/';
    },
    ...mapGetters(['isLoggedIn', 'username', 'isAdmin', 'facilities']),
  },

  methods: {
    async logout() {
      await this.$cognito.logout();
      await this.setLoggedInUser(null);
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
        });
      }
    },

    ...mapActions(['setLoggedInUser']),
  },
});
</script>
