<template>
  <v-dialog v-model="show" scrollable width="1440" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-text class="text--primary">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" md="8" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <v-card-title>{{
                  isNewMode ? '料金を追加' : '詳細'
                }}</v-card-title>
                <div class="masterDetail pt-6">
                  <DataPanelField label="名称">
                    <v-text-field
                      v-model="item.name"
                      placeholder="例： 入場料"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '名称は必須項目です。']"
                    ></v-text-field>
                  </DataPanelField>
                  <DataPanelField label="単価">
                    <NumberInput
                      v-model="item.unitPrice"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    />
                  </DataPanelField>
                  <DataPanelField label="数量">
                    <v-select
                      v-model="item.numOfPieces"
                      :items="fields"
                      item-text="name"
                      item-value="property"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    ></v-select>
                  </DataPanelField>
                  <DataPanelField label="計算式">
                    <v-text-field
                      ref="formulaTextField"
                      v-model="item.formula"
                      placeholder="例： 1000 * numOfPeople"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '計算式は必須項目です。']"
                    ></v-text-field>
                  </DataPanelField>
                  <DataPanelField label="税率">
                    <NumberInput
                      v-model="item.taxRate"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '税率は必須項目です。']"
                    />
                  </DataPanelField>
                  <DataPanelField label="税区分">
                    <v-select
                      v-model="item.taxType"
                      :items="taxType.items"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '税区分は必須項目です。']"
                    ></v-select>
                  </DataPanelField>
                  <DataPanelField label="端数処理区分">
                    <v-select
                      v-model="item.roundingType"
                      :items="roundingType.items"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      :rules="[(v) => !!v || '端数処理区分は必須項目です。']"
                    ></v-select>
                  </DataPanelField>

                  <SchemaEdit v-model="item.schema_" :fields="fields" />
                </div>
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn depressed color="primary" small @click="$emit('save')">
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <FieldList
                  :items="fields"
                  class="overflow-auto"
                  @click="selectField"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveFeeRequest } from '@api-i/routes/fee/fee';
import { Field } from '@api/models';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import FieldList from '@web-i/components/FieldList.vue';
import SchemaEdit from '@web-i/components/schema/SchemaEdit.vue';
import { roundingType, taxType } from '@api/constants';
import NumberInput from '@web/components/inputs/number-input.vue';

export type FeeDetailDataType = {
  show: boolean;
  item: SaveFeeRequest;
  newMode: boolean;
};

export const initFeeDetailItem = {
  name: '',
  schema_: [],
  formula: '',
  unitId: '',
};

export default Vue.extend({
  name: 'FeeDetail',

  components: { DataPanelField, FieldList, SchemaEdit, NumberInput },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<FeeDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveFeeRequest {
        return this.value.item;
      },
      set(item: SaveFeeRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    taxType() {
      return taxType;
    },

    roundingType() {
      return roundingType;
    },
  },

  methods: {
    selectField(item: Field) {
      const element: HTMLInputElement = (this.$refs.formulaTextField as any)
        ?.$refs?.input;
      const cursorPosition = element?.selectionStart ?? 0;
      const value = this.item.formula ?? '';

      if (cursorPosition > value.length) {
        return;
      }

      const value_ = [
        value.slice(0, cursorPosition),
        item.property,
        value.slice(cursorPosition),
      ].join('');
      this.item = { ...this.item, formula: value_ };

      const nextPosition = cursorPosition + item.property.length;
      element.focus();
      element.setSelectionRange(nextPosition, nextPosition);
    },
  },
});
</script>
