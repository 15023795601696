import {
  ConstantDocumentTarget,
  ConstantDocumentTargetDict,
} from '@api-t/constants/documentTarget';

const documentTargetDict: ConstantDocumentTargetDict = {
  book: {
    text: '予約データ',
    value: 'book',
  },
  invoice: {
    text: '請求データ',
    value: 'invoice',
  },
};

export const documentTarget: ConstantDocumentTarget = {
  ...documentTargetDict,
  items: [documentTargetDict.book, documentTargetDict.invoice],
  get(value: string): string {
    switch (value) {
      case documentTargetDict.book.value:
        return documentTargetDict.book.text;
      case documentTargetDict.invoice.value:
        return documentTargetDict.invoice.text;
      default:
        return '';
    }
  },
};
