<template>
  <v-dialog v-model="menu" :width="300">
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="displayValue"
        outlined
        :clearable="clearable"
        dense
        readonly
        hide-details="auto"
        :label="label"
        v-bind="{ ...attrs, rules, ...textFieldProps }"
        v-on="on"
        @click:clear="clear"
      ></v-text-field>
    </template>
    <v-card class="calendar timePicker">
      <v-card-text class="px-0 py-0 text--primary">
        <v-tabs v-model="tab" grow>
          <v-tab key="date-select"> 日付 </v-tab>
          <v-tab key="time-select"> 時間 </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="date-select">
            <DateSelector
              v-model="dateValue"
              v-bind="dateSelectorAttrs"
              @updated="tab = 1"
            />
          </v-tab-item>
          <v-tab-item key="time-select">
            <TimeSelector v-model="timeValue" v-bind="timeSelectorAttrs" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-4 py-4">
        <v-spacer></v-spacer>
        <v-btn x-small class="gray" @click="menu = false"> キャンセル </v-btn>
        <v-btn x-small @click="select"> 指定する </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DateSelector, { props as dateSelectorProps } from './date-selector.vue';
import TimeSelector, { props as timeSelectorProps } from './time-selector.vue';

export default Vue.extend({
  name: 'DatetimePicker',

  components: {
    DateSelector,
    TimeSelector,
  },

  props: {
    value: {
      type: [Date, String] as PropType<string | Date>,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array as PropType<
        (boolean | string | ((v: any) => boolean | string))[]
      >,
      default: () => [],
    },

    textFieldProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },

    ...dateSelectorProps,
    ...timeSelectorProps,
  },

  data: () => ({
    menu: false,
    editValue: null as Date | null,
    tab: null as number | null,
  }),

  computed: {
    dateValue: {
      get(): string {
        return this.dateObjToDateString(this.editValue);
      },
      set(newValue: string) {
        const [yearStr, monthStr, dayStr] = newValue.split('-');
        let result = new Date(this.editValue || new Date());
        result.setFullYear(Number(yearStr));
        result.setMonth(Number(monthStr) - 1);
        result.setDate(Number(dayStr));
        this.editValue = result;
      },
    },
    timeValue: {
      get(): string {
        return this.dateObjToTimeString(this.editValue);
      },
      set(newValue: string) {
        const [hourStr, minuteStr] = newValue.split(':');
        let result = new Date(this.editValue || new Date());
        result.setHours(Number(hourStr));
        result.setMinutes(Number(minuteStr));
        this.editValue = result;
      },
    },

    displayValue(): string {
      return (
        (this.value && this.$dateFns.fnsFormatDatetime(this.value, '')) || ''
      );
    },

    dateSelectorAttrs(): Record<string, any> {
      return {
        ...Object.keys(dateSelectorProps).reduce((prev, nextKey) => {
          const nextValue = (this as any)[nextKey];
          if (nextValue !== undefined) {
            return {
              ...prev,
              [nextKey]: nextValue,
            };
          }
          return prev;
        }, {}),
        fullWidth: true,
      };
    },
    timeSelectorAttrs(): Record<string, any> {
      return Object.keys(timeSelectorProps).reduce((prev, nextKey) => {
        const nextValue = (this as any)[nextKey];
        if (nextValue !== undefined) {
          return {
            ...prev,
            [nextKey]: nextValue,
          };
        }
        return prev;
      }, {});
    },
  },

  watch: {
    menu(to, from) {
      if (to && !from) {
        // メニューを開いたら値をコピー
        this.editValue = (this.value && new Date(this.value)) || null;
        // タブをもどす
        this.tab = 0;
      }
    },
  },

  methods: {
    // 日時オブジェクトから日付のみを文字列で取り出し
    dateObjToDateString(d: Date | null | undefined): string {
      if (!d) return '';
      const year = String(d.getFullYear()).padStart(4, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    // 日時オブジェクトから時間のみを文字列で取り出し
    dateObjToTimeString(d: Date | null | undefined): string {
      if (!d) return '';
      const hour = String(d.getHours()).padStart(2, '0');
      const minute = String(d.getMinutes()).padStart(2, '0');
      return `${hour}:${minute}`;
    },

    select() {
      this.menu = false;
      this.$emit('input', this.editValue);
    },

    clear() {
      this.$emit('input', null);
      this.$emit('clear');
    },
  },
});
</script>
