<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? '利用目的を追加' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-alert type="warning" icon="mdi-alert" dense outlined
          >特定の管理者のみ利用目的を編集できます。</v-alert
        >
        <div class="masterDetail">
          <v-row>
            <v-col cols="12">
              <data-panel-field label="名称">
                <v-text-field
                  v-model="item.name"
                  placeholder="例： キャンプ"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[(v) => !!v || '名称は必須項目です。']"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="表示サイズ">
                <v-select
                  v-model="item.size"
                  :items="purposeSize.items"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[(v) => !!v || 'サイズは必須項目です。']"
                ></v-select>
              </data-panel-field>
              <data-panel-field label="背景画像名">
                <v-text-field
                  v-model="item.imageName"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </data-panel-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isNewMode"
          depressed
          small
          class="red"
          @click="$emit('destroy')"
        >
          <v-icon>mdi-delete-outline</v-icon>
          <span>区画種類から削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!isAdmin"
          @click="$emit('save')"
        >
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { SavePurposeRequest } from '@api-i/routes/purpose/purpose';
import { purposeSize } from '@api/constants';

export type PurposeDetailDataType = {
  show: boolean;
  item: SavePurposeRequest;
  newMode: boolean;
};

export const initPurposeDetailItem: Partial<SavePurposeRequest> = {
  name: '',
  size: '',
  imageName: '',
};

export default Vue.extend({
  name: 'PurposeDetail',

  components: {
    DataPanelField,
  },

  props: {
    value: {
      type: Object as PropType<PurposeDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SavePurposeRequest {
        return this.value.item;
      },
      set(item: SavePurposeRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    purposeSize() {
      return purposeSize;
    },

    isAdmin(): boolean {
      return this.$store.getters['login/isAdmin'];
    },
  },
});
</script>
