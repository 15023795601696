<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>利用目的</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn
            depressed
            small
            color="primary"
            :disabled="!selectedUnitId"
            @click="dialog.show = true"
          >
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-container class="">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="selectedUnitId"
                      :items="units"
                      outlined
                      dense
                      hide-details="auto"
                      label="区画種類"
                      clearable
                      item-text="name"
                      item-value="id"
                      @change="load"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            @click:row="handleClickRow"
          >
          </v-data-table>
          <purpose-add
            v-model="dialog"
            @add="add"
            @select="select"
          ></purpose-add>
          <purpose-detail
            v-model="detailDialog"
            @save="save"
            @destroy="remove"
          ></purpose-detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  GetAllPurposesRequest,
  GetAllPurposesResponse,
  SavePurposeRequest,
  UpdatePurposeRequest,
  UpdatePurposeResponse,
} from '@api-i/routes/purpose/purpose';
import {
  InsertUnitPurposeRequest,
  InsertUnitPurposeResponse,
  DeleteUnitPurposeRequest,
  DeleteUnitPurposeResponse,
} from '@api-i/routes/unitPurpose/unitPurpose';
import { Purpose, Unit } from '@api/models';
import PurposeAdd from './PurposeAdd.vue';
import PurposeDetail, { initPurposeDetailItem } from './PurposeDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { mapActions } from '@web/store/snackbar';
import { loadUnits } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'MastersPurposes',

  pageOptions: {
    routeConfig: {
      path: '/masters/purpose',
    },
    props: {
      title: 'マスタ設定 - 利用目的',
    },
  },

  components: {
    PurposeAdd,
    PurposeDetail,
  },

  data: () => ({
    facilityName: '',
    selectedUnitId: '',
    units: [] as Unit[],
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '表示サイズ', value: 'size', cellClass: 'pointer' },
        { text: '背景画像名', value: 'imageName', cellClass: 'pointer' },
      ],
      items: [] as Purpose[],
    },
    dialog: {
      show: false,
      item: {} as Partial<SavePurposeRequest>,
    },
    detailDialog: {
      show: false,
      item: {} as Partial<UpdatePurposeRequest>,
      newMode: false,
    },
  }),

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },
  },

  async mounted() {
    await this.loadUnits();
  },

  methods: {
    async loadUnits() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      const units = await loadUnits(this, {
        facilityId: facilityId,
      });
      if (units) {
        // 備品は利用目的を設定しない
        this.units = units.filter((u) => !u.isOption);
      }
    },

    async load() {
      if (!this.selectedUnitId) {
        this.table.items = [];
        return;
      }
      try {
        const result = await this.$api<
          GetAllPurposesResponse,
          GetAllPurposesRequest
        >({
          path: '/purpose',
          method: 'get',
          params: {
            scope: 'units',
            unitId: this.selectedUnitId,
          },
        });

        this.table.items = result.purposes;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }
    },

    async add() {
      if (!this.selectedUnitId) {
        return;
      }
      if (
        this.table.items?.some((item) => item.name === this.dialog.item.name)
      ) {
        alert('既存の利用目的と同じ内容です。');
        return;
      }
      try {
        const result = await this.$api({
          path: '/purpose/add',
          method: 'post',
          params: {
            unitId: this.selectedUnitId,
            ...this.dialog.item,
          },
        });
        this.dialog.show = false;

        if (result) {
          this.dialog.item = structuredClone(initPurposeDetailItem);
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async select(id: string) {
      if (!this.selectedUnitId) {
        return;
      }
      if (this.table.items?.some((item) => item.id === id)) {
        alert('この利用目的は既に追加されています。');
        return;
      }
      try {
        const result = await this.$api<
          InsertUnitPurposeResponse,
          InsertUnitPurposeRequest
        >({
          path: '/unitPurpose/create',
          method: 'post',
          params: {
            unitId: this.selectedUnitId,
            purposeId: id,
          },
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    handleClickRow(item: Purpose) {
      this.$set(this.detailDialog, 'show', true);
      this.$set(this.detailDialog, 'item', structuredClone(item));
      this.$set(this.detailDialog, 'newMode', false);
    },

    // 保存※管理者のみ
    async save() {
      if (!this.selectedUnitId) {
        return;
      }
      try {
        const result = await this.$api<
          UpdatePurposeResponse,
          UpdatePurposeRequest
        >({
          path: '/purpose/update',
          method: 'post',
          params: this.detailDialog.item as UpdatePurposeRequest,
        });
        this.detailDialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async remove() {
      if (!this.selectedUnitId) {
        return;
      }
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeleteUnitPurposeResponse,
          DeleteUnitPurposeRequest
        >({
          path: '/unitPurpose/delete',
          method: 'post',
          params: {
            unitId: this.selectedUnitId!,
            purposeId: this.detailDialog.item.id!,
          },
        });
        this.detailDialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>

<style lang="scss" scoped></style>
