<template>
  <v-dialog v-model="show" scrollable width="1000" class="dialogBox">
    <v-card class="dialogBox -user">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <v-row>
            <v-col cols="12" md="6">
              <DataPanelFieldWrap
                v-model="item.name"
                label="名前"
              ></DataPanelFieldWrap>
              <DataPanelFieldWrap
                v-model="item.username"
                label="ID"
                :readonly="!isNewMode"
                readonly-text="ユーザーIDは一度作成すると編集できません"
              ></DataPanelFieldWrap>
              <DataPanelFieldWrap
                v-model="item.staffCode"
                label="職員コード"
              ></DataPanelFieldWrap>
              <data-panel-field label="有効/無効">
                <v-edit-dialog
                  :return-value.sync="item.isValid"
                  large
                  cancel-text="キャンセル"
                  save-text="適用"
                >
                  <span>
                    {{ item.isValid ? '有効' : '無効' }}
                  </span>
                  <v-icon small class="ml-2">mdi-pencil</v-icon>
                  <template #input>
                    <v-checkbox
                      v-model="item.isValid"
                      hide-details
                      :label="`${item.isValid ? '有効' : '無効'}`"
                    ></v-checkbox>
                  </template>
                </v-edit-dialog>
              </data-panel-field>
              <data-panel-field label="所属グループ">
                <v-edit-dialog
                  :return-value.sync="item.roleId"
                  large
                  cancel-text="キャンセル"
                  save-text="適用"
                >
                  <span>
                    {{ getRoleName(item.roleId) }}
                  </span>
                  <v-icon small class="ml-2">mdi-pencil</v-icon>
                  <template #input>
                    <div class="mt-5">
                      <v-autocomplete
                        v-model="item.roleId"
                        :items="roleItems"
                        label="権限グループを選択"
                        outlined
                        hide-details
                        clearable
                        dense
                      >
                      </v-autocomplete>
                    </div>
                  </template>
                </v-edit-dialog>
              </data-panel-field>
            </v-col>
            <v-col cols="12" md="6">
              <DataPanelFieldWrap
                v-model="item.department"
                label="所属名"
              ></DataPanelFieldWrap>
              <DataPanelFieldWrap
                v-model="item.position"
                label="役職名"
              ></DataPanelFieldWrap>
              <DataPanelFieldWrap
                v-model="item.email"
                label="メールアドレス"
              ></DataPanelFieldWrap>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <Confirm
          v-if="!isNewMode"
          message="削除します。よろしいですか?"
          @yes="$emit('destroy')"
        >
          <template #default="{ on }">
            <v-btn depressed small color="red" v-on="on">
              <v-icon>mdi-delete-outline</v-icon>
              <span>ユーザー削除</span>
            </v-btn>
          </template>
        </Confirm>
        <Confirm
          v-if="!isNewMode"
          message="このユーザをすべての端末からサインアウトさせます。よろしいですか？"
          @yes="$emit('sign-out')"
        >
          <template #default="{ on }">
            <v-btn depressed small color="gray" v-on="on">サインアウト</v-btn>
          </template>
        </Confirm>
        <v-btn
          v-if="!isNewMode"
          depressed
          small
          color="gray"
          @click="passwordDialog = true"
        >
          パスワード変更
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" small @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="passwordDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">パスワード変更</span>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="password.value1"
                outlined
                dense
                label="パスワード"
                type="password"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password.value2"
                outlined
                dense
                label="パスワード(再入力)"
                type="password"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="justify-center">
          <slot name="action">
            <v-btn color="gray" @click="passwordDialog = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" depressed @click="handleClickChangePassword">
              変更
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Confirm from '@web/components/Confirm.vue';
import DataPanelFieldWrap from '@web-i/components/DataPanelFieldWrap.vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { InsertUserRequestUser } from '@api-i/routes/user/user';
import { VSelectItem } from '@web-t/global/vuetify-custom';
import { Role } from '@api/models';
import { mapActions } from '@web/store/snackbar';

export type UserDetailDataType = {
  show: boolean;
  item: InsertUserRequestUser;
  newMode: boolean;
  passwordDialog: boolean;
  roles: Role[];
};

export default Vue.extend({
  name: 'UserDetail',

  components: {
    Confirm,
    DataPanelField,
    DataPanelFieldWrap,
  },

  props: {
    value: {
      type: Object as PropType<UserDetailDataType>,
      default: undefined,
    },
  },

  data: () => ({
    password: {
      value1: '',
      value2: '',
    },
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },
    item: {
      get(): InsertUserRequestUser {
        return this.value.item;
      },
      set(item: InsertUserRequestUser): void {
        this.$emit('input', { ...this.value, item });
      },
    },
    isNewMode(): boolean {
      return this.value?.newMode;
    },
    passwordDialog: {
      get(): boolean {
        return this.value.passwordDialog;
      },
      set(newValue: boolean) {
        this.$emit('input', {
          ...this.value,
          passwordDialog: newValue,
        });
        if (!newValue) {
          this.password.value1 = '';
          this.password.value2 = '';
        }
      },
    },
    roles(): Role[] {
      return this.value.roles;
    },
    roleItems(): VSelectItem[] {
      return this.roles.map((r) => ({
        text: r.name,
        value: r.id!,
      }));
    },
  },

  methods: {
    handleClickChangePassword() {
      const { value1, value2 } = this.password;
      if (!value1 || value1.length === 0 || !value2 || value2.length === 0) {
        return this.snackbarRegister({
          type: 'error',
          message: 'パスワードを入力してください。',
        });
      }
      if (value1 !== value2) {
        return this.snackbarRegister({
          type: 'error',
          message: '入力されたパスワードが一致しません。',
        });
      }
      if (value1.length < 8) {
        return this.snackbarRegister({
          type: 'error',
          message: 'パスワードは8文字以上にしてください。',
        });
      }
      this.$emit('change-password', this.password.value1);
    },

    getRoleName(roleId: string): string {
      return this.roles.find((r) => r.id === roleId)?.name || '';
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
