<template>
  <v-dialog v-model="show" scrollable width="1440" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? '予約登録' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-tabs v-model="tabIdx" color="basil">
          <!-- 概要 -->
          <v-tab>
            <div class="my-n1 py-1">
              <span class="font-weight-bold ma-2">概要</span>
            </div>
          </v-tab>
          <!-- 区画種類 -->
          <v-tab v-for="(detail, i) in item.details" :key="i">
            <div class="my-n1 py-1">
              <span class="font-weight-bold ma-2">
                {{ unitDict[detail.unitId].name }}
              </span>
            </div>
            <div class="ml-auto">
              <v-btn icon @click="deleteDetail(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-tab>
          <!-- 備品 -->
          <v-tab v-for="(option, i) in options" :key="option.text">
            <div class="my-n1 py-1">
              <span class="font-weight-bold ma-2">
                {{ option.text }}
              </span>
            </div>
            <div class="ml-auto">
              <v-btn icon @click="deleteOption(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabIdx">
          <!-- 概要 -->
          <v-tab-item>
            <v-card>
              <div class="v-card__text text--primary px-7">
                <template v-if="!isNewMode">
                  <v-row>
                    <!-- 受付番号 -->
                    <v-col cols="12" md="6">
                      <data-panel-field label="受付番号">
                        {{ item.receiptNo }}
                      </data-panel-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- 最終更新者 -->
                    <v-col cols="12" md="6">
                      <data-panel-field label="最終更新者">
                        {{ item.updatedBy?.name }}
                      </data-panel-field>
                    </v-col>
                    <!-- 最終更新日 -->
                    <v-col cols="12" md="6">
                      <data-panel-field label="最終更新日">
                        {{ $dateFns.fnsFormatDatetime(item.updatedAt) }}
                      </data-panel-field>
                    </v-col>
                  </v-row>
                </template>
                <v-row>
                  <!-- 氏名 -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="氏名">
                      <v-text-field
                        v-model="item.name"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="予約太郎"
                        :rules="rules.name"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- かな -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="かな">
                      <v-text-field
                        v-model="item.kana"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="よやくたろう"
                        :rules="rules.kana"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- 郵便番号 -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="郵便番号">
                      <v-text-field
                        v-model="item.zipCode"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="422-8033"
                        :rules="rules.zipCode"
                        @input="onInput"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- 住所 -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="住所">
                      <v-text-field
                        v-model="item.address"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="静岡県静岡市駿河区登呂3-1-1"
                        :rules="rules.address"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- 電話番号 -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="電話番号">
                      <v-text-field
                        v-model="item.tel"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="054-123-4567"
                        :rules="rules.tel"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- E-mail -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="E-mail">
                      <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        placeholder="example@mail.com"
                        :rules="rules.email"
                      ></v-text-field>
                    </data-panel-field>
                  </v-col>
                  <!-- 施設 -->
                  <v-col cols="12" md="6">
                    <data-panel-field label="施設">
                      <v-autocomplete
                        v-model="item.facilityId"
                        :items="facilities"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                        item-text="name"
                        item-value="id"
                        placeholder="未選択"
                        :rules="rules.facilityId"
                        :disabled="!isNewMode"
                        @change="onChangeFacility"
                      ></v-autocomplete>
                    </data-panel-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="d-flex mt-5 mb-2">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          depressed
                          small
                          dark
                          :disabled="!selectableUnits.length"
                          v-bind="attrs"
                          v-on="on"
                        >
                          予約する区画種類を選択
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="unit in selectableUnits"
                          :key="unit.id"
                          @click="selectUnit(unit)"
                        >
                          <v-list-item-title>{{ unit.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-tab-item>

          <!-- 区画種類 -->
          <v-tab-item v-for="(detail, i) in item.details" :key="i">
            <v-card>
              <div class="v-card__text text--primary px-7">
                <v-row>
                  <v-col
                    v-for="field in unitDict[detail.unitId].fields"
                    :key="field.id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <data-panel-field :label="field.name">
                      <variable-input-field
                        v-model="detail[field.property]"
                        :field="field"
                        :unit-step="unitDict[detail.unitId].step"
                        :unit-items="unitDict[detail.unitId].items_"
                      ></variable-input-field>
                    </data-panel-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="d-flex mt-5 mb-2">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          depressed
                          small
                          dark
                          :disabled="!selectableOptions.length"
                          v-bind="attrs"
                          v-on="on"
                        >
                          追加するオプションを選択
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(option, j) in selectableOptions"
                          :key="j"
                          @click="selectOption(option.value)"
                        >
                          <v-list-item-title>
                            {{ option.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-tab-item>

          <!-- 備品 -->
          <v-tab-item v-for="option in options" :key="option.text">
            <v-card>
              <div class="v-card__text text--primary px-7">
                <v-row
                  v-for="(details, group) in option.value"
                  :key="group"
                  cols="12"
                >
                  <v-col cols="12" class="d-flex mt-5 mb-2 data-panel-section">
                    <div class="my-n1 py-1">
                      <span class="font-weight-bold ma-2">{{
                        group || 'その他'
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    v-for="(detail, i) in details"
                    :key="i"
                    cols="12"
                    :lg="min(12, 3 * unitDict[detail.unitId].fields.length)"
                  >
                    <v-row>
                      <v-col cols="12">
                        {{ unitDict[detail.unitId].name }}
                      </v-col>
                      <v-col
                        v-for="field in unitDict[detail.unitId].fields"
                        :key="field.id"
                        cols="12"
                        md="6"
                        :lg="max(3, 12 / unitDict[detail.unitId].fields.length)"
                      >
                        <data-panel-field :label="field.name">
                          <variable-input-field
                            v-model="detail[field.property]"
                            :field="field"
                            :unit-step="unitDict[detail.unitId].step"
                            :unit-items="unitDict[detail.unitId].items_"
                          ></variable-input-field>
                        </data-panel-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <!-- 登録モード -->
        <template v-if="isNewMode">
          <v-btn depressed small class="gray" @click="tempSave">
            <v-icon class="">mdi-file-check-outline</v-icon>
            仮保存
          </v-btn>
        </template>
        <!-- 参照モード -->
        <template v-else>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn depressed small class="gray" v-bind="attrs" v-on="on">
                <v-icon>mdi-email-outline</v-icon>
                メール送信
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$emit('send', 'created')">
                <v-list-item-title>予約完了メール</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('send', 'updated')">
                <v-list-item-title>予約変更メール</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn depressed small class="gray ml-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-download</v-icon>
                帳票出力
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="report in reportGroup[item.facilityId]"
                :key="report.id"
                @click="$emit('output', report.id)"
              >
                <v-list-item-title>{{ report.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn depressed small class="red" @click="$emit('cancel')">
            <v-icon>mdi-delete-outline</v-icon>
            <span>予約取消</span>
          </v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="save">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import VariableInputField from '@web-i/components/VariableInputField.vue';
import { Facility, Report, Unit } from '@api/models';
import { InsertBookRequest } from '@api-i/routes/book/book';
import { chain } from 'lodash';
import { dateUtility } from '@c/util';
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import { validationRule } from '@api/constants';
import AddressSearcher from '@web/modules/address-searcher';
import { ConstantValidationRuleItems } from '@api-t/constants/validationRule';
import { documentTarget } from '@api/constants/documentTarget';

export interface BookAddItem extends InsertBookRequest {}

interface BookAddOption {
  date: string;
  text: string;
  value: {
    [key: string]: InsertDetailRequest[];
  };
}

export type BookAddDataType = {
  show: boolean;
  item: BookAddItem;
  newMode: boolean;
};

export const initItem: BookAddItem = {
  facilityId: '',
  name: '',
  kana: '',
  zipCode: '',
  address: '',
  tel: '',
  email: '',
  details: [],
};

export default Vue.extend({
  name: 'BookAdd',

  components: {
    DataPanelField,
    VariableInputField,
  },

  props: {
    facilities: {
      type: Array as PropType<Facility[]>,
      default: () => [],
    },

    units: {
      type: Array as PropType<Unit[]>,
      default: () => [],
    },

    reports: {
      type: Array as PropType<Report[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<BookAddDataType>,
      default: () => ({
        show: false,
        item: { ...initItem },
        newMode: false,
      }),
    },
  },

  data: () => ({
    tabIdx: 0,

    options: [] as BookAddOption[],
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): BookAddItem {
        return this.value.item;
      },
      set(item: BookAddItem): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value.newMode;
    },

    unitDict(): Record<string, Unit> {
      return chain(this.units).keyBy('id').value();
    },

    reportGroup(): Record<string, Report[]> {
      const result = chain(this.reports)
        .filter((r) => r.target === documentTarget.book.value)
        .groupBy('facilityId')
        .value();

      return result;
    },

    rules(): ConstantValidationRuleItems {
      return validationRule.items;
    },

    max(): (...values: number[]) => number {
      return Math.max;
    },

    min(): (...values: number[]) => number {
      return Math.min;
    },

    /**
     * 選択可能な区画種類
     */
    selectableUnits(): Unit[] {
      if (!this.value.item.facilityId) {
        return [];
      }
      const result = this.units.filter((unit) => {
        if (unit.isOption) {
          return false;
        }
        if (unit.facilityId !== this.value.item.facilityId) {
          return false;
        }
        return true;
      });

      return result;
    },

    /**
     * 選択可能なオプション
     */
    selectableOptions(): {
      value: string;
      text: string;
    }[] {
      const result = chain(this.value.item.details)
        .map(dateUtility.getISODatesFromObj)
        .flatten()
        .uniq()
        .filter((v) => {
          return !this.options.some((option) => option.date === v);
        })
        .map((v) => ({
          value: v,
          text: this.$dateFns.fnsFormat(v, '', 'yyyy年M月d日(E) 利用分'),
        }))
        .value();

      return result;
    },

    /**
     * 保存するディテール
     */
    detailsToSave(): Partial<InsertDetailRequest>[] {
      const result = chain(this.item.details)
        .map((detail) => {
          const paths = chain(this.unitDict[detail.unitId].fields)
            .keyBy('property')
            .keys()
            .value();

          const result_ = chain(detail)
            .pick([...paths, 'id', 'bookId', 'unitId'])
            .value();

          return result_;
        })
        .value();

      return result;
    },

    /**
     * 保存するオプション
     */
    optionsToSave(): InsertDetailRequest[] {
      const result = chain(this.options)
        .map((option) => {
          const result_ = chain(option.value)
            .values()
            .flatten()
            .filter((detail) => {
              const fields = this.unitDict[detail.unitId].fields;
              const result__ = chain(fields)
                .some((field) => {
                  return !!(detail as Record<string, any>)[field.property];
                })
                .value();

              return result__;
            })
            .value();

          return result_;
        })
        .flatten()
        .value();

      return result;
    },
  },

  watch: {
    show: {
      handler: function (value: boolean) {
        if (!value) {
          this.options = [];
        } else {
          this.init();
        }
      },
    },
  },

  methods: {
    /**
     * 住所検索
     */
    async onInput(value: string) {
      if (!AddressSearcher.isZipCodeFormat(value)) {
        return;
      }
      const result = await AddressSearcher.fetchAPI(value);
      if (!result) {
        return;
      }
      const { address1, address2, address3 } = result;
      this.item.address = `${address1}${address2}${address3}`;
    },

    /**
     * 初期化
     */
    init() {
      const options = [] as InsertDetailRequest[];
      const result = chain(this.item)
        .mapValues((v, k) => {
          if (k !== 'details') {
            return v;
          }
          const result_ = chain(v)
            .filter((d) => {
              if (this.unitDict[d.unitId].isOption) {
                options.push(d);
                return false;
              }
              return true;
            })
            .value();

          return result_;
        })
        .value();

      this.item = result;
      this.initOptions(options);
    },

    /**
     * オプションの初期化
     */
    initOptions(options: InsertDetailRequest[]) {
      // 日ごと区画種類Idごとにまとめる
      const result = chain(options)
        .groupBy('startDate')
        .mapValues((v) => {
          const result_ = chain(v).keyBy('unitId').value();

          return result_;
        })
        .value();

      // 日ごとにオプションを追加する
      chain(result)
        .forOwn((v, k) => {
          this.selectOption(k, v);
        })
        .value();
    },

    /**
     * 施設が変わったら区画種類とオプションをクリアする
     */
    onChangeFacility() {
      this.item.details = [];
      this.options = [];
    },

    deleteDetail(index: number) {
      this.item.details = [
        ...this.item.details.slice(0, index),
        ...this.item.details.slice(index + 1),
      ];
    },

    deleteOption(index: number) {
      this.options = [
        ...this.options.slice(0, index),
        ...this.options.slice(index + 1),
      ];
    },

    /**
     * 区画種類を選択した時
     */
    selectUnit(unit: Unit) {
      this.item.details.push({
        ...unit.fields?.reduce((acc, crr) => {
          return {
            ...acc,
            [crr.property]: '',
          };
        }, {}),
        unitId: unit.id!,
      });
      // 追加されたタブへ移動
      this.tabIdx = this.item.details.length;
    },

    /**
     * オプションを追加した時
     */
    selectOption(date: string, exists?: Record<string, InsertDetailRequest>) {
      const value = chain(this.units)
        .filter((u) => u.facilityId === this.item.facilityId)
        .filter((u) => u.isOption)
        .groupBy('group')
        .mapValues((units) => {
          const value_ = chain(units)
            .map((unit) => ({
              ...unit.fields?.reduce((acc, crr) => {
                return {
                  ...acc,
                  [crr.property]: '',
                };
              }, {}),
              ...(exists?.[unit.id!] ? { ...exists[unit.id!] } : {}),
              startDate: date,
              unitId: unit.id!,
            }))
            .value();
          return value_;
        })
        .value();
      this.options.push({
        date,
        text: this.$dateFns.fnsFormat(date, '', 'M月d日'),
        value,
      });
      // 追加されたタブへ移動
      if (!exists) {
        this.tabIdx = this.item.details.length + this.options.length;
      }
    },

    /**
     * 仮保存
     */
    tempSave() {
      this.$emit('temp', [...this.detailsToSave, ...this.optionsToSave]);
    },

    /**
     * 保存
     */
    save() {
      this.$emit('save', [...this.detailsToSave, ...this.optionsToSave]);
    },
  },
});
</script>
