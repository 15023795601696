<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>入力項目</h2>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            @click:row="handleClickRow"
          >
            <template #item.icon="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>
          </v-data-table>
          <field-detail
            v-model="dialog"
            @destroy="destroy"
            @save="save"
          ></field-detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { Field } from '@api/models';
import {
  GetAllFieldsRequest,
  GetAllFieldsResponse,
  UpdateFieldRequest,
  UpdateFieldResponse,
  DeleteFieldRequest,
  DeleteFieldResponse,
} from '@api-i/routes/field/field';
import FieldDetail, {
  initFieldDetailItem,
} from '../master_fields/FieldDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';

export default Vue.extend({
  name: 'AdminFields',

  pageOptions: {
    routeConfig: {
      path: '/admin/fields',
    },
    props: {
      title: 'マスタ設定 - フィールド',
    },
  },

  components: {
    FieldDetail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '対象', value: 'property', cellClass: 'pointer' },
        { text: '入力タイプ', value: 'typeName', cellClass: 'pointer' },
        { text: '入力サイト', value: 'targetSite', cellClass: 'pointer' },
        { text: 'アイコン', value: 'icon', cellClass: 'pointer' },
      ],
      items: [] as Field[],
    },
    dialog: {
      show: false,
      item: {} as Partial<UpdateFieldResponse>,
      newMode: false,
    },
  }),

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        const result = await this.$api<
          GetAllFieldsResponse,
          GetAllFieldsRequest
        >({
          path: '/field',
          method: 'get',
        });

        if (result && result.fields) {
          this.table.items = result.fields;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initFieldDetailItem));
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: UpdateFieldRequest) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/field/save',
          method: 'post',
          params: this.dialog.item,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<DeleteFieldResponse, DeleteFieldRequest>(
          {
            path: '/field/delete',
            method: 'post',
            params: this.dialog.item as DeleteFieldRequest,
          },
        );
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
