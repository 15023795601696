import {
  CognitoAuthenticatedUser,
  CognitoProps,
  CognitoUserAttributesKeyValue,
  CognitoUserDetail,
} from '@web-t/cognito';
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

/**
 * cognitoへのアクセスを共通化するクラス
 * @see https://www.npmjs.com/package/amazon-cognito-identity-js
 */
export class Cognito {
  private readonly UserPoolId: string;
  private readonly ClientId: string;

  private readonly userPool: CognitoUserPool;

  constructor(props: CognitoProps) {
    this.UserPoolId = props.userPoolId;
    this.ClientId = props.userPoolClientId;

    if (this.UserPoolId && this.ClientId) {
      this.userPool = new CognitoUserPool({
        UserPoolId: this.UserPoolId,
        ClientId: this.ClientId,
      });
    } else {
      throw new Error('UserPoolIdとClientIdが指定されていません。');
    }
  }

  /**
   * ログインする
   * @param param0.username ユーザ名
   * @param param0.password パスワード
   */
  public async login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<CognitoAuthenticatedUser> {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const cognitoUser = new CognitoUser({
      Pool: this.userPool,
      Username: username,
    });
    await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(session) {
          resolve(session);
        },
        onFailure(err) {
          reject(err);
        },
        newPasswordRequired() {
          console.log('new password is the same as before.');
          cognitoUser.completeNewPasswordChallenge(
            password,
            {},
            {
              onSuccess(session) {
                resolve(session);
              },
              onFailure(err) {
                reject(err);
              },
            },
          );
        },
      });
    });
    // ログインが正常に行った場合はユーザ情報を返却
    const userInfo = await this.getAuthenticatedUser();
    if (!userInfo) throw new Error('ログインに失敗しました。不明なエラー');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return userInfo;
  }

  /**
   * ログアウト
   */
  public async logout() {
    await new Promise((resolve) => {
      this.userPool.getCurrentUser()?.signOut(() => {
        resolve(true);
      });
    });
  }

  /**
   * ログインユーザの詳細情報を取得する
   * @returns
   */
  public async getLoggedInUserDetail(): Promise<CognitoUserDetail | null> {
    const res = await this.getAuthenticatedUser();
    if (!res) return null;
    const { user } = res;
    try {
      const attributes = await this.getUserAttributes(user);
      return {
        user,
        attributes,
      };
    } catch (error) {
      // グローバルサインアウトすると NotAuthorizedException: Access Token has been revoked が出ることがあるので回避
      console.error('ログイン済みユーザ情報の取得に失敗しました。', error);
    }
    return null;
  }

  /**
   * ログインしているかどうか
   * @returns
   */
  public async isLoggedIn(): Promise<boolean> {
    return !!(await this.getAuthenticatedUser());
  }

  /**
   * apiへのアクセストークンを取得する
   * @returns
   */
  public async getToken(): Promise<string | null> {
    const res = await this.getAuthenticatedUser();
    if (!res) return null;
    return res.session.getIdToken().getJwtToken() || null;
  }

  /**
   * パスワードを変更する
   * @param oldPassword
   * @param newPassword
   */
  public async changePassword(oldPassword: string, newPassword: string) {
    const res = await this.getAuthenticatedUser();
    if (!res) {
      throw new Error('ログインしていません。');
    }
    await new Promise((resolve, reject) => {
      res.user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          return reject(err);
        }
        resolve(result === 'SUCCESS');
      });
    });
  }

  /**
   * ユーザのattributesを更新する
   * @param attr attributes
   */
  public async updateAttributes(attr: CognitoUserAttributesKeyValue) {
    const res = await this.getAuthenticatedUser();
    if (!res) {
      throw new Error('ログインしていません。');
    }
    const { user } = res;

    const realAttr = this.keyValueToAttributes(attr);

    await new Promise((resolve, reject) => {
      user.updateAttributes(realAttr, (err, result, details) => {
        if (err) {
          console.log('update fail', result, details);
          return reject(err);
        }
        resolve(result);
      });
    });
  }

  /**
   * ユーザのセッションを取得する
   * @returns
   */
  public async getAuthenticatedUser(): Promise<CognitoAuthenticatedUser | null> {
    const user = this.userPool.getCurrentUser();
    if (!user) return user;
    return new Promise((resolve, reject) => {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            return reject(err);
          }
          if (session?.isValid()) {
            resolve({
              user,
              session,
            });
          } else {
            reject(new Error('session is invalid.'));
          }
        },
      );
    });
  }

  /**
   * ユーザのattributesを取得する
   * @param user authenticatedなユーザ
   * @returns
   */
  private async getUserAttributes(user: CognitoUser) {
    const attr: CognitoUserAttribute[] = await new Promise(
      (resolve, reject) => {
        user.getUserAttributes((err, result) => {
          if (err) return reject(err);
          resolve(result || []);
        });
      },
    );
    return this.attributesToKeyValue(attr);
  }

  /**
   * ユーザのattributesをkeyvalueに変換する
   * @param attrs user attributes
   * @returns key value attributes
   */
  private attributesToKeyValue(
    attrs: CognitoUserAttribute[],
  ): CognitoUserAttributesKeyValue {
    return attrs.reduce((prev, next) => {
      return {
        ...prev,
        [next.getName()]: next.getValue(),
      };
    }, {});
  }

  /**
   * ユーザのattributesをkeyvalueからcognitouserattributeの型に変換する
   * @param original key value attributes
   * @returns cognito user attributes
   */
  private keyValueToAttributes(
    original?: CognitoUserAttributesKeyValue,
  ): CognitoUserAttribute[] {
    return Object.entries(original || {}).reduce(
      (prev: CognitoUserAttribute[], [Name, Value]) => {
        return [
          ...prev,
          new CognitoUserAttribute({
            Name,
            Value,
          }),
        ];
      },
      [],
    );
  }
}
