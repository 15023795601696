<template>
  <div class="d-flex align-center" style="height: 40px">
    <div class="mr-5">
      {{ label }}
    </div>
    <div>
      <v-switch
        v-model="editValue"
        hide-details
        class="mt-0 pb-1"
        :label="`${editValue ? trueText : falseText}`"
        :disabled="disabled"
      ></v-switch>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'BooleanInput',

  props: {
    value: {
      type: [String, Boolean] as PropType<string | boolean | null>,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    trueText: {
      type: String,
      default: 'はい',
    },
    falseText: {
      type: String,
      default: 'いいえ',
    },
  },

  computed: {
    editValue: {
      get(): boolean {
        if (this.value === null || this.value === undefined) {
          return false;
        }
        if (typeof this.value === 'string') {
          return !!this.value;
        }
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
  },
});
</script>
