<template>
  <v-container class="free">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>空き状況照会/予約登録</h2>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <!-- 新規作成ボタン -->
        <v-btn x-large @click="open">
          <v-icon class="white--text">mdi-plus</v-icon>
          予約を新規作成
        </v-btn>
        <BookAdd
          v-model="dialog"
          :facilities="facilities"
          :units="units"
          @temp="tempSave"
          @save="save"
        />
      </v-col>
      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form>
                <v-row>
                  <v-col cols="12" md="3">
                    <!-- 施設 -->
                    <v-autocomplete
                      v-model="filter.facilityId"
                      :items="facilities"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      hide-details="auto"
                      label="施設"
                      multiple
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <!-- 利用目的 -->
                    <v-autocomplete
                      v-model="filter.purposeId"
                      :items="purposes"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      hide-details="auto"
                      label="利用目的"
                      multiple
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <!-- 利用日 -->
                    <DatePicker
                      v-model="filter.startDate"
                      label="利用日"
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <!-- 曜日 -->
                    <v-autocomplete
                      v-model="filter.dayOfWeek"
                      :items="daysOfWeek"
                      outlined
                      dense
                      hide-details="auto"
                      label="曜日"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <!-- 検索ボタン -->
                <v-btn small outlined @click="search()">
                  <v-icon>mdi-magnify</v-icon>
                  検索
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-card tile>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="calendarBox">
                  <div class="calendar">
                    <!-- 前へ -->
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <button :aria-label="prevLabel" v-on="on" @click="prev">
                          <v-icon>mdi-chevron-left</v-icon>
                        </button>
                      </template>
                      <span>{{ prevLabel }}</span>
                    </v-tooltip>
                    <!-- 週次の日付 -->
                    <div v-if="mode === 1">
                      {{ `${startLabel} - ${endLabel}` }}
                    </div>
                    <!-- 日次の日付 -->
                    <div v-else-if="mode === 0">
                      {{ dateLabel }}
                    </div>
                    <!-- 次へ -->
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <button :aria-label="nextLabel" v-on="on" @click="next">
                          <v-icon>mdi-chevron-right</v-icon>
                        </button>
                      </template>
                      <span>{{ nextLabel }}</span>
                    </v-tooltip>
                  </div>
                  <form>
                    <RadioGroup
                      v-model="mode"
                      :items="[
                        { text: '日', value: 0 },
                        { text: '週', value: 1 },
                      ]"
                      class="cal-switcher__switch"
                    />
                  </form>
                </div>
              </v-col>

              <!-- 検索結果 -->
              <v-col
                v-for="facility in table.items"
                :key="facility.id"
                cols="12"
              >
                <div class="d-flex mt-5 mb-2 data-panel-section col col-12">
                  <div class="my-n1 py-1">
                    <span class="font-weight-bold ma-2">
                      {{ facility.name }}
                    </span>
                  </div>
                </div>
                <!-- 週次 -->
                <WeeklyAvailabilityTable
                  v-if="mode === 1"
                  :dates="datesOfWeek"
                  :item="facility"
                  @click:cell="handleClickWeeklyCell"
                />
                <!-- 日次 -->
                <DailyAvailabilityTable
                  v-if="mode === 0"
                  :dates="datesOfWeek"
                  :disp-idx="dispIdx"
                  :item="facility"
                  @click:cell="handleClickDailyCell"
                />
              </v-col>

              <v-col>
                <!-- 凡例 -->
                <IconList />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import BookAdd, { BookAddItem, initItem } from '@web-i/components/BookAdd.vue';
import RadioGroup from '@web/components/inputs/radio-group.vue';
import DatePicker from '@web/components/inputs/date-picker.vue';
import DailyAvailabilityTable, {
  ClickDailyCellEventData,
} from '@web/components/availability/DailyAvailabilityTable.vue';
import IconList from '@web/components/availability/IconList.vue';
import WeeklyAvailabilityTable, {
  ClickWeeklyCellEventData,
} from '@web/components/availability/WeeklyAvailabilityTable.vue';
import { InsertBookRequest, InsertBookResponse } from '@api-i/routes/book/book';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { Facility, Purpose, Unit } from '@api/models';
import { loadCachePurposes, loadCacheUnits } from '@web/modules/master-loader';
import {
  GetWeeklyAvailabilityRequest,
  GetWeeklyAvailabilityResponse,
  WeeklyAvailability,
} from '@api-i/routes/availability/availability';
import { closedDayType, daysOfWeek, rentalType } from '@api/constants';
import { range } from 'lodash';
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import { dateUtility } from '@c/util';

export default Vue.extend({
  name: 'Free',

  pageOptions: {
    routeConfig: {
      path: '/free',
    },
    props: {
      title: '空き状況照会/予約登録',
    },
  },

  components: {
    BookAdd,
    RadioGroup,
    DatePicker,
    DailyAvailabilityTable,
    IconList,
    WeeklyAvailabilityTable,
  },

  data: () => ({
    units: [] as Unit[],

    purposes: [] as Purpose[],

    daysOfWeek: daysOfWeek.items,

    filter: {
      facilityId: [],
      purposeId: [],
      startDate: '',
      dayOfWeek: null,
    },

    datesOfWeek: [] as Date[],

    dispIdx: 0,

    mode: 1,

    table: {
      items: [] as WeeklyAvailability[],
    },

    dialog: {
      show: false,
      item: {} as BookAddItem,
      newMode: true,
    },
  }),

  computed: {
    prevLabel(): string {
      return this.mode === 0 ? '前日' : '前週';
    },

    nextLabel(): string {
      return this.mode === 0 ? '翌日' : '翌週';
    },

    startLabel() {
      const DATE_FORMAT = 'yyyy年M月d日';
      return this.$dateFns.fnsFormat(this.datesOfWeek[0], '', DATE_FORMAT);
    },

    endLabel() {
      const DATE_FORMAT = 'yyyy年M月d日';
      return this.$dateFns.fnsFormat(this.datesOfWeek[6], '', DATE_FORMAT);
    },

    dateLabel() {
      const DATE_FORMAT = 'yyyy年M月d日(E)';
      return this.$dateFns.fnsFormat(
        this.datesOfWeek[this.dispIdx],
        '',
        DATE_FORMAT,
      );
    },

    /**
     * 貸区分
     */
    rentalType() {
      return rentalType;
    },

    facilities(): Facility[] {
      return this.$store.getters['login/facilities'];
    },
  },

  async mounted() {
    await Promise.all([this.loadUnit(), this.loadPurpose(), this.search()]);
  },

  methods: {
    /**
     * 区画種類マスタを読み込む
     */
    async loadUnit() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 利用目的マスタを読み込む
     */
    async loadPurpose() {
      const purposes = await loadCachePurposes(this);
      this.$set(this, 'purposes', purposes);
    },

    /**
     * 検索
     */
    async search(date?: Date) {
      const { startDate, dayOfWeek } = this.filter;
      const date_ =
        date ?? (!startDate ? new Date() : new Date(startDate as string));
      if (dayOfWeek !== undefined && dayOfWeek !== null) {
        // 直前の？曜日
        date_.setDate(
          date_.getDate() + ((7 - date_.getDay() + dayOfWeek) % 7) - 7,
        );
      }
      const idx = (date_.getDay() + 6) % 7;
      const datesOfWeek = range(7).map((i) => {
        if (dayOfWeek !== undefined && dayOfWeek !== null) {
          // 曜日が指定されていたら
          date_.setDate(date_.getDate() + 7);
        } else {
          date_.setDate(date_.getDate() - ((date_.getDay() + 6) % 7) + i);
        }
        return structuredClone(date_);
      });
      const datesOfWeek_ = datesOfWeek.map((date) => {
        return this.$dateFns.fnsFormat(date, '', 'yyyy-MM-dd');
      });

      try {
        const result = await this.$api<
          GetWeeklyAvailabilityResponse,
          GetWeeklyAvailabilityRequest
        >({
          path: '/availability/week',
          method: 'get',
          params: {
            ...this.filter,
            startDate: datesOfWeek_,
          },
        });

        if (result) {
          this.$set(this.table, 'items', result.availability);
          if (dayOfWeek === undefined || dayOfWeek === null) {
            this.dispIdx = idx;
          }
          this.datesOfWeek = datesOfWeek;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 前週を読み込む
     */
    async loadLastWeek() {
      const { dayOfWeek } = this.filter;
      const date = this.datesOfWeek[0];
      if (dayOfWeek !== undefined && dayOfWeek !== null) {
        // 曜日が指定されていたら
        date.setDate(date.getDate() - 7 * 7);
      } else {
        date.setDate(date.getDate() - 7);
      }
      await this.search(date);
    },

    /**
     * 次週を読み込む
     */
    async loadNextWeek() {
      const { dayOfWeek } = this.filter;
      const date = this.datesOfWeek[0];
      if (dayOfWeek !== undefined && dayOfWeek !== null) {
        // 曜日が指定されていたら
        date.setDate(date.getDate() + 7 * 7);
      } else {
        date.setDate(date.getDate() + 7);
      }
      await this.search(date);
    },

    /**
     * 前週または前日へ
     */
    async prev() {
      if (this.mode === 1) {
        await this.loadLastWeek();
      } else if (this.mode === 0) {
        if (0 < this.dispIdx) {
          this.dispIdx--;
        } else {
          await this.loadLastWeek();
          this.dispIdx = 6;
        }
      }
    },

    /**
     * 次週または翌日へ
     */
    async next() {
      if (this.mode === 1) {
        await this.loadNextWeek();
      } else if (this.mode === 0) {
        if (this.dispIdx < 6) {
          this.dispIdx++;
        } else {
          await this.loadNextWeek();
          this.dispIdx = 0;
        }
      }
    },

    /**
     * 新規作成ダイアログを開く
     */
    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initItem));
    },

    /**
     * 週次テーブルのセルをクリックした時
     */
    handleClickWeeklyCell({
      facilityId,
      unitId,
      day,
    }: ClickWeeklyCellEventData) {
      if (day.ratio >= 100) {
        return;
      }
      if (closedDayType.items.some((item) => item.value === day.type)) {
        return;
      }
      const unit = this.units.find((u) => u.id === unitId);
      if (!unit) {
        return;
      }
      const item = {
        ...structuredClone(initItem),
        facilityId,
        details: [
          {
            ...unit.fields?.reduce((acc, crr) => {
              return {
                ...acc,
                [crr.property]: '',
              };
            }, {}),
            startDate: day.date,
            unitId: unit.id!,
          },
        ],
      };
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', item);
    },

    /**
     * 日次テーブルのセルをクリックした時
     */
    handleClickDailyCell({ facilityId, row, detail }: ClickDailyCellEventData) {
      if (detail.ratio >= 100) {
        return;
      }
      if (closedDayType.items.some((item) => item.value === row.type)) {
        return;
      }
      const unit = this.units.find((u) => u.id === row.id);
      if (!unit) {
        return;
      }
      const item = {
        ...structuredClone(initItem),
        facilityId,
        details: [
          {
            ...unit.fields?.reduce((acc, crr) => {
              return {
                ...acc,
                [crr.property]: '',
              };
            }, {}),
            ...(!detail.label
              ? {}
              : dateUtility.isTimeFormat(detail.label)
              ? { startTime: detail.label }
              : { section: JSON.stringify([detail.label]) }),
            startDate: this.$dateFns.fnsFormat(
              this.datesOfWeek[this.dispIdx],
              '',
              'yyyy-MM-dd',
            ),
            unitId: unit.id!,
          },
        ],
      };
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', item);
    },

    /**
     * 仮保存
     */
    async tempSave(details: InsertDetailRequest[]) {
      try {
        const result = await this.$api<InsertBookResponse, InsertBookRequest>({
          path: '/book/temp',
          method: 'post',
          params: {
            ...this.dialog.item,
            details: [...details],
          } as InsertBookRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.search();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの仮保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    /**
     * 保存
     */
    async save(details: InsertDetailRequest[]) {
      try {
        const result = await this.$api<InsertBookResponse, InsertBookRequest>({
          path: '/book/create',
          method: 'post',
          params: {
            ...this.dialog.item,
            details: [...details],
          } as InsertBookRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.search();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>
