<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>メール本文設定</h2>
        <div class="facility">（井川オートキャンプ場）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed @click="dialog.show = false" small>
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            hide-default-footer
            @click:row="handleClickRow"
          >
          </v-data-table>
          <v-dialog
            v-model="dialog.show"
            scrollable
            width="600"
            class="dialogBox"
          >
            <v-card class="dialogBox">
              <v-card-title>詳細</v-card-title>
              <v-card-text class="text--primary px-7">
                <MasterDetail v-model="dialog.data"></MasterDetail>
              </v-card-text>
              <v-card-actions>
                <v-btn depressed @click="dialog.show = false" small class="red">
                  <v-icon>mdi-delete-outline</v-icon>
                  <span>項目削除</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  @click="dialog.show = false"
                  small
                  class="gray"
                >
                  <v-icon class="">mdi-close-circle-outline</v-icon>
                  キャンセル
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="dialog.show = false"
                  small
                >
                  <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import MasterDetail from '@web/entry/internal/components/MasterDetail.vue';

export default Vue.extend({
  name: 'MastersMail',

  pageOptions: {
    routeConfig: {
      path: '/masters/mail',
    },
    props: {
      title: 'マスタ設定 - メール基本設定',
    },
  },

  components: {
    MasterDetail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '件名', value: 'mail', cellClass: 'pointer' },
      ],
      items: [
        {
          name: '完了',
          section: '件名がはいります。件名がはいります。',
        },
        {
          name: '変更',
          section: '件名がはいります。件名がはいります。',
        },
        {
          name: '取消',
          section: '件名がはいります。件名がはいります。',
        },
      ],
    },

    dialog: {
      show: false,
      data: null,
    },
  }),

  methods: {
    handleClickRow(item: any) {
      this.$set(this.dialog, 'data', item);
      this.dialog.show = true;
    },
  },

  watch: {
    markdown(to) {
      console.log(to);
    },
  },
});
</script>

<style lang="scss" scoped>
.markdown-editor {
  height: 550px;
}
</style>
