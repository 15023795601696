<template>
  <v-container>
    <v-row>
      <v-col class="d-flex align-center justify-center mb-10">
        <h4 class="text-sm-h5">{{ pageTitle }}</h4>
      </v-col>
    </v-row>
    <v-card class="pa-3 pa-sm-5">
      <v-card-text>
        <v-row>
          <v-col class="text-center">
            <h3>ログイン</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-form
                ref="loginForm"
                v-model="loginForm.valid"
                lazy-validation
                @submit="loginValidate"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginForm.data.username"
                      prepend-icon="mdi-account"
                      label="ユーザー名"
                      :rules="[loginForm.rules.required]"
                      outlined
                      dense
                      :disabled="loginForm.loading"
                      @keyup.enter="loginValidate"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginForm.data.password"
                      prepend-icon="mdi-lock"
                      label="パスワード"
                      type="password"
                      :rules="[loginForm.rules.required]"
                      outlined
                      dense
                      :disabled="loginForm.loading"
                      @keyup.enter="loginValidate"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      x-large
                      :disabled="!loginForm.valid || loginForm.loading"
                      block
                      :loading="loginForm.loading"
                      @click="loginValidate"
                    >
                      ログイン
                    </v-btn>
                  </v-col>
                  <v-col v-if="loginForm.message" cols="12">
                    <span class="red--text">{{ loginForm.message }}</span>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { loggedInCheck } from '@web/modules/login';
import { mapActions } from '@web-i/store/login';

export default Vue.extend({
  name: 'LoginComponent',

  data: () => ({
    loginForm: {
      valid: null as boolean | null,
      data: {
        username: null as string | null,
        password: null as string | null,
      },
      rules: {
        required: (v: unknown) => !!v || '必須項目です。',
      },
      loading: false,
      message: null as string | null,
    },
  }),

  computed: {
    pageTitle(): string {
      return process.env.PAGE_TITLE || '予約システム';
    },
  },

  methods: {
    async loginValidate() {
      if (!(this.$refs.loginForm as any).validate()) {
        await this.$nextTick();
        this.loginForm.data.password = null;
        return;
      }
      await this.login();
    },

    async login() {
      this.loginForm.loading = true;
      this.loginForm.message = null;
      const { username, password } = this.loginForm.data;
      if (!username || !password) {
        this.loginForm.message = 'ユーザー名とパスワードは必須項目です。';
        return;
      }
      try {
        await this.$cognito.login({ username, password });
        // ログイン済みのユーザを取得しstoreに保存
        await loggedInCheck({ store: this.$store, cognito: this.$cognito });
      } catch (error) {
        console.error(error);
        this.loginForm.message =
          'ログインに失敗しました。ユーザ名とパスワードを確認してください。';
        this.loginForm.data.password = null;
        (this.$refs.loginForm as any).resetValidation();
        await this.setLoggedInUser(null);
      }
      this.loginForm.loading = false;
    },

    ...mapActions(['setLoggedInUser']),
  },
});
</script>
