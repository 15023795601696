<template>
  <v-dialog v-model="show" scrollable width="1520" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? 'メール設定の追加' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" md="8" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <div class="masterDetail">
                  <v-form ref="form" v-model="formValid">
                    <v-row>
                      <v-col cols="12">
                        <DataPanelField label="名称">
                          <v-text-field
                            v-model="item.name"
                            placeholder="例： 新規予約時通知メール, 予約xx日前通知メール"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[(v) => !!v || '名称は必須項目です。']"
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField label="メール件名">
                          <v-text-field
                            v-model="item.title"
                            placeholder="例： {facility.name}予約完了のお知らせ"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[
                              (v) => !!v || 'メール件名は必須項目です。',
                            ]"
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField label="メール本文">
                          <v-textarea
                            ref="bodyTextField"
                            v-model="item.body"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[
                              (v) => !!v || 'メール本文は必須項目です。',
                            ]"
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField
                          label="同時送信するtoアドレス(「;」区切り)"
                        >
                          <v-text-field
                            v-model="item.alternativeTo"
                            placeholder="例： aaa@example.com;bbb@example.com"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField
                          label="同時送信するccアドレス(「;」区切り)"
                        >
                          <v-text-field
                            v-model="item.alternativeCc"
                            placeholder="例： aaa@example.com;bbb@example.com"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField
                          label="同時送信するbccアドレス(「;」区切り)"
                        >
                          <v-text-field
                            v-model="item.alternativeBcc"
                            placeholder="例： aaa@example.com;bbb@example.com"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <DataPanelField label="メール種類">
                          <v-select
                            v-model="item.mailType"
                            :items="mailType.items"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[
                              (v) => !!v || 'メール種類は必須項目です。',
                            ]"
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                        <template v-if="item.mailType === 'periodically'">
                          <DataPanelField label="定期実行時の時間">
                            <v-select
                              v-model="item.hour"
                              :items="
                                [...Array(24)].map((_v, i) => ({
                                  text: `${i}時`,
                                  value: i,
                                }))
                              "
                              outlined
                              dense
                              hide-details="auto"
                              clearable
                              :rules="[
                                (v) =>
                                  (v !== null && v !== undefined) ||
                                  '定期実行時の時間は必須項目です。',
                              ]"
                              class="mt-0 pt-0"
                            />
                          </DataPanelField>
                          <DataPanelField label="予約何日前">
                            <NumberInput
                              v-model="item.preDays"
                              outlined
                              dense
                              hide-details="auto"
                              clearable
                              :rules="[
                                (v) =>
                                  (v !== null && v !== undefined) ||
                                  '予約何日前は必須項目です。',
                                (v) => v > 0 || '1日以上前を指定してください。',
                              ]"
                            />
                          </DataPanelField>
                        </template>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn depressed small color="primary" @click="save">
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <FieldList
                  :items="fields"
                  class="overflow-auto"
                  @click="selectField"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { SaveMailConfigRequest } from '@api-i/routes/mailConfig/mailConfig';
import { mailType } from '@api/constants/mailType';
import NumberInput from '@web/components/inputs/number-input.vue';
import { mapActions as mapActionsSnackbar } from '@web/store/snackbar';
import { Field } from '@api/models';
import FieldList from '@web-i/components/FieldList.vue';

export type MailConfigDetailDataType = {
  show: boolean;
  item: SaveMailConfigRequest;
  newMode: boolean;
};

export default Vue.extend({
  name: 'MailConfigDetail',

  components: {
    DataPanelField,
    NumberInput,
    FieldList,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<MailConfigDetailDataType>,
      default: undefined,
    },
  },

  data: () => ({
    mailType,
    formValid: false,
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveMailConfigRequest {
        return this.value.item;
      },
      set(item: SaveMailConfigRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },
  },

  methods: {
    async save() {
      await (this.$refs.form as any).validate();

      if (!this.formValid) {
        this.registerSnackbar({
          type: 'error',
          message: '入力内容を確認してください。',
        });
        return;
      }
      this.$emit('save');
    },

    selectField(item: Field) {
      const element: HTMLInputElement = (this.$refs.bodyTextField as any)?.$refs
        ?.input;
      const cursorPosition = element?.selectionStart ?? 0;
      const value = this.item.body ?? '';

      if (cursorPosition > value.length) {
        return;
      }

      const value_ = [
        value.slice(0, cursorPosition),
        `{${item.property}}`,
        value.slice(cursorPosition),
      ].join('');
      this.item = { ...this.item, body: value_ };

      const nextPosition = cursorPosition + item.property.length + 2;
      element.focus();
      element.setSelectionRange(nextPosition, nextPosition);
    },

    ...mapActionsSnackbar({
      registerSnackbar: 'register',
    }),
  },
});
</script>
