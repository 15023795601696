<template>
  <v-container class="master">
    <v-row>
      <v-col cols="12" class="pageTtl">
        <h2>マスタ設定</h2>
      </v-col>

      <!-- 共通設定 -->
      <v-col cols="12">
        <v-card tile>
          <v-card-title>共通設定</v-card-title>
          <v-container class="">
            <v-row>
              <!-- 施設設定 -->
              <v-col cols="3">
                <v-btn to="/masters/facilities" outlined block x-large>
                  <div class="flex-grow-1 text-center">
                    <span>施設設定</span>
                  </div>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
              <!-- 権限グループ設定 -->
              <v-col cols="3">
                <v-btn to="/masters/roles" outlined block x-large>
                  <div class="flex-grow-1 text-center">
                    <span>権限グループ設定</span>
                  </div>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
              <!-- 利用目的 -->
              <v-col v-if="isAdmin" cols="3">
                <v-btn to="/admin/purposes" outlined block x-large>
                  <div class="flex-grow-1 text-center">
                    <span>利用目的</span>
                  </div>
                </v-btn>
              </v-col>
              <!-- 入力項目 -->
              <v-col v-if="isAdmin" cols="3">
                <v-btn to="/admin/fields" outlined block x-large>
                  <div class="flex-grow-1 text-center">
                    <span>入力項目</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- 施設ごとの設定 -->
      <v-col cols="12">
        <v-card tile>
          <v-card-title>施設ごとの設定</v-card-title>
          <v-container class="">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :value="selectedFacility"
                      :items="facilities"
                      outlined
                      dense
                      hide-details="auto"
                      label="施設"
                      clearable
                      item-text="name"
                      return-object
                      @change="setSelectedFacility"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <!-- 区画種類 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/units"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>区画種類</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 備品 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/options"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>備品</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 利用目的 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/purpose"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>利用目的</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 入力項目 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/field"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>入力項目</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 料金 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/fee"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>料金</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 利用時間帯 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/usageTime"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>利用時間帯</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 受付期間 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/receiptPeriod"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>受付期間</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 入力検証 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/validation"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>入力検証</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- メール -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/mailConfig"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>メール設定</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 帳票 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/reports"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>帳票</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- 休館日 -->
                  <v-col cols="3">
                    <v-btn
                      to="/masters/closedDay"
                      outlined
                      block
                      x-large
                      :disabled="!selectedFacility"
                    >
                      <div class="flex-grow-1 text-center">
                        <span>休館日</span>
                      </div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Masters',

  pageOptions: {
    routeConfig: {
      path: '/masters',
    },
    props: {
      title: 'マスタ設定',
    },
  },

  computed: {
    ...mapGetters('master', ['selectedFacility']),

    ...mapGetters('login', ['isAdmin', 'facilities']),
  },

  methods: {
    ...mapActions('master', ['setSelectedFacility']),
  },
});
</script>

<style lang="scss" scoped>
.master-cell {
  border: thin solid gray;
}
</style>
