<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? '帳票の追加' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <v-row>
            <v-col cols="12">
              <DataPanelField label="名称">
                <v-text-field
                  v-model="item.name"
                  placeholder="例： 利用許可書"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[(v) => !!v || '名称は必須項目です。']"
                  class="mt-0 pt-0"
                />
              </DataPanelField>
              <DataPanelField label="対象">
                <v-select
                  v-model="item.target"
                  :items="documentTarget.items"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[(v) => !!v || '対象は必須項目です。']"
                  class="mt-0 pt-0"
                />
              </DataPanelField>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isNewMode"
          depressed
          small
          class="red"
          @click="$emit('destroy')"
        >
          <v-icon>mdi-delete-outline</v-icon>
          <span>項目削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import { SaveReportRequest } from '@api-i/routes/report/report';
import { documentTarget } from '@api/constants/documentTarget';
import { ConstantDocumentTarget } from '@api-t/constants/documentTarget';

export type ReportDetailDataType = {
  show: boolean;
  item: SaveReportRequest;
  newMode: boolean;
};

export default Vue.extend({
  name: 'ReportDetail',

  components: {
    DataPanelField,
  },

  props: {
    value: {
      type: Object as PropType<ReportDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveReportRequest {
        return this.value.item;
      },
      set(item: SaveReportRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    documentTarget(): ConstantDocumentTarget {
      return documentTarget;
    },
  },
});
</script>
