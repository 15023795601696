<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card>
      <v-card-title>
        <slot name="title">
          <span class="text-subtitle-1">{{ message }}</span>
        </slot>
      </v-card-title>
      <slot name="inner"></slot>
      <v-card-actions class="justify-center">
        <slot name="action">
          <v-btn color="gray" v-bind="noButtonProps" @click="onClickNo">
            {{ noButtonText }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            v-bind="yesButtonProps"
            @click="onClickYes"
          >
            {{ yesButtonText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Confirm',

  props: {
    message: {
      type: String,
      default: 'よろしいですか?',
    },
    yesButtonText: {
      type: String,
      default: 'OK',
    },
    yesButtonProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    noButtonText: {
      type: String,
      default: 'キャンセル',
    },
    noButtonProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    maxWidth: {
      type: [String, Number],
      default: '500px',
    },
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    onClickYes() {
      this.dialog = false;
      this.$emit('yes'), this.$emit('decided', true);
    },
    onClickNo() {
      this.dialog = false;
      this.$emit('no'), this.$emit('decided', false);
    },
  },
});
</script>
