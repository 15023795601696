import {
  ConstantInvoiceStatus,
  ConstantInvoiceStatusDict,
} from '@api-t/constants/invoiceStatus';

const invoiceStatusDict: ConstantInvoiceStatusDict = {
  unpaid: {
    text: '未入金',
    value: 'unpaid',
  },
  paid: {
    text: '消込済',
    value: 'paid',
  },
};

export const invoiceStatus: ConstantInvoiceStatus = {
  ...invoiceStatusDict,
  items: [invoiceStatusDict.unpaid, invoiceStatusDict.paid],
  get(value: string): string {
    switch (value) {
      case invoiceStatusDict.unpaid.value:
        return invoiceStatusDict.unpaid.text;
      case invoiceStatusDict.paid.value:
        return invoiceStatusDict.paid.text;
      default:
        return '';
    }
  },
};
