<template>
  <v-dialog v-model="show" scrollable width="1280" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <v-row>
            <v-col cols="6">
              <DataPanelField label="状態">
                <v-edit-dialog
                  :return-value.sync="item.status"
                  large
                  cancel-text="キャンセル"
                  save-text="適用"
                >
                  {{ invoiceStatus.get(item.status) }}
                  <v-icon small class="ml-2">mdi-pencil</v-icon>
                  <template #input>
                    <div class="mt-5">
                      <v-select
                        v-model="item.status"
                        :items="invoiceStatus.items"
                        outlined
                        dense
                        hide-details="auto"
                        clearable
                      >
                      </v-select>
                    </div>
                  </template>
                </v-edit-dialog>
              </DataPanelField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.book?.receiptNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.book?.facilityName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.book?.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.book?.kana }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" md="8">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.book?.address }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.book?.zipCode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.book?.tel }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12">
              <custom-data-table
                :headers="tableHeaders"
                :items="item.details"
                item-class="pointer"
                :use-draggable="true"
                hide-default-footer
                :items-per-page="-1"
                @updated-items="
                  (v) => {
                    item.details = v;
                  }
                "
              >
                <!-- 日付 -->
                <template #item.date="{ item: item_ }">
                  <v-edit-dialog :return-value.sync="item_.date">
                    {{ item_.date }}
                    <template #input>
                      <v-text-field v-model="item_.date" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 名称 -->
                <template #item.name="{ item: item_ }">
                  <v-edit-dialog :return-value.sync="item_.name">
                    {{ item_.name }}
                    <template #input>
                      <v-text-field v-model="item_.name" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 単価 -->
                <template #item.unitPrice="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.unitPrice"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ (Number(item_.unitPrice) || 0).toLocaleString() }}
                    <template #input>
                      <NumberInput v-model="item_.unitPrice" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 数量 -->
                <template #item.numOfPieces="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.numOfPieces"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ (Number(item_.numOfPieces) || 0).toLocaleString() }}
                    <template #input>
                      <NumberInput v-model="item_.numOfPieces" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 金額 -->
                <template #item.amount="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.amount"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ (Number(item_.amount) || 0).toLocaleString() }}
                    <template #input>
                      <NumberInput v-model="item_.amount" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 税率 -->
                <template #item.taxRate="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.taxRate"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ item_.taxRate }}
                    <template #input>
                      <NumberInput v-model="item_.taxRate" single-line />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 税区分 -->
                <template #item.taxType="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.taxType"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ taxType.get(item_.taxType) }}
                    <template #input>
                      <v-select
                        v-model="item_.taxType"
                        :items="taxType.items"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 端数処理区分 -->
                <template #item.roundingType="{ item: item_ }">
                  <v-edit-dialog
                    :return-value.sync="item_.roundingType"
                    large
                    cancel-text="キャンセル"
                    save-text="適用"
                  >
                    {{ roundingType.get(item_.roundingType) }}
                    <template #input>
                      <v-select
                        v-model="item_.roundingType"
                        :items="roundingType.items"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.actions="{ item: item_ }">
                  <v-icon @click="deleteItem(item_)">mdi-close</v-icon>
                </template>
              </custom-data-table>
              <div>
                <v-btn color="primary" dark class="mt-4" @click="addItem">
                  <v-icon>mdi-plus</v-icon>
                  追加
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="pa-4 text-right">
                {{ summary.toLocaleString() + '円（税込）' }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn depressed small v-bind="attrs" v-on="on">
              <v-icon>mdi-download</v-icon>
              帳票出力
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(report, index) in reportGroup[item.book?.facilityId]"
              :key="index"
              @click="$emit('output', report.id)"
            >
              <v-list-item-title>{{ report.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn depressed small class="red" @click="$emit('destroy')">
          <v-icon>mdi-delete-outline</v-icon>
          <span>この請求を削除する</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { chain, parseInt } from 'lodash';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import NumberInput from '@web/components/inputs/number-input.vue';
import { invoiceStatus } from '@api/constants/invoiceStatus';
import { UpdateInvoiceRequest } from '@api-i/routes/invoice/invoice';
import { roundingType, taxType } from '@api/constants';
import { Report } from '@api/models';
import { documentTarget } from '@api/constants/documentTarget';

export type InvoiceDetailDataType = {
  show: boolean;
  item: UpdateInvoiceRequest;
  newMode: boolean;
};

export default Vue.extend({
  name: 'InvoiceDetail',

  components: {
    DataPanelField,
    NumberInput,
  },

  props: {
    reports: {
      type: Array as PropType<Report[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<InvoiceDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): UpdateInvoiceRequest {
        return this.value.item;
      },
      set(item: UpdateInvoiceRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    tableHeaders() {
      return [
        { text: '日付', value: 'date', cellClass: 'pointer' },
        { text: '名称', value: 'name', cellClass: 'pointer' },
        {
          text: '単価',
          value: 'unitPrice',
          cellClass: 'pointer',
          align: 'end',
        },
        {
          text: '数量',
          value: 'numOfPieces',
          cellClass: 'pointer',
          align: 'end',
        },
        {
          text: '金額（税込）',
          value: 'amount',
          cellClass: 'pointer',
          align: 'end',
        },
        { text: '税率', value: 'taxRate', cellClass: 'pointer', align: 'end' },
        { text: '税区分', value: 'taxType', cellClass: 'pointer' },
        { text: '端数処理区分', value: 'roundingType', cellClass: 'pointer' },
        { text: '', value: 'actions', cellClass: 'pointer', sortable: false },
      ];
    },

    summary(): number {
      const result = chain(this.value.item.details)
        .map('amount')
        .map(parseInt)
        .sum()
        .value();

      return result;
    },

    reportGroup(): Record<string, Report[]> {
      const result = chain(this.reports)
        .filter((r) => r.target === documentTarget.invoice.value)
        .groupBy('facilityId')
        .value();

      return result;
    },

    invoiceStatus() {
      return invoiceStatus;
    },

    taxType() {
      return taxType;
    },

    roundingType() {
      return roundingType;
    },
  },

  methods: {
    addItem() {
      this.item.details.push({
        invoiceId: this.item.id!,
        date: '月日',
        name: '',
        unitPrice: 0,
        numOfPieces: 0,
        amount: 0,
        taxRate: 10,
        taxType: taxType.included.value,
        roundingType: roundingType.round.value,
        order: -1,
      });
    },

    deleteItem(item: any) {
      const idx = this.item.details.indexOf(item);
      this.item.details.splice(idx, 1);
    },
  },
});
</script>
