import {
  StoreLoginActions,
  StoreLoginGetters,
  StoreLoginMutations,
  StoreLoginState,
} from '@web-t/store/login';
import { StoreRootInternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

// https://tech.opst.co.jp/2021/10/25/vuex-typescript-%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E5%88%86%E5%89%B2%E3%81%99%E3%82%8B/
// https://dev.to/mrchoke/vue-js-2-and-vuex-3-with-typescript-371p
// https://qiita.com/potato4d/items/c9c0c8e674f20c85948a

const state: StoreLoginState = {
  loggedInUser: null,
  dbUser: null,
};

const getters: DefineGetters<StoreLoginGetters, StoreLoginState> = {
  /**
   * ログインしているかどうか
   * @param state
   * @returns
   */
  isLoggedIn(state) {
    return !!state.loggedInUser;
  },
  /**
   * ユーザー名
   * @param state
   * @returns
   */
  username(state) {
    return state.dbUser?.name || '';
  },
  /**
   * 管理者かどうか
   * @param state
   * @returns
   */
  isAdmin(state) {
    return state.dbUser?.role?.isAdmin || false;
  },
  /**
   * 利用可能な施設
   * @param state
   * @returns
   */
  facilities(state) {
    return state.dbUser?.role?.facilities || [];
  },
};

const mutations: DefineMutations<StoreLoginMutations, StoreLoginState> = {
  setLoggedInUser(state, payload) {
    state.loggedInUser = payload;
  },
  setDBUser(state, payload) {
    state.dbUser = payload;
  },
};

const actions: DefineActions<
  StoreLoginActions,
  StoreLoginState,
  StoreLoginMutations,
  StoreLoginGetters
> = {
  setLoggedInUser({ commit }, payload) {
    commit('setLoggedInUser', payload);
  },
  setDBUser({ commit }, payload) {
    commit('setDBUser', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreLoginState,
    StoreLoginGetters,
    StoreLoginMutations,
    StoreLoginActions
  >('login');

const storeModule: Module<StoreLoginState, StoreRootInternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<StoreLoginState, StoreRootInternal>,
};

export default storeModule;
