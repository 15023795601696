<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>権限グループ設定</h2>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed color="primary" small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="false"
            @click:row="handleClickRow"
          >
            <template #item.isAdmin="{ item }">
              <v-chip :color="item.isAdmin ? 'enabled' : 'disabled'">
                {{ item.isAdmin ? 'はい' : 'いいえ' }}
              </v-chip>
            </template>
          </custom-data-table>
          <Detail v-model="dialog" @save="save" @destroy="destroy"></Detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  DeleteRoleRequest,
  DeleteRoleResponse,
  GetAllRolesRequest,
  GetAllRolesResponse,
  SaveRoleRequest,
  SaveRoleResponse,
} from '@api-i/routes/role/role';
import { Facility, Role } from '@api/models';
import { InferCreationAttributesRole } from '@api/models/Role';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadCacheFacilities } from '@web/modules/master-loader';
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import Detail from './detail.vue';

const initializedItem: Partial<InferCreationAttributesRole> = {
  isAdmin: false,
  facilities: [],
};

export default Vue.extend({
  name: 'MasterRoles',

  pageOptions: {
    routeConfig: {
      path: '/masters/roles',
    },
    props: {
      title: 'マスタ設定 - 権限グループ',
    },
  },

  components: {
    Detail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: '管理者', value: 'isAdmin', cellClass: 'pointer' },
      ] as DataTableHeader[],
      items: [] as Role[],
    },

    dialog: {
      show: false,
      item: initializedItem as InferCreationAttributesRole,
      facilities: [] as Facility[],
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.load(), this.loadFacilities()]);
    },

    async load() {
      try {
        const result = await this.$api<GetAllRolesResponse, GetAllRolesRequest>(
          {
            path: '/role',
            method: 'get',
            params: {},
          },
        );

        this.table.items = result.result;
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async loadFacilities() {
      const result = await loadCacheFacilities(this);
      this.$set(this.dialog, 'facilities', result);
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initializedItem));
    },

    handleClickRow(item: Role) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
    },

    async save() {
      try {
        const result = await this.$api<SaveRoleResponse, SaveRoleRequest>({
          path: '/role/save',
          method: 'post',
          params: {
            role: {
              ...this.dialog.item,
              id: this.dialog.item.id,
            },
          },
        });
        this.dialog.show = false;

        if (result.role) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      try {
        const id = this.dialog.item?.id;
        if (id) {
          const result = await this.$api<DeleteRoleResponse, DeleteRoleRequest>(
            {
              path: '/role/delete',
              method: 'post',
              params: {
                id,
              },
            },
          );
          this.dialog.show = false;

          if (result) {
            await this.load();
          }
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>
