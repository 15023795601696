<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>メール設定</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small outlined @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
            <template #item.mailType="{ value }">
              <span>{{ mailType[value].text }}</span>
            </template>
          </custom-data-table>
          <MailConfigDetail
            v-model="dialog"
            :fields="fields"
            @save="save"
            @destroy="destroy"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  DeleteMailConfigRequest,
  DeleteMailConfigResponse,
  OrderMailConfigRequest,
  OrderMailConfigResponse,
  SaveMailConfigRequest,
  SaveMailConfigResponse,
} from '@api-i/routes/mailConfig/mailConfig';
import { MailConfig } from '@api/models';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadMailConfigs } from '@web/modules/master-loader';
import { mapActions } from '@web/store/snackbar';
import Vue from 'vue';
import MailConfigDetail from './MailConfigDetail.vue';
import { mailType } from '@api/constants/mailType';
import { systemFields } from '@api/constants';

export default Vue.extend({
  name: 'MastersMailConfigs',

  pageOptions: {
    routeConfig: {
      path: '/masters/mailConfig',
    },
    props: {
      title: 'マスタ設定 - 帳票',
    },
  },

  components: {
    MailConfigDetail,
  },

  data: () => ({
    table: {
      headers: [
        { text: '名称', value: 'name', cellClass: 'pointer' },
        { text: 'メール種類', value: 'mailType', cellClass: 'pointer' },
      ],
      items: [] as MailConfig[],
    },

    dialog: {
      show: false,
      item: {} as any,
      newMode: false,
    },

    mailType,
  }),

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },

    fields() {
      return [...systemFields.book, ...systemFields.facility];
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      const mailConfigs = await loadMailConfigs(this, {
        facilityId: facilityId,
      });

      if (mailConfigs) {
        this.table.items = mailConfigs;
      }
    },

    open() {
      const facilityId = this.selectedFacility.id;
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', { name: '', target: '', facilityId });
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: MailConfig) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', JSON.parse(JSON.stringify(item)));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const data = structuredClone(this.dialog.item);
        const result = await this.$api<
          SaveMailConfigResponse,
          SaveMailConfigRequest
        >({
          path: '/mailConfig/save',
          method: 'post',
          params: { ...data },
        });
        this.dialog.show = false;
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeleteMailConfigResponse,
          DeleteMailConfigRequest
        >({
          path: '/mailConfig/delete',
          method: 'post',
          params: this.dialog.item as DeleteMailConfigRequest,
        });
        this.dialog.show = false;
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      if (!this.table.items) {
        return;
      }
      try {
        const result = await this.$api<
          OrderMailConfigResponse,
          OrderMailConfigRequest
        >({
          path: '/mailConfig/order',
          method: 'post',
          params: {
            items: this.table.items.map((item, order) => {
              item.order = order + 1;
              return item;
            }),
          },
        });
        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
