<template>
  <v-dialog v-model="show" scrollable width="500" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <v-row>
            <v-col cols="12">
              <DataPanelFieldWrap
                v-model="item.name"
                label="名称"
              ></DataPanelFieldWrap>
              <DataPanelField v-model="item.name" label="管理者">
                <v-edit-dialog
                  :return-value.sync="item.isAdmin"
                  large
                  cancel-text="キャンセル"
                  save-text="適用"
                >
                  <span>
                    {{ item.isAdmin ? 'はい' : 'いいえ' }}
                  </span>
                  <v-icon small class="ml-2">mdi-pencil</v-icon>
                  <template #input>
                    <v-checkbox
                      v-model="item.isAdmin"
                      hide-details
                      :label="`${item.isAdmin ? 'はい' : 'いいえ'}`"
                    ></v-checkbox>
                  </template>
                </v-edit-dialog>
              </DataPanelField>
            </v-col>
          </v-row>
          <DataPanelSection label="施設選択"></DataPanelSection>
          <v-row>
            <v-col cols="12">
              <small> 選択済み施設 </small>
              <div
                v-for="facility in selectedFacilities"
                :key="facility.id"
                class="d-flex align-center role-facility-row px-3"
              >
                <div class="flex-grow-1">
                  {{ facility.name }}
                </div>
                <div>
                  <v-btn
                    icon
                    color="red"
                    @click="handleClickRemoveFacility(facility)"
                  >
                    <v-icon class="role-facility-delete-icon"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="add.value"
                :items="facilityItems"
                label="追加する施設を選択"
                outlined
                hide-details
                dense
                @change="handleChangeFacility"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <Confirm
          v-if="!!item.id"
          message="削除します。よろしいですか?"
          @yes="$emit('destroy')"
        >
          <template #default="{ on }">
            <v-btn depressed small class="red" v-on="on">
              <v-icon>mdi-delete-outline</v-icon>
              <span>項目削除</span>
            </v-btn>
          </template>
        </Confirm>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" small @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { InferCreationAttributesRole } from '@api/models/Role';
import Vue, { PropType } from 'vue';
import Confirm from '@web/components/Confirm.vue';
import DataPanelFieldWrap from '@web-i/components/DataPanelFieldWrap.vue';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import DataPanelSection from '@web-i/components/DataPanelSection.vue';
import { Facility } from '@api/models';

interface DetailDataType {
  show: boolean;
  item: InferCreationAttributesRole;
  facilities: Facility[];
}

export default Vue.extend({
  name: 'MasterRoleDetail',

  components: {
    Confirm,
    DataPanelField,
    DataPanelFieldWrap,
    DataPanelSection,
  },

  props: {
    value: {
      type: Object as PropType<DetailDataType>,
      required: true,
    },
  },

  data: () => ({
    add: {
      value: null as null | string,
    },
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(newValue: boolean) {
        this.$emit('input', {
          ...this.value,
          show: newValue,
        });
      },
    },
    item: {
      get(): InferCreationAttributesRole {
        return this.value.item;
      },
      set(newValue: InferCreationAttributesRole) {
        this.$emit('input', {
          ...this.value,
          item: newValue,
        });
      },
    },
    originalFacilities(): Facility[] {
      return this.value.facilities || [];
    },
    // 選択用
    facilityItems(): any[] {
      return (this.value.facilities || [])
        .filter((f) => !this.selectedFacilityIds.includes(f.id!))
        .map((f) => ({
          text: f.name,
          value: f.id,
        }));
    },
    selectedFacilityIds(): string[] {
      return this.item.facilities?.map((f) => f.id!) || [];
    },
    selectedFacilities(): Facility[] {
      return this.originalFacilities.filter((f) =>
        this.selectedFacilityIds.includes(f.id!),
      );
    },
  },

  methods: {
    async handleChangeFacility() {
      if (this.add.value) {
        this.item.facilities = [
          ...(this.item.facilities || []),
          this.originalFacilities.find((f) => f.id === this.add.value),
        ].filter((v) => !!v) as Facility[];
      }
      await this.$nextTick();
      // 待ってからクリアする
      this.$set(this.add, 'value', null);
    },

    // 削除ボタン
    async handleClickRemoveFacility(facility: Facility) {
      this.item.facilities = (this.item.facilities || []).filter(
        (f) => f.id !== facility.id,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.role-facility-row {
  &:hover {
    background: #eceff1;
  }

  .v-btn {
    .v-icon.role-facility-delete-icon {
      margin: 0 !important;
    }
  }
}
</style>
