<template>
  <v-dialog v-model="show" scrollable width="1520" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-text class="text--primary">
        <div class="masterDetail pt-3 pb-3">
          <v-row>
            <v-col cols="12" md="9" class="pl-0 pr-md-9 pt-0 pb-0">
              <v-card class="dialogBox">
                <v-card-title>
                  {{ isNewMode ? '備品の追加' : '詳細' }}
                </v-card-title>
                <v-card-text class="text--primary px-7">
                  <div class="masterDetail">
                    <v-row>
                      <v-col cols="6">
                        <DataPanelField label="名称">
                          <v-text-field
                            v-model="item.name"
                            placeholder="例： テント"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[(v) => !!v || '名称は必須項目です。']"
                            class="mt-0 pt-0"
                          ></v-text-field>
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="貸区分">
                          <v-select
                            v-model="item.type"
                            :items="rentalType.items"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :rules="[(v) => !!v || '貸区分は必須項目です。']"
                          ></v-select>
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="予約単位(分)">
                          <NumberInput
                            :value="item.step"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            @input="(value) => (item.step = value || 0)"
                          />
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="最大数量">
                          <NumberInput
                            :value="item.maximum"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            @input="(value) => (item.maximum = value || 0)"
                          />
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="有効期間(from)">
                          <DatetimePicker v-model="item.from" />
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="有効期間(to)">
                          <DatetimePicker v-model="item.to" />
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="選択肢">
                          <v-textarea
                            v-model="item.items"
                            placeholder="1行に1つの選択肢を入力"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            :disabled="item.type === rentalType.time.value"
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                      </v-col>
                      <v-col cols="6">
                        <DataPanelField label="メール記載内容">
                          <v-textarea
                            ref="mailTextField"
                            v-model="item.mail"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            class="mt-0 pt-0"
                          />
                        </DataPanelField>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <DataPanelField label="グループ">
                          <v-text-field
                            v-model="item.group"
                            outlined
                            dense
                            hide-details="auto"
                            clearable
                            class="mt-0 pt-0"
                          ></v-text-field>
                        </DataPanelField>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    v-if="!isNewMode"
                    depressed
                    small
                    class="red"
                    @click="$emit('destroy')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>項目削除</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn depressed small class="gray" @click="show = false">
                    <v-icon class="">mdi-close-circle-outline</v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn
                    depressed
                    small
                    color="primary"
                    :disabled="disabledToSave"
                    @click="$emit('save')"
                  >
                    <v-icon class="white--text">mdi-file-edit-outline</v-icon>
                    保存
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" class="fieldList rounded">
              <v-card height="100%">
                <v-card-title class="subtitle-1">項目一覧</v-card-title>
                <FieldList
                  :items="fields"
                  class="overflow-auto"
                  @click="selectField"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveUnitRequest } from '@api-i/routes/unit/unit';
import { rentalType } from '@api/constants';
import DataPanelField from '@web-i/components/DataPanelField.vue';
import DatetimePicker from '@web/components/inputs/datetime-picker.vue';
import NumberInput from '@web/components/inputs/number-input.vue';
import FieldList from '@web-i/components/FieldList.vue';
import { Field } from '@api/models';

export type UnitOptionDetailDataType = {
  show: boolean;
  item: SaveUnitRequest;
  newMode: boolean;
};

export const initUnitOptionDetailItem: Partial<SaveUnitRequest> = {
  group: '',
  name: '',
  type: '',
  maximum: undefined,
  isOption: true,
  from: new Date(),
  to: new Date('2099-12-31T14:59:00.000Z'),
  facilityId: '',
};

export default Vue.extend({
  name: 'UnitOptionDetail',

  components: {
    DataPanelField,
    DatetimePicker,
    NumberInput,
    FieldList,
  },

  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },

    value: {
      type: Object as PropType<UnitOptionDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveUnitRequest {
        return this.value.item;
      },
      set(item: SaveUnitRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },

    rentalType() {
      return rentalType;
    },

    disabledToSave(): boolean {
      if (!this.item.name) {
        return true;
      }
      if (!this.item.type) {
        return true;
      }
      if (this.item.type === rentalType.frame.value) {
        return !Number(this.item.maximum) === !this.item.items;
      }
      return false;
    },
  },

  methods: {
    selectField(item: Field) {
      const element: HTMLInputElement = (this.$refs.mailTextField as any)?.$refs
        ?.input;
      const cursorPosition = element?.selectionStart ?? 0;
      const value = this.item.mail ?? '';

      if (cursorPosition > value.length) {
        return;
      }

      const value_ = [
        value.slice(0, cursorPosition),
        `{${item.property}}`,
        value.slice(cursorPosition),
      ].join('');
      this.item = { ...this.item, mail: value_ };

      const nextPosition = cursorPosition + item.property.length + 2;
      element.focus();
      element.setSelectionRange(nextPosition, nextPosition);
    },
  },
});
</script>
