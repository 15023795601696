<template>
  <v-card :width="300" flat>
    <v-card-text>
      <v-container class="pa-0">
        <v-row v-for="(v, row) in icons" :key="row" no-gutters class="mx-2">
          <v-col v-for="(icon, col) in v" :key="col" cols="2">
            <v-btn
              icon
              :color="value === icon ? 'primary' : ''"
              :style="{ backgroundColor: value === icon ? '#f2f3f8' : '' }"
              @click="$emit('input', icon)"
            >
              <v-icon>{{ icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import chunk from 'lodash/chunk';
import Vue from 'vue';

export default Vue.extend({
  name: 'IconSelector',

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    icons() {
      const list = [
        'mdi-domain',
        'mdi-car',
        'mdi-account-outline',
        'mdi-bed',
        'mdi-format-list-bulleted',
        'mdi-calendar',
        'mdi-clock-outline',
        'mdi-ceiling-light',
        'mdi-login',
        'mdi-logout',
      ];
      return chunk(list, 6);
    },
  },
});
</script>
