<template>
  <v-container class="reserveList">
    <v-row>
      <v-col cols="6" class="pageTtl">
        <h2>利用者お知らせ設定</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn x-large @click="open">
          <v-icon class="white--text"> mdi-plus </v-icon>
          お知らせを新規作成
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in 1" :key="i">
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="search.title"
                      outlined
                      dense
                      hide-details="auto"
                      label="タイトル"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="search.public"
                      :items="status"
                      outlined
                      dense
                      hide-details="auto"
                      label="ステータス"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions class="pl-0 pr-0 pt-4 pb-2">
                <v-spacer></v-spacer>
                <v-btn small outlined @click="load"
                  ><v-icon>mdi-magnify</v-icon>検索</v-btn
                >
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :items-per-page="20"
            item-class="pointer"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1],
            }"
            @click:row="handleClickRow"
          >
            <template #item.period="{ item }">
              {{ item.from | datetime }}
              ～
              {{ item.to | datetime }}
            </template>
            <template #item.facilityId="{ value }">
              {{ getFacilityName(value) }}
            </template>
            <template #item.isEmergency="{ value }">
              <v-chip v-if="!!value" :color="'disabled'"> 重要 </v-chip>
            </template>
            <template #item.isPublic="{ value }">
              <v-chip :color="value ? 'enabled' : 'disabled'">
                {{ value ? '公開中' : '非公開' }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <notice-detail v-model="dialog" @save="save"></notice-detail>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  GetAllNoticeForUserRequest,
  GetAllNoticeForUserResponse,
  InsertNoticeForUserRequest,
  InsertNoticeForUserResponse,
  UpdateNoticeForUserRequest,
  UpdateNoticeForUserResponse,
} from '@api-i/routes/noticeForUser/noticeForUser';
import { Facility, NoticeForUser } from '@api/models';
import { DataTableHeader } from 'vuetify';
import NoticeDetail, {
  initItem,
  NoticeDetailDataType,
  NoticeDetailItem,
} from './detail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadCacheFacilities } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'NoticeForUser',

  pageOptions: {
    routeConfig: {
      path: '/noticeForUser',
    },
    props: {
      title: '利用者お知らせ設定',
    },
  },

  components: {
    NoticeDetail,
  },

  data: () => ({
    search: {
      title: '' as string,
      isPublic: null as boolean | null,
    },
    table: {
      headers: [
        { text: 'タイトル', value: 'subject', cellClass: 'pointer' },
        { text: '公開期間', value: 'period', cellClass: 'pointer' },
        { text: '重要', value: 'isEmergency', cellClass: 'pointer' },
        { text: '施設', value: 'facilityId', cellClass: 'pointer' },
        { text: 'ステータス', value: 'isPublic', cellClass: 'pointer' },
      ] as DataTableHeader[],
      items: [] as NoticeForUser[] | undefined,
    },

    dialog: {
      show: false,
      item: initItem,
    } as NoticeDetailDataType,
    facilities: [] as Facility[],
  }),

  computed: {
    status() {
      return [
        { text: '公開中', value: true },
        { text: '非公開', value: false },
      ];
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        const result = await this.$api<
          GetAllNoticeForUserResponse,
          GetAllNoticeForUserRequest
        >({
          path: '/noticeForUser',
          method: 'get',
          params: { ...this.search },
        });

        if (result) {
          this.table.items = result.notices.filter(
            (notice) =>
              notice.isPublic === (this.search.isPublic ?? notice.isPublic),
          );
        }
        this.facilities = await loadCacheFacilities(this);
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    getFacilityName(id: string): string {
      if (!id) return '';
      return this.facilities.find((f) => f.id === id)?.name || '';
    },

    open() {
      this.dialog = {
        show: true,
        item: { ...initItem },
      };
    },

    handleClickRow(item: NoticeDetailItem) {
      this.dialog = {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
      };
    },

    async save() {
      try {
        let result = null;
        if (!this.dialog.item.id) {
          result = await this.insert();
        } else {
          result = await this.update();
        }
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async insert() {
      const result = await this.$api<
        InsertNoticeForUserResponse,
        InsertNoticeForUserRequest
      >({
        path: '/noticeForUser/create',
        method: 'post',
        params: this.dialog.item as InsertNoticeForUserRequest,
      });

      return result;
    },

    async update() {
      const result = await this.$api<
        UpdateNoticeForUserResponse,
        UpdateNoticeForUserRequest
      >({
        path: '/noticeForUser/update',
        method: 'post',
        params: this.dialog.item as UpdateNoticeForUserRequest,
      });

      return result;
    },
  },
});
</script>

<style lang="scss" scoped>
.markdown-editor {
  height: 550px;
}
</style>
