<template>
  <v-text-field
    :value="value_"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :clearable="clearable"
    :single-line="singleLine"
    :rules="rules"
    class="centered-input"
    prepend-inner-icon="mdi-minus"
    append-icon="mdi-plus"
    @keypress="onKeyPress"
    @click:prepend-inner="minus"
    @click:append="plus"
    @change="onChange"
  ></v-text-field>
</template>

<script lang="ts">
import { stringUtility } from '@c/util';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'NumberInput',

  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: [Boolean, String],
      default: undefined,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    singleLine: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array as PropType<Array<(arg0: any) => boolean | string>>,
      default: () => [],
    },
  },

  computed: {
    value_(): string {
      return String(Number(this.value ?? ''));
    },

    numberValue(): number {
      return Number(this.value_ ?? '');
    },
  },

  methods: {
    async onChange(value: string | number | null) {
      this.$emit('input', '');
      await this.$nextTick();

      const value_ = String(value || 0);
      const value__ = stringUtility.fullToHalf(value_);

      this.$emit('input', value__);
    },
    /**
     * 数値のみ入力できるようにする
     */
    onKeyPress(event: KeyboardEvent) {
      if (!/[0-9０-９\s]/.test(event.key)) {
        event.preventDefault();
      }
    },
    /**
     * 入力値を1減らす
     */
    minus() {
      if (this.numberValue > 0) {
        this.onChange(this.numberValue - 1);
      }
    },
    /**
     * 入力値を1増やす
     */
    plus() {
      this.onChange(this.numberValue + 1);
    },
  },
});
</script>
