import {
  ConstantMailType,
  ConstantMailTypeDict,
  ConstantMailTypeItem,
} from '@api-t/constants/mailType';

const mailTypeDict: ConstantMailTypeDict = {
  onCreate: {
    text: '新規予約時',
    value: 'onCreate',
  },
  onUpdate: {
    text: '予約更新時',
    value: 'onUpdate',
  },
  onDelete: {
    text: '予約削除時',
    value: 'onDelete',
  },
  periodically: {
    text: '定期実行',
    value: 'periodically',
  },
};

export const mailType: ConstantMailType = {
  ...mailTypeDict,
  items: Object.values(mailTypeDict),
  get(value: string): string {
    return (
      (mailTypeDict as Record<string, ConstantMailTypeItem>)[value]?.value || ''
    );
  },
};
