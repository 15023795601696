var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"scrollable":"","width":"1500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"dialogBox"},[_c('v-card-title',[_vm._v("詳細")]),_c('v-card-text',{staticClass:"text--primary px-7"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":"","label":"タイトル"},model:{value:(_vm.item.subject),callback:function ($$v) {_vm.$set(_vm.item, "subject", $$v)},expression:"item.subject"}})],1)],1),_c('v-row',[_c('v-col',[_c('mavon-editor',{staticClass:"markdown-editor",attrs:{"language":"ja","placeholder":"本文をここに入力します。","toolbars":{
                bold: true,
                italic: true,
                header: true,
                underline: true,
                strikethrough: true,
                superscript: true,
                subscript: true,
                quote: true,
                ol: true,
                ul: true,
                link: true,
                code: true,
                table: true,
                fullscreen: true,
                readmodel: true,
                htmlcode: true,
                help: true,
                undo: true,
                redo: true,
                trash: true,
                save: true,
                navigation: true,
                alignleft: true,
                aligncenter: true,
                alignright: true,
                subfield: true,
                preview: true,
              }},model:{value:(_vm.item.body),callback:function ($$v) {_vm.$set(_vm.item, "body", $$v)},expression:"item.body"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('date-picker',{attrs:{"label":"掲載開始日"},model:{value:(_vm.item.from),callback:function ($$v) {_vm.$set(_vm.item, "from", $$v)},expression:"item.from"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('date-picker',{attrs:{"label":"掲載終了日"},model:{value:(_vm.item.to),callback:function ($$v) {_vm.$set(_vm.item, "to", $$v)},expression:"item.to"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"gray",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{},[_vm._v("mdi-close-circle-outline")]),_vm._v(" キャンセル ")],1),_c('v-btn',{attrs:{"depressed":"","color":"primary","small":""},on:{"click":function($event){return _vm.$emit('save')}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-file-edit-outline")]),_vm._v(" 保存 ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }