<template>
  <v-row>
    <v-col cols="12" class="d-flex mt-5 mb-2 data-panel-section">
      <div class="my-n1 py-1">
        <span class="font-weight-bold ma-2">{{ label }}</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DataPanelSection',

  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
