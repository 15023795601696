import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';
import { Cognito } from '@web/modules/cognito';
import { CognitoProps } from '@web-t/cognito';

const cognitoProps: CognitoProps = {
  userPoolId: Vue.$config.cognito.userPoolId,
  userPoolClientId: Vue.$config.cognito.userPoolClientId,
};

export const cognitoInstance = new Cognito(cognitoProps);

export const plugin: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$cognito = cognitoInstance;
    Vue.$cognito = cognitoInstance;
  },
};

Vue.use(plugin);
