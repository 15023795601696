<template>
  <div class="masterDetail">
    <v-row>
      <v-col cols="12">
        <DataPanelField label="名称">
          <v-edit-dialog :return-value.sync="master.name">
            <span>
              {{ master.name }}
            </span>
            <v-icon small class="ml-2">mdi-pencil</v-icon>
            <template #input>
              <v-text-field
                v-model="master.name"
                label="Edit"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </DataPanelField>
        <DataPanelField label="区分">
          <v-select
            :items="filter.section"
            outlined
            dense
            hide-details="auto"
            label=""
          ></v-select>
        </DataPanelField>
        <DataPanelField label="選択肢">
          <ul class="inputList">
            <li>
              <v-text-field
                v-model="forms[index]"
                dense
                outlined
                filled
              ></v-text-field>
              <button @click="deleteForm(index)">
                <v-icon class="">mdi-minus-circle</v-icon>
              </button>
            </li>
            <li v-for="(form, index) in forms" :key="index">
              <v-text-field
                v-model="forms[index]"
                dense
                outlined
                filled
              ></v-text-field>
              <button @click="deleteForm(index)">
                <v-icon class="">mdi-minus-circle</v-icon>
              </button>
            </li>
            <li>
              <v-btn depressed @click="addForm()" x-small outlined>
                <v-icon class="">mdi-plus</v-icon>
                追加
              </v-btn>
            </li>
          </ul>
        </DataPanelField>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataPanelField from '@web/entry/internal/components/DataPanelField.vue';

export default {
  name: 'MasterDetail',

  components: {
    DataPanelField,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    filter: {
      section: ['枠貸', '時間貸'],
    },

    master: {
      name: 'テントサイトA',
      section: '枠貸',
    },

    forms: [],
  }),

  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    addForm() {
      this.forms.push('');
    },
    deleteForm(index) {
      this.forms.splice(index, 1);
    },
  },
};
</script>
