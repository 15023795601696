import { PageOptionsProps } from '@web-t/global/component-options';
import VueRouter from 'vue-router';

import store from '@web-i/store/index';

export function setupRouter({ router }: { router: VueRouter }) {
  // タイトルの変更
  router.afterEach((to) => {
    const props = to.meta?.pageProps as PageOptionsProps;
    if (props) {
      const { title, fullTitle } = props;
      if (fullTitle) {
        document.title = fullTitle;
      } else if (title) {
        document.title = [process.env.PAGE_TITLE, title].join(' | ');
      } else {
        document.title = process.env.PAGE_TITLE || '';
      }
    }
  });

  // ログイン情報がない時にtopに遷移する
  router.beforeEach(async (to, from, next) => {
    if (to.path !== from.path && to.path !== '/') {
      if (!store.state.login.loggedInUser) {
        return next('/');
      }
    }
    next();
  });
}
