<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>料金</h2>
        <div class="facility">（{{ selectedFacility?.name }}）</div>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed small outlined @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed :disabled="!selectedUnitId" small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-container class="">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="selectedUnitId"
                      :items="units"
                      outlined
                      dense
                      hide-details="auto"
                      label="区画種類または備品"
                      clearable
                      item-text="name"
                      item-value="id"
                      @change="load"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <custom-data-table
            :headers="table.headers"
            :items="tableItems"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
          </custom-data-table>
          <fee-detail
            v-model="dialog"
            :fields="fields"
            @save="save"
            @destroy="destroy"
          ></fee-detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import { Fee, Field, Unit } from '@api/models';
import {
  DeleteFeeRequest,
  DeleteFeeResponse,
  OrderFeeRequest,
  OrderFeeResponse,
} from '@api-i/routes/fee/fee';
import FeeDetail, { initFeeDetailItem } from './FeeDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadUnit, loadUnits } from '@web/modules/master-loader';
import { mapActions } from '@web/store/snackbar';
import { systemFields } from '@api/constants';
import { stringUtility } from '@c/util';

export default Vue.extend({
  name: 'MastersFee',

  pageOptions: {
    routeConfig: {
      path: '/masters/fee',
    },
    props: {
      title: 'マスタ設定 - 料金',
    },
  },

  components: {
    FeeDetail,
  },

  data: () => {
    return {
      selectedUnitId: '',
      units: [] as Unit[],
      fields: [] as Partial<Field>[],
      table: {
        headers: [
          { text: '名称', value: 'name', cellClass: 'pointer' },
          { text: '単価', value: 'unitPrice', cellClass: 'pointer' },
          { text: '数量', value: 'numOfPieces_', cellClass: 'pointer' },
          { text: '計算式', value: 'formula_', cellClass: 'pointer' },
          { text: '税率', value: 'taxRate', cellClass: 'pointer' },
          { text: '税区分', value: 'taxTypeName', cellClass: 'pointer' },
          {
            text: '端数処理区分',
            value: 'roundingTypeName',
            cellClass: 'pointer',
          },
        ],
        items: [] as Fee[],
      },
      dialog: {
        show: false,
        item: {},
        newMode: false,
      },
    };
  },

  computed: {
    selectedFacility() {
      return this.$store.getters['master/selectedFacility'];
    },

    tableItems() {
      const corr = chain(this.fields)
        .keyBy('property')
        .mapValues('name')
        .value();

      const result = this.table.items.map((item) => {
        const { numOfPieces, formula } = item;
        const numOfPieces_ = stringUtility.convertMessage(numOfPieces, corr);
        const formula_ = stringUtility.convertMessage(formula, corr);

        return { ...item, numOfPieces_, formula_ };
      });

      return result;
    },
  },

  async mounted() {
    await this.loadUnits();
  },

  methods: {
    async loadUnits() {
      if (!this.selectedFacility) {
        return this.snackbarRegister({
          type: 'error',
          message:
            '施設が選択されていません。前の画面に戻り施設を選択して下さい。',
        });
      }
      const facilityId = this.selectedFacility.id;
      const units = await loadUnits(this, {
        facilityId: facilityId,
      });
      if (units) {
        this.units = units;
      }
    },

    async load() {
      if (!this.selectedUnitId) {
        this.table.items = [];
        return;
      }
      const unit = await loadUnit(this.selectedUnitId, this, { scope: 'fees' });

      if (unit) {
        this.table.items = unit.fees!;
        // 料金はシステムが使用するプロパティを追加で指定できる
        this.fields = [...unit.fields_, ...systemFields.usually];
      }
    },

    open() {
      if (!this.selectedUnitId) {
        return;
      }
      const unitId = this.selectedUnitId;
      this.$set(this.dialog, 'show', true);
      this.$set(
        this.dialog,
        'item',
        structuredClone({ ...initFeeDetailItem, unitId }),
      );
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: Fee) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/fee/save',
          method: 'post',
          params: this.dialog.item,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<DeleteFeeResponse, DeleteFeeRequest>({
          path: '/fee/delete',
          method: 'post',
          params: this.dialog.item as DeleteFeeRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      if (!this.table.items) {
        return;
      }
      try {
        const result = await this.$api<OrderFeeResponse, OrderFeeRequest>({
          path: '/fee/order',
          method: 'post',
          params: {
            items: this.table.items.map((item, order) => {
              item.order = order + 1;
              return item;
            }),
          },
        });

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>

<style lang="scss" scoped></style>
