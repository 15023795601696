<template>
  <v-row class="mx-0 data-field-panel">
    <v-col
      cols="12"
      sm="4"
      class="text-end font-weight-bold data-field-panel__ttl"
    >
      <span v-if="!!label">
        {{ label }}
      </span>
    </v-col>
    <v-col cols="12" sm="8" class="data-field-panel__txt">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.data-field-panel {
  &:not(:last-child) {
    > div {
      border-bottom: thin solid black;
    }
  }
}
</style>
