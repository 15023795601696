<template>
  <v-select
    v-if="field.type === inputType.enumSingle.value"
    :value="value"
    :items="field.items_"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  ></v-select>
  <v-select
    v-else-if="field.type === inputType.enumMultiple.value"
    :value="parsedValue"
    :items="field.items_"
    outlined
    dense
    hide-details="auto"
    clearable
    multiple
    @input="input"
  ></v-select>
  <DatePicker
    v-else-if="field.type === inputType.date.value"
    :value="value"
    clearable
    @input="input"
  />
  <TimePicker
    v-else-if="field.type === inputType.time.value"
    :value="value"
    :minute-step="unitStep"
    clearable
    @input="input"
  />
  <v-select
    v-else-if="field.type === inputType.unitItems.value"
    :value="parsedValue"
    :items="unitItems"
    outlined
    dense
    hide-details="auto"
    clearable
    multiple
    @input="input"
  ></v-select>
  <NumberInput
    v-else-if="field.type === inputType.number.value"
    :value="value"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  />
  <v-checkbox
    v-else-if="field.type === inputType.checkbox.value"
    :input-value="parseBoolean(value)"
    :prepend-inner-icon="field.icon"
    :label="field.label"
    hide-details="auto"
    @change="(v) => input(!!v)"
  ></v-checkbox>
  <v-text-field
    v-else
    :type="field.type"
    :value="value"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  ></v-text-field>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { isArray, isBoolean } from 'lodash';
import DatePicker from '@web/components/inputs/date-picker.vue';
import NumberInput from '@web/components/inputs/number-input.vue';
import TimePicker from '@web/components/inputs/time-picker.vue';
import { inputType } from '@api/constants';
import { Field } from '@api/models';

export default Vue.extend({
  name: 'VariableInputField',

  components: {
    DatePicker,
    NumberInput,
    TimePicker,
  },

  props: {
    value: {
      type: String,
      default: () => '',
    },

    field: {
      type: Object as PropType<Field>,
      default: undefined,
    },

    unitStep: {
      type: Number,
      default: undefined,
    },

    unitItems: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
  },

  computed: {
    inputType() {
      return inputType;
    },

    parsedValue() {
      return JSON.parse(this.value || '[]');
    },
  },

  methods: {
    input(value: any) {
      this.$emit(
        'input',
        isArray(value) || isBoolean(value) ? JSON.stringify(value) : value,
      );
    },

    parseBoolean(value: string): boolean {
      try {
        return JSON.parse(value);
      } catch (e) {
        return false;
      }
    },
  },
});
</script>
