<template>
  <v-dialog v-model="show" scrollable width="1500">
    <v-card class="dialogBox">
      <v-card-title>詳細</v-card-title>
      <v-card-text class="text--primary px-7">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.subject"
                outlined
                dense
                hide-details="auto"
                clearable
                label="タイトル"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <mavon-editor
                v-model="item.body"
                language="ja"
                placeholder="本文をここに入力します。"
                :toolbars="{
                  bold: true,
                  italic: true,
                  header: true,
                  underline: true,
                  strikethrough: true,
                  superscript: true,
                  subscript: true,
                  quote: true,
                  ol: true,
                  ul: true,
                  link: true,
                  code: true,
                  table: true,
                  fullscreen: true,
                  readmodel: true,
                  htmlcode: true,
                  help: true,
                  undo: true,
                  redo: true,
                  trash: true,
                  save: true,
                  navigation: true,
                  alignleft: true,
                  aligncenter: true,
                  alignright: true,
                  subfield: true,
                  preview: true,
                }"
                class="markdown-editor"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <DatetimePicker v-model="item.from" label="掲載開始日" />
            </v-col>
            <v-col cols="6">
              <DatetimePicker v-model="item.to" label="掲載終了日" />
            </v-col>
            <v-col cols="6">
              <BooleanInput v-model="item.isEmergency" label="重要" />
              <div class="caption">強調表示されます。</div>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="item.facilityId"
                :items="facilityItems"
                label="施設を選択"
                outlined
                clearable
                hide-details
                dense
              >
              </v-autocomplete>
              <div class="caption">
                施設を選択するとその施設の予約ページにお知らせが表示されます。
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" small @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon> 保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  DeleteNoticeForUserRequest,
  InsertNoticeForUserRequest,
  UpdateNoticeForUserRequest,
} from '@api-i/routes/noticeForUser/noticeForUser';
import DatetimePicker from '@web/components/inputs/datetime-picker.vue';
import BooleanInput from '@web/components/inputs/boolean.vue';
import { Facility } from '@api/models';
import { loadCacheFacilities } from '@web/modules/master-loader';
import { VSelectItem } from '@web-t/global/vuetify-custom';

export interface NoticeDetailItem
  extends InsertNoticeForUserRequest,
    UpdateNoticeForUserRequest,
    DeleteNoticeForUserRequest {}

export type NoticeDetailDataType = {
  show: boolean;
  item: NoticeDetailItem;
};

const defaultTo = new Date();
defaultTo.setDate(defaultTo.getDate() + 7);

export const initItem = {
  id: '',
  subject: '',
  body: '',
  from: new Date(),
  to: defaultTo,
  isEmergency: false,
  facilityId: undefined,
};

export default Vue.extend({
  name: 'NoticeForUserDetail',

  components: {
    DatetimePicker,
    BooleanInput,
  },

  props: {
    value: {
      type: Object as PropType<NoticeDetailDataType>,
      default: () => ({
        show: false,
        item: initItem,
      }),
    },
  },

  data: () => ({
    fromMenu: false,
    toMenu: false,
    facilities: [] as Facility[],
  }),

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },
    item: {
      get(): InsertNoticeForUserRequest | UpdateNoticeForUserRequest {
        return this.value.item;
      },
      set(item: InsertNoticeForUserRequest | UpdateNoticeForUserRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },
    facilityItems(): VSelectItem[] {
      return this.facilities.map((f) => ({
        text: f.name,
        value: f.id,
      }));
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.facilities = await loadCacheFacilities(this);
    },
  },
});
</script>
