<template>
  <v-virtual-scroll :items="items_" item-height="81" height="550">
    <template #default="{ item, index }">
      <v-list-item :key="item.id" @click="$emit('click', item)">
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.property"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text
            v-text="item.typeName"
          ></v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index < items.length - 1"></v-divider>
    </template>
  </v-virtual-scroll>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Field } from '@api/models';
import { inputType } from '@api/constants';

export default Vue.extend({
  name: 'FieldList',

  props: {
    items: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
  },

  computed: {
    items_() {
      const result = this.items.map((item) => {
        let subtitle = '';

        if (/^system\./.test(item.property)) {
          // ...
        } else if (inputType.selectable.includes(item.type)) {
          subtitle = String(item.items_);
        } else if (inputType.number.value === item.type) {
          subtitle = '最大値：' + (item.max || 10);
        } else if (inputType.checkbox.value === item.type) {
          subtitle = item.label || '';
        }

        return { ...item, subtitle };
      });

      return result;
    },
  },
});
</script>
