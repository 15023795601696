<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row justify="center" align="center">
          <LoadingInner />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import LoadingInner from './LoadingInner.vue';

export default Vue.extend({
  name: 'InitialLoading',

  components: {
    LoadingInner,
  },
});
</script>
