<template>
  <v-dialog v-model="show" scrollable width="600" class="dialogBox">
    <v-card class="dialogBox">
      <v-card-title>{{ isNewMode ? '施設を追加' : '詳細' }}</v-card-title>
      <v-card-text class="text--primary px-7">
        <div class="masterDetail">
          <v-row>
            <v-col cols="12">
              <data-panel-field label="名称">
                <v-text-field
                  v-model="item.name"
                  placeholder="例： 井川オートキャンプ場"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[(v) => !!v || '施設名は必須項目です。']"
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="頭文字">
                <v-text-field
                  v-model="item.initials"
                  placeholder="例： IA"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  :rules="[
                    (v) => !!v || '頭文字は必須項目です。',
                    (v) => v.length === 2 || '頭文字は2文字です。',
                  ]"
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="地区名">
                <v-text-field
                  v-model="item.area"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="概要">
                <v-textarea
                  v-model="item.overview"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-textarea>
              </data-panel-field>
              <data-panel-field label="住所">
                <v-text-field
                  v-model="item.address"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="TEL">
                <v-text-field
                  v-model="item.tel"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="営業時間">
                <v-textarea
                  v-model="item.businessHours"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-textarea>
              </data-panel-field>
              <data-panel-field label="定休日">
                <v-textarea
                  v-model="item.regularHoliday"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-textarea>
              </data-panel-field>
              <data-panel-field label="利用料金">
                <v-textarea
                  v-model="item.usageFee"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-textarea>
              </data-panel-field>
              <data-panel-field label="リンク先">
                <v-text-field
                  v-model="item.url"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="施設内マップ名">
                <v-text-field
                  v-model="item.imageName"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
              <data-panel-field label="地図URL">
                <v-text-field
                  v-model="item.mapUrl"
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                  class="mt-0 pt-0"
                ></v-text-field>
              </data-panel-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isNewMode"
          depressed
          small
          class="red"
          @click="$emit('destroy')"
        >
          <v-icon>mdi-delete-outline</v-icon>
          <span>項目削除</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed small class="gray" @click="show = false">
          <v-icon class="">mdi-close-circle-outline</v-icon>
          キャンセル
        </v-btn>
        <v-btn depressed small color="primary" @click="$emit('save')">
          <v-icon class="white--text">mdi-file-edit-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SaveFacilityRequest } from '@api-i/routes/facility/facility';
import DataPanelField from '@web-i/components/DataPanelField.vue';

export type FacilityDetailDataType = {
  show: boolean;
  item: SaveFacilityRequest;
  newMode: boolean;
};

export const initFacilityDetailItem: Partial<SaveFacilityRequest> = {
  name: '',
  initials: '',
  area: '',
  overview: '',
  address: '',
  tel: '',
  businessHours: '',
  regularHoliday: '',
  usageFee: '',
  url: '',
  imageName: '',
  mapUrl: '',
};

export default Vue.extend({
  name: 'FacilityDetail',

  components: {
    DataPanelField,
  },

  props: {
    value: {
      type: Object as PropType<FacilityDetailDataType>,
      default: undefined,
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): SaveFacilityRequest {
        return this.value.item;
      },
      set(item: SaveFacilityRequest): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    isNewMode(): boolean {
      return this.value?.newMode;
    },
  },
});
</script>
