import ExcelJS from 'exceljs';

export interface WorksheetData {
  name: string;
  columns: {
    header: string;
    key: string;
  }[];
  rows: any[];
}

export interface WorkbookData {
  sheets: WorksheetData[];
}

/**
 * 予約本体のヘッダー列
 */
export const bookColumns = [
  { header: '受付番号', key: 'book.receiptNo' },
  { header: '最終更新者ID', key: 'book.updatedById' },
  { header: '最終更新者', key: 'book.updatedBy.name' },
  { header: '最終更新日', key: 'book.updatedAt' },
  { header: '氏名', key: 'book.name' },
  { header: 'かな', key: 'book.kana' },
  { header: '郵便番号', key: 'book.zipCode' },
  { header: '住所', key: 'book.address' },
  { header: '電話番号', key: 'book.tel' },
  { header: 'E-mail', key: 'book.email' },
  { header: '施設', key: 'book.facilityName' },
];

/**
 * ワークブックを作成する
 */
export async function generateWorkbook(data: WorkbookData) {
  const workbook = new ExcelJS.Workbook();

  for (const sheet of data.sheets) {
    const worksheet = workbook.addWorksheet(sheet.name);
    worksheet.columns = sheet.columns;
    worksheet.addRows(sheet.rows);
  }

  const result = await workbook.xlsx.writeBuffer();

  return result;
}
