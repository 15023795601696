<template>
  <v-form>
    <v-row>
      <!-- 施設 -->
      <v-col cols="12">
        <v-autocomplete
          :value="filter.facilityId"
          :items="facilities"
          outlined
          dense
          chips
          small-chips
          hide-details="auto"
          label="施設"
          multiple
          clearable
          item-text="name"
          item-value="id"
          @input="setFacilityId"
        ></v-autocomplete>
      </v-col>
      <!-- 利用日（指定方法） -->
      <v-col cols="12" sm="4">
        <v-select
          :value="filter.dateMethod"
          :items="dateMethod.items"
          outlined
          dense
          hide-details="auto"
          @input="setDateMethod"
          @change="onChangeDateMethod"
        />
      </v-col>
      <!-- 利用日（開始） -->
      <v-col cols="12" sm="4">
        <DatePicker
          :value="filter.fromDate"
          label="利用日(from)"
          clearable
          :text-field-props="{
            disabled: !isDateMethodManual,
          }"
          @input="setFromDate"
        />
      </v-col>
      <!-- 利用日（終了） -->
      <v-col cols="12" sm="4">
        <DatePicker
          :value="filter.toDate"
          label="利用日(to)"
          clearable
          :text-field-props="{
            disabled: !isDateMethodManual,
          }"
          @input="setToDate"
        />
      </v-col>
      <!-- 予約者名 -->
      <v-col cols="12" sm="4">
        <v-text-field
          :value="filter.name"
          outlined
          dense
          hide-details="auto"
          label="予約者名"
          clearable
          @input="setName"
        ></v-text-field>
      </v-col>
      <!-- 受付番号 -->
      <v-col cols="12" sm="4">
        <v-text-field
          :value="filter.receiptNo"
          outlined
          dense
          hide-details="auto"
          label="受付番号"
          clearable
          @input="setReceiptNo"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DatePicker from '@web/components/inputs/date-picker.vue';
import { Facility } from '@api/models';
import { mapActions, mapState } from '@web-i/store/filter';
import { ConstantDateMethod } from '@api-t/constants/dateMethod';
import { dateMethod } from '@api/constants/dateMethod';

export default Vue.extend({
  name: 'FilterForm',

  components: {
    DatePicker,
  },

  props: {
    facilities: {
      type: Array as PropType<Facility[]>,
      required: true,
    },
  },

  computed: {
    isDateMethodManual(): boolean {
      const result = this.filter.dateMethod === dateMethod.manual.value;

      return result;
    },

    dateMethod(): ConstantDateMethod {
      return dateMethod;
    },

    ...mapState(['filter']),
  },

  methods: {
    /**
     * 指定方法に合わせてfrom,toをセットする
     */
    onChangeDateMethod() {
      if (this.isDateMethodManual) {
        return;
      }
      const { from, to } = dateMethod.calc(this.filter.dateMethod);
      const from_ = this.$dateFns.fnsFormat(from, '', 'yyyy-MM-dd');
      const to_ = this.$dateFns.fnsFormat(to, '', 'yyyy-MM-dd');

      this.setFromDate(from_);
      this.setToDate(to_);
    },

    ...mapActions([
      'setFacilityId',
      'setReceiptNo',
      'setDateMethod',
      'setFromDate',
      'setToDate',
      'setName',
    ]),
  },
});
</script>
