import { Detail, Facility } from '@api/models';
import { chain, isNumber } from 'lodash';
/**
 * 統計情報一覧表示用データを返す
 */
export function getStatsListData(
  details: Detail[],
  facilityDict: Record<string, Facility>,
) {
  const result = chain(details)
    .groupBy('unit.facilityId')
    .mapValues((details) => {
      const number = chain(details)
        .map((detail) => {
          const prop = detail.unit?.asNumOfPeople;
          if (!prop) {
            return 0;
          }

          return Number(detail[prop]) || 0;
        })
        .filter(isNumber)
        .sum()
        .value();

      const realNumber = chain(details)
        .map((detail) => {
          const prop = detail.unit?.asRealNumOfPeople;
          if (!prop) {
            return 0;
          }

          return Number(detail[prop]) || 0;
        })
        .filter(isNumber)
        .sum()
        .value();

      const result_ = {
        counts: details.length,
        number: number,
        realNumber: realNumber,
        visitor: number
          ? `${Math.floor((realNumber / number) * 100)}%`
          : undefined,
      };
      return result_;
    })
    .merge(facilityDict)
    .values()
    .value();

  return result;
}
