<template>
  <v-container class="masterDetail">
    <v-row>
      <v-col cols="12" md="6" class="pageTtl">
        <h2>利用目的</h2>
      </v-col>
      <v-col cols="12" md="6" class="text-right d-flex justify-end">
        <v-card-actions class="px-0 py-0">
          <v-btn depressed outlined small @click="saveOrder">
            順番を保存
          </v-btn>
          <v-btn depressed small @click="open">
            <v-icon class="white--text">mdi-plus</v-icon>
            追加
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <custom-data-table
            :headers="table.headers"
            :items="table.items"
            item-class="pointer"
            :use-draggable="true"
            @click:row="handleClickRow"
            @updated-items="
              (v) => {
                table.items = v;
              }
            "
          >
          </custom-data-table>
          <purpose-detail
            v-model="dialog"
            @save="save"
            @destroy="destroy"
          ></purpose-detail>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  UpdatePurposeRequest,
  DeletePurposeRequest,
  DeletePurposeResponse,
  OrderPurposeRequest,
  OrderPurposeResponse,
} from '@api-i/routes/purpose/purpose';
import { Purpose } from '@api/models';
import PurposeDetail, {
  initPurposeDetailItem,
} from '../master_purposes/PurposeDetail.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { loadPurposes } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'AdminPurposes',

  pageOptions: {
    routeConfig: {
      path: '/admin/purposes',
    },
    props: {
      title: 'マスタ設定 - 利用目的',
    },
  },

  components: {
    PurposeDetail,
  },

  data: () => ({
    table: {
      headers: [{ text: '名称', value: 'name', cellClass: 'pointer' }],
      items: [] as Purpose[],
    },
    dialog: {
      show: false,
      item: {} as Partial<UpdatePurposeRequest>,
      newMode: false,
    },
  }),

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      this.table.items = await loadPurposes(this);
    },

    open() {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(initPurposeDetailItem));
      this.$set(this.dialog, 'newMode', true);
    },

    handleClickRow(item: Purpose) {
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', structuredClone(item));
      this.$set(this.dialog, 'newMode', false);
    },

    async save() {
      try {
        const result = await this.$api({
          path: '/purpose/save',
          method: 'post',
          params: this.dialog.item,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの保存に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async destroy() {
      if (!confirm('本当に削除しますか？')) {
        return;
      }
      try {
        const result = await this.$api<
          DeletePurposeResponse,
          DeletePurposeRequest
        >({
          path: '/purpose/delete',
          method: 'post',
          params: this.dialog.item as DeletePurposeRequest,
        });
        this.dialog.show = false;

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['削除に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    async saveOrder() {
      if (!this.table.items) {
        return;
      }
      try {
        const result = await this.$api<
          OrderPurposeResponse,
          OrderPurposeRequest
        >({
          path: '/purpose/order',
          method: 'post',
          params: {
            items: this.table.items.map((item, order) => {
              item.order = order + 1;
              return item;
            }),
          },
        });

        if (result) {
          await this.load();
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['順番の保存に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
