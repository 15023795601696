<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="value_"
        outlined
        dense
        hide-details="auto"
        clearable
        :disabled="disabled"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <icon-selectorr :value="value" @input="onInput"></icon-selectorr>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import IconSelectorr from './IconSelector.vue';

export default Vue.extend({
  name: 'IconPicker',

  components: {
    IconSelectorr,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    value_: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    onInput(icon: string) {
      this.menu = false;
      this.$emit('input', icon);
    },
  },
});
</script>
